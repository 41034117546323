import React from 'react'; 
import CheckboxField from '../formFields/CheckboxField'
/**
 * to be wrapped with redux-form Field component
 */
export default function ReduxFormcheckboxField(props) {

  const { input, meta, fieldlabel, placeholder,  required ,checkboxLabel , onCheck, defaultChecked ,onChange , setChecked ,setDisabled, changeSensitiveInfoFieldValue ,ChangeNotDiscloseSensitiveInfoValue} = props

const onChangeValue = (value:any) =>  {
  setChecked && setChecked(!defaultChecked)
  setDisabled && setDisabled(!input.value)
  setChecked && setDisabled && defaultChecked && setChecked(!defaultChecked)
  ChangeNotDiscloseSensitiveInfoValue && ChangeNotDiscloseSensitiveInfoValue(!input.value, input.name)

  changeSensitiveInfoFieldValue && changeSensitiveInfoFieldValue(!input.value)
  onCheck && onCheck(value)
  input.onChange(value)
  onChange && onChange(!input.value, input.name)
}
  // do not display warning if the field has not been touched or if it's currently being edited
  let error = ""
  if (meta.touched && !meta.active) {
    if (meta.valid) {
    } else {
      error = meta.error
    }
  }
  return (
 <CheckboxField
    fieldlabel={fieldlabel}
    placeholder={placeholder}
    required = {required}
    errMsg={error}
    onChange={onChangeValue}
    value={input.value}
    checkboxLabel={checkboxLabel}
    onCheck={onCheck}
    defaultChecked={defaultChecked}
  />
  );
}