import { Radio } from '@material-ui/core';
import React from 'react';
import { Col, Form, Row } from "react-bootstrap";
import "../../sass/main.scss";
import './FormField.scss';

interface RadioFieldProps {
    fieldlabel: string,
    placeholder?: string,
    required?: boolean,
    errMsg: string,
    onChange: Function
    value?: any
    showError?: boolean,
    disabled?: boolean,
    radioLabel?: string,
    defaultSelected?: string
    selectedValue?: any
}

function RadioField(props: RadioFieldProps) {

    const { fieldlabel, required, errMsg = "", selectedValue } = props

    return (
        <Form className="radio-field-input" onSubmit={e => { e.preventDefault(); }}>
            <Row>
                <Col>
                    <Form.Label >{fieldlabel}{required ?
                        <span className="required" style={{ color: "red" }}>*</span> : null}</Form.Label>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="radio-field">
                        {errMsg.length > 0 ? <span className="alert-icon  alert-icon-align"></span> : ''}
                        {props.fieldlabel ? <>
                            <Radio
                                checked={selectedValue === 'Default'}
                                onChange={value => props.onChange(value)}
                                value="Default"
                                name="radio-button-demo"
                                className="radio-field-style"
                                inputProps={{ 'aria-label': 'DEAFULT' }}
                                disabled={props.disabled}
                            />
                            <Form.Label >Default</Form.Label> </> : null}
                        <Radio
                            checked={selectedValue === 'Yes'}
                            onChange={value => props.onChange(value)}
                            value="Yes"
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'Yes' }}
                            className="radio-field-style"
                            disabled={props.disabled}
                        />
                        <Form.Label >Yes</Form.Label>
                        <Radio
                            checked={selectedValue === 'No'}
                            onChange={value => props.onChange(value)}
                            value="No"
                            className="radio-field-style"
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'No' }}
                            disabled={props.disabled}
                        />
                        <Form.Label >No</Form.Label>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    {props.errMsg.length > 0
                        ? <span className="error-message">{props.errMsg}</span>
                        : <span>&nbsp;</span>
                    }
                </Col>
            </Row>
        </Form>
    )
}

export default RadioField;