import React from 'react';
import '../src/sass/index.scss';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import DataEntryDetails from './components/dataEntryDetails/dataEntryComponents/DataEntryDetails';
import LoginPage from './components/login/LoginPage';
import HealthOrgFacilityDetails from './components/landingPage/HealthOrgFacilityDetails';
import { setGlobalId } from './common/utils/appUtils';

interface AppProps {
  history: any
}

const PrivateRoute = (props) => {
  const renderMethod = () => {
    const userId = sessionStorage.getItem("userID")
    if(userId){
      return <Route component={props.component} />
    }
    return <Redirect to={"/"} />
  }
  return(
    <Route render={renderMethod} />
  )
}


class App extends React.Component<AppProps> {
  componentDidMount(){
    setGlobalId()
  }

  render(){
  return (
    <Router history={this.props.history}>
      <Switch>
          <Route exact path='/' component={LoginPage} />
          <PrivateRoute path='/home' component={HealthOrgFacilityDetails} />
          <PrivateRoute path='/data' component={DataEntryDetails} />
      </Switch>
    </Router>
  );
  }
}


export default App;
