import ReduxFormDropdownField from "../reduxFormFields/ReduxFormDropDownField";
import React, { useState, useRef, MutableRefObject } from "react";
import { connect } from 'react-redux'
import { Col, Modal, Row, Form } from "react-bootstrap";
import { reduxForm, Field } from 'redux-form';
import { useHistory } from 'react-router-dom';
import { WaitAndHoldConstants } from "../../components/dataEntryDetails/dataEntryComponents/DataEntryFieldsConstants";
import localValidations from "../../components/dataEntryDetails/dataEntryComponents/validations/localValidations";
import submitValidations from '../../components/dataEntryDetails/dataEntryComponents/validations/submitValidations';
import IconWithButton from "../IconWithButton/IconWithButton";
import {postWaitAndHoldReasonsActionCreators, unassignTransactionIDActionCreator, assignTransactionIDActionCreator,
         patchTransactionContinueActionCreator, getTransactionAttributesActionCreator, getWaitAndHoldReasons} from "../../components/dataEntryDetails/DataEntryDetailsDucks";
import {headerAssignTransactionActionHeader, headerUnassignTransactionIDActionCreator, resetRequestContext} from '../../components/landingPage/LandingPageDucks'
import toast, { Toaster } from "react-hot-toast";
import { Radio } from "@material-ui/core";
import Multiselect from 'multiselect-react-dropdown';
import { ExcludeNever } from "typesafe-actions/dist/type-helpers";
import { propTypes } from "react-bootstrap/esm/Image";

interface IProps {
    show: Function;
    handleClose: any,
    headerText?: String,
    contentText?: String,
    buttonText: string,
    buttonOnClick?: Function,
    waitAndHoldReasons?: any,
    formState?:any,
    transactionId?:any,
    postWaitAndHoldReasonsDispatch?:any,
    setShowWaitAndHoldModal?:any,
    resetForm?: Function
    resetFormDataEntryState?: Function,
    resetRequestContext?: Function
    statusIndicatorID?:any,
    unassignSearchTransactionID?: Function,
    assignSearchTransactionID?: Function,
    holdForFacility: any,
    selectedHoldReasons?: any,
    isReadonly?: Boolean,
    continueTransaction?: Function,
    closeReadOnlyModal?: Function,
    getTransactionAttributes?: Function,
    facilityId?: any,
    transactionWaitAndHoldReasons?: Function,
    existingTransactionID?: any,
    loadUnwaitedTx?: Function
}

function WaitAndHoldModal(props: IProps) {
  const [activeId, setActiveId] = useState("");
  const history = useHistory();
  const { handleClose, show, buttonText,  headerText } = props;
  const [waitAndHoldError, setWaitAndHoldError] = useState("");
  const [radioSelect , setRadioSelect] = useState("");
  const [selectedValues, setSelectedValues] = useState([]);
  const [removedValues, setRemovedValues] = useState([]);
  const [holdForError, setHoldForError] = useState("");
  const [isReadOnly, setIsReadonly] = useState(props.isReadonly);
  const [removedItems, setRemovedItems] = useState<string[]>([]);
  const multiselectRef = useRef() as MutableRefObject<Multiselect>;

  const okHandler = () => {
    if(!isReadOnly){
        if(radioSelect === ""){
            setHoldForError("Hold for is required");
          }
          if(selectedValues.length === 0){
            setWaitAndHoldError("Wait and hold reason is required");
          }
          if(radioSelect !== "" && selectedValues.length > 0){
            toastPop("Request placed on hold.");
               // reset state here
               if(props.resetForm){
                props.resetForm()
            }
    
            if(props.resetFormDataEntryState){
                props.resetFormDataEntryState()
            }
            setTimeout(() => {
                props.unassignSearchTransactionID && props.unassignSearchTransactionID(props.transactionId);
                props.postWaitAndHoldReasonsDispatch( props.transactionId, selectedValues, props.statusIndicatorID, radioSelect);
                props.setShowWaitAndHoldModal(false);
                props.resetRequestContext && props.resetRequestContext();
                
                history?.push('/home')
              }, 500);
          }
    } else{
        if(removedValues.length === 0)
            toastPop('Request resumed.');
        else
            toastPop('Items removed.');
        setTimeout(() => {
            props.continueTransaction && props.continueTransaction(props.transactionId, removedItems);
            if(removedValues.length > 0)
                props.getTransactionAttributes && props.getTransactionAttributes(props.transactionId); //if this is only being done to refresh the status, please delete
            props.setShowWaitAndHoldModal(false);
            if(removedValues.length === 0){
                props.loadUnwaitedTx && props.loadUnwaitedTx(props.transactionId);
                props.closeReadOnlyModal && props.closeReadOnlyModal();
            }
        }, 500);
    }
  }
const toastPop = (message) =>{
    toast(message, {id: "rejectPopUp", duration: 5000})
  }

const changeDropDown =() =>{
      if(props.formState.values[WaitAndHoldConstants.WAIT_HOLD_REASONS]){
        setWaitAndHoldError("") ;
      }
  }
function onChangeRadio(value: any) {
    resetValues();
    if(value === "Facility Name"){
        setRadioSelect(props.holdForFacility);
        props.transactionWaitAndHoldReasons && props.transactionWaitAndHoldReasons(props.facilityId);
    } else {
        setRadioSelect(value);
        props.transactionWaitAndHoldReasons && props.transactionWaitAndHoldReasons(0);
    }
        
    setHoldForError("");
  }
const onSelect = (selectedList, selectedItem) => {
    setSelectedValues(selectedList);
    setWaitAndHoldError("");
}

const onRemove =(selectedList, removedItem) => {
    setRemovedValues(selectedList);
    setRemovedItems(removedItems => [...removedItems, removedItem])
}

const resetValues = ()  => {
    multiselectRef.current.resetSelectedValues();
  }
  
  return (
      <Modal className="cancel-dimension" show={props.setShowWaitAndHoldModal}>
          <div className="alert-container">
              <Modal.Title className="cancel-header">
                  <span className="info-icon"></span>
                  <div className="cancel-header-text">{headerText}</div>
              </Modal.Title>
              <div className="newRequest-content">
              {!isReadOnly ?
                  <Row className="override-email-row">
                      <Col xs={4} md={4} className="pl-0 override-form">
                          <Row>
                              <Col xs={12} md={12} className="override-label">
                                  <Radio
                                    checked={radioSelect === "Verisma"}
                                    onChange={e => onChangeRadio(e.target.value)}
                                    value="Verisma"
                                    name="radio-button-demo"
                                    inputProps={{ 'aria-label': 'Yes' }}
                                    required={true}
                                  />
                                  <Form.Label className="radio-label">Verisma</Form.Label>
                              </Col>
                          </Row>
                      </Col>
                      <Col xs={8} md={8} className="pl-0 override-form">
                          <Row>
                              <Col xs={12} md={12} className="override-label">
                                  <Radio
                                    checked={radioSelect === props.holdForFacility}
                                    onChange={e => onChangeRadio(e.target.value)}
                                    value="Facility Name"
                                    name="radio-button-demo"
                                    inputProps={{ 'aria-label': 'Yes' }}
                                  />
                                  <Form.Label className="radio-label">{props.holdForFacility}</Form.Label>
                              </Col>
                          </Row>
                      </Col>
                  </Row>
                    : 
                    <div>
                    <Row>
                        <Col xs={12} md={12} sm={12} lg={12} className="section-headers">
                            Select items to take off  hold:
                        </Col>
                    </Row>
                     <Row>
                        <Col>
                           <div className="wait-hold-note">NOTE: This transaction will be taken off hold when all hold reasons have been fulfilled.</div>
                        </Col>
                   </Row>
                   </div>}
                  {holdForError && (
                        <p className="red-text"> {holdForError} </p>
                        )}
                    {!isReadOnly ?
                    <Multiselect
                        options={props.waitAndHoldReasons}
                        onSelect={onSelect} 
                        onRemove={onRemove} 
                        displayValue="label" 
                        name={WaitAndHoldConstants.WAIT_HOLD_REASONS}
                        showCheckbox={true}
                        ref={multiselectRef}
                    />
                    : 
                    <Multiselect
                        options={props.selectedHoldReasons}
                        selectedValues={props.selectedHoldReasons} 
                        onSelect={onSelect} 
                        onRemove={onRemove} 
                        displayValue="label" 
                        name={WaitAndHoldConstants.WAIT_HOLD_REASONS}
                        showCheckbox={true}
                        ref={multiselectRef}
                    />}
                    {waitAndHoldError && (
                        <p className="red-text"> {waitAndHoldError} </p>
                        )}
                  <Row className="button-align">
                  <Col xs={6} md={6} className="button-position-cancel">
                          <a href="#!" onClick={handleClose} className="modal-cancel">
                              Cancel
                          </a>
                      </Col>
                      <Col xs={6} md={6} className="button-position-ok">
                          <IconWithButton
                              buttonText={buttonText}
                              onButtonClick={okHandler}
                              isSave={true}
                              hideArrowIcon={true}
                          />
                          </Col>    
                  </Row>
              </div>
          </div>
      </Modal>
  );
}
const WaitAndHoldModalReduxForm = reduxForm<{}, IProps>({
  form: WaitAndHoldConstants.WAIT_HOLD_MODAL_FORM,
  validate: localValidations,
  onSubmit: submitValidations,
  enableReinitialize: true,
  touchOnChange: true
})(WaitAndHoldModal)

function mapStateToProps(state: any, props: any) {
  const formValues = state.form && state.form[WaitAndHoldConstants.WAIT_HOLD_MODAL_FORM] ?
    state.form[WaitAndHoldConstants.WAIT_HOLD_MODAL_FORM]?.values : {}
    return {
        formState: state.form && state.form[WaitAndHoldConstants.WAIT_HOLD_MODAL_FORM],
        waitAndHoldReasons: state.dataEntryState.transactionWaitAndHoldReasons,
        transactionId: props.transactionId || state.landingPageState?.readOnlyTransactionDetails?.transactionId,
        statusIndicatorID: state.dataEntryState?.transactionDetails?.transactionStatus?.id,
        existingTransactionID: state.landingPageState.transactionId,
        initialValues: {
            ...formValues
        }
    }
}
function mapDispatchToProps(dispatch: Function) {
  return {
    postWaitAndHoldReasonsDispatch: (transactionId: string, waitHoldReasons:any, statusIndicatorId: any, waitFor: string) => 
    dispatch(postWaitAndHoldReasonsActionCreators(transactionId, JSON.stringify(waitHoldReasons), statusIndicatorId, waitFor)),
    resetRequestContext: () => dispatch(resetRequestContext()),
    continueTransaction: (transactionId: string, waitHoldReasons:any) => dispatch(patchTransactionContinueActionCreator(transactionId, JSON.stringify(waitHoldReasons))),
    transactionWaitAndHoldReasons: (data) => dispatch(getWaitAndHoldReasons(data)),
    assignSearchTransactionID: (transactionId: string, facilityId: string) => dispatch(headerAssignTransactionActionHeader(transactionId, facilityId)),
    unassignSearchTransactionID: (transactionId: string) => dispatch(headerUnassignTransactionIDActionCreator(transactionId))
  };
}
export default connect<{}, {}, IProps>(mapStateToProps,mapDispatchToProps)(WaitAndHoldModalReduxForm);