
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import './CustomTable.scss';
import AddNewRequestor from '../../assets/images/tabImages/AddNewRequestor.png';
import nationalR from '../../assets/images/tabImages/map.png';
import eTransferR from '../../assets/images/tabImages/Folder_arrow.png';
import { getComparator } from './AccordionTable'
import { isAPIFetching } from '../utils/appUtils';
import ReactHover, { Hover, Trigger } from '../reactHover/ReactHover';
import DragTableWrapper from '../../components/dataEntryDetails/dataEntryComponents/DragTableWrapper'
interface CustomTableProps {
    setGrid?: Function;
    onTableRowClickHandler?: Function;
    rows?: object
    headCells?: object
    addNewText?: String
    defaultSortCol?: String
    defaultSortVal?: String
    setAddNewRequestor?: Function
    searchTerms?: any
    showAddNewRequestor?: Boolean,
    apiStatus?: string,
    embedded?: Boolean,
    disabled?: Boolean
}

function stableSort(array, comparator, orderBy, orderVal = null) {

    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        if (orderVal && ((a[0][orderBy] ?? "").toString().startsWith(orderVal) != (b[0][orderBy] ?? "").toString().startsWith(orderVal))){
            return a[0][orderBy].toString().startsWith(orderVal) ? -1 : 1;
        }
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export function CustomTableHead(props) {

    const { order, orderBy, onRequestSort, headCells, includeComment } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead style={{position: 'sticky', top: 0}}>
            <TableRow>
                {headCells.map((headCell) => (
                    !headCell.commentField || props.includeComment ?
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={headCell?.label === 'EMPI' ? { width: '105px' } : { width: 'fit-conetent' }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell> :
                    <></>
                ))}
            </TableRow>
            {props.children}
        </TableHead>
    );
}

export default function CustomTable(props) {

    const [order, setOrder] = React.useState('asc');
    const [orderVal, setOrderVal] = React.useState(props.defaultSortVal);
    const [orderBy, setOrderBy] = React.useState(props.defaultSortCol);
    const [showAddNewRequestor, setShowAddNewRequestor] = React.useState(props.showAddNewRequestor);
    const [filteredRows, setFilteredRows] = React.useState(props.rows);
    const [filterValues, setFilterValues] = React.useState( { firstName: '', lastName: '', phone: '', fax: '', email: '', } )
    const [rightPaneWidth, setRightPaneWidth] = React.useState <any> (12);
    const [selectedItem, setSelectedItem] = React.useState<any>(null);
    const requestorTable = props.addNewText === 'Add new Requestor';
    const contactTable = props.addNewText === 'Add new Contact';
    const handleRequestSort = (event, property) => {
        setOrderVal(null);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const rowClickHandler = (rowData) => {
        setSelectedItem(rowData);
        props.onTableRowClickHandler && props.onTableRowClickHandler(rowData);
        props.setGrid && props.setGrid(false);
    }

    const optionsCursorTrueWithMargin = {
        followCursor: false,
        shiftX: 0,
        shiftY: 15,
        style: {
            right: "0px",
            zIndex: "9999"
        }
    };

    const filterTable = (e, headCell) => {
        setFilterValues({...filterValues, [headCell]: e.target.value})
    }

    React.useEffect(() => {
        if(orderBy == "phonefax"){
            if(props.rows.some(r => r.fax.startsWith(orderVal))){
                setOrderBy("fax")
            }else{
                setOrderBy("phone")
            }
        }
    },[orderBy])
    
    React.useEffect(()=>{
        if (contactTable){
            const filterCategories = Object.keys(filterValues);
            setFilteredRows(
                props.rows.filter((row)=>{
                    return Object.entries(row).every((e:any)=>{
                        let rowCategory = e[0];
                        let rowValue = e[1];
                        // if the current row is a category that can be filtered...
                        if (filterCategories.includes(rowCategory)) {
                            // and if the row's value includes the value entered into the filter...
                            if (rowValue.toLowerCase().includes(filterValues[rowCategory].toLowerCase())) {
                                return true
                            }
                            else { return false }
                        }
                        // if the current row is not a category that can be filtered, ignore it...
                        else{
                            return true
                        }
                    })
                })
            )
        }
    }, [filterValues])

    React.useEffect(()=>{
        setRightPaneWidth(document.getElementById('requestor-tab-right-pane')?.offsetWidth)
    })


    function display(){
        return (<div className='customTable'>
            {props.disabled && <div className='customTableDisable'></div>}
            <Table size="small" stickyHeader style={requestorTable
                ? { display: 'flex', flexDirection: 'column', width: '100%', position: 'relative', left: 0, margin: 0 }
                : props.addNewText === 'Add new Requestor'
                    ? { bottom: '55px' }
                    : props.addNewText === 'Add new Contact'
                        ? { bottom: '131px', width: '35%' }
                        : { top: '380px', right: '3.2%', overflowY: 'auto' }}>
                <div className="table-row-wrapper" style={{ maxHeight: props.addNewText === 'Add new Requestor' ? '305px' : '235px' }}>
                    <CustomTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={props.headCells}
                        includeComment={props.rows && props.headCells.some(h => h.commentField && props.rows.some(r => r[h.id]))}
                    >
                        {contactTable && props.headCells.map((headCell) => { 
                        return (
                            <TableCell style={{ top: 39, width: 'fit-content' }}>
                                <input
                                    type="text"
                                    onChange={(e) => filterTable(e, headCell.id)}
                                    className='table-filter-input'
                                />
                            </TableCell>
                        )
                    })}
                </CustomTableHead>
                <TableBody>
                    {isAPIFetching(props.apiStatus) ?
                        <TableRow className="row-wrapper no-records" key={'010101'}>
                            <TableCell colSpan={props.headCells && props.headCells.length}>{"Searching..."}</TableCell>
                        </TableRow> : props.rows && !props.rows.length ? <TableRow className="row-wrapper no-records" key={'010101'}>
                            <TableCell colSpan={props.headCells && props.headCells.length}>{"No matching records found"}</TableCell>
                        </TableRow> : stableSort(contactTable ? filteredRows : props.rows, getComparator(order, orderBy), orderBy, orderVal)
                            .map((row, index) => {
                                return (
                                    <TableRow style={{ height: "51px" }} className={"row-wrapper" + (row === selectedItem ? " selected" : "")} key={row[index]} onClick={() => rowClickHandler(row)}>
                                        {props.headCells.map((headCell) => {
                                            if (headCell.commentField) {
                                                return (
                                                    props.rows.some(r => r[headCell.id]) ?
                                                        <TableCell>
                                                            {
                                                                row[headCell.id] && row[headCell.id].trim() ?
                                                                    <ReactHover options={optionsCursorTrueWithMargin}>
                                                                        <Trigger type="trigger">
                                                                            <i className="fa fa-comment note-space" aria-hidden="true"></i>
                                                                        </Trigger>
                                                                        <Hover type="hover">
                                                                            <div className="react-hover">
                                                                                <blockquote className="patient-comment">
                                                                                    Comment:<br />
                                                                                    {row[headCell.id]}
                                                                                </blockquote>
                                                                            </div>
                                                                        </Hover>
                                                                    </ReactHover> :
                                                                    <></>
                                                            }
                                                        </TableCell> :
                                                        <></>
                                                )
                                            } else if (headCell.id === 'en') {
                                                return (
                                                    <TableCell align={headCell.id === 'state' ? "center" : "left"}>
                                                        {row['eTransfer'] ?
                                                            <Tooltip title="eTransfer">
                                                                <img src={eTransferR} alt="" />
                                                            </Tooltip>
                                                            : ''}
                                                        &nbsp;
                                                        {row['national'] ?
                                                            <Tooltip title="National" >
                                                                <img src={nationalR} alt="" />
                                                            </Tooltip>
                                                            : ''}
                                                    </TableCell>
                                                )
                                            } else if (headCell.id === 'address') {

                                                return (
                                                    <TableCell align={headCell.id === 'state' ? "center" : "left"}>
                                                        {row.requestorAddress?.address1}
                                                        <br />
                                                        {row.requestorAddress?.address2}
                                                        {row.requestorAddress?.address2 ? <br /> : ""}
                                                    </TableCell>
                                                );
                                            } else if (headCell.id === 'city' || headCell.id === 'state' || headCell.id === 'zip') {
                                                return (
                                                    <TableCell align={headCell.id === 'state' ? "center" : "left"}>{row.requestorAddress?.[headCell.id]}</TableCell>
                                                )
                                            } else {
                                                return (
                                                    <TableCell align={headCell.id === 'state' ? "center" : "left"}>{row[headCell.id]}</TableCell>
                                                );
                                            }

                                        })}
                                    </TableRow>
                                );
                            }
                            )}
                </TableBody>
            </div>
            {showAddNewRequestor ?
                (<caption className='add-new-note'>
                    <a href="#!" onClick={() => {
                        rowClickHandler({})
                        props.setAddNewRequestor(true)
                    }}>
                        <img
                            src={AddNewRequestor}
                            alt={props.addNewText}
                            className="add-new-icon"
                        />
                        <span className='empty-css'>{props.addNewText}</span>
                    </a>
                </caption>)
                : null}
        </Table></div>)
    }

    return props.embedded ? display() : (
        <>
            <div className='table-backdrop' onClick={() => !requestorTable && props.setGrid(false)}></div>
            <DragTableWrapper requestorTable={requestorTable} setGrid={props.setGrid}>
                {display()}
            </DragTableWrapper>
        </>
    );
}
