
import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import IconWithButton from "../IconWithButton/IconWithButton";
import './alertPopup.scss';
import { useHistory } from "react-router-dom";

interface Props {
    setCompleteTransactionPopUp?: any,
    getConfirmation?: any,
    transactionID?: String,
    completeTransaction: any,
    getNextTransactionId?: any,
    goToNextTransaction: any,
    facilityId: any,
    onChangeDataEntryTab: any,
    activeTabId: any,
    setWait: any,
    requestType?: any,
    setCreateNewTransactionComplete?: any,
    createTransaction?: any,
    clearUserLocks?: any,
    isCreateTransaction?: any,
    clearPreviousTransactionOnComplete?: any,
    requestContext: any,
    resetRequestContext: Function,
    hasSensitiveData?: any,
    setCloseTranctionComplete: Function,
    closeTranctioncomplete: boolean 
}

function CompleteTransactionPopUp(props: Props) {

    const {setCompleteTransactionPopUp, getConfirmation, transactionID,setCreateNewTransactionComplete} = props
    const history = useHistory();

    const onclickCancelhandler = () => {
        setCompleteTransactionPopUp(false)
        setCreateNewTransactionComplete(false)
        getConfirmation('Cancel')
    }

    const onClickYesHandler = () => {
        setCompleteTransactionPopUp(false)
        getConfirmation('OK')
        props.completeTransaction(transactionID)
        if(props.requestType && props.requestType !== '66')
        {
            if(props.isCreateTransaction){
                console.log("test")
                props.resetRequestContext();
                setCreateNewTransactionComplete(false)
                props.setWait(true);
                setTimeout(()=>{
                    props.setWait(false);
                    props.clearPreviousTransactionOnComplete && props.clearPreviousTransactionOnComplete()
                    props.createTransaction && props.createTransaction({ facilityId: props.facilityId.toString()})
                    props.clearUserLocks && props.clearUserLocks()
                    props.onChangeDataEntryTab(1, false)
                }, 1000)
            }
            else if(props.closeTranctioncomplete){
                props.setCloseTranctionComplete(false)
                props.resetRequestContext();
                history?.push("/home")
            }
            else{
                //props.getNextTransactionId(props.facilityId);
               // props.onChangeDataEntryTab(1, false)
                props.setWait(true);
                setTimeout(()=>{
                    props.setWait(false);
                    props.clearPreviousTransactionOnComplete && props.clearPreviousTransactionOnComplete()
                    props.getNextTransactionId(props.facilityId, props.requestContext);
                    props.onChangeDataEntryTab(1, false)
                }, 1000)
                
            } 
        }
        else{
            props.setCloseTranctionComplete(false)
            props.resetRequestContext();
            history?.push("/home")
        }
    }
    
    return (
        <div>
            <Modal className="cancel-dimension complete-transaction-modal" show={true}>
                <div className="alert-container">
                    <Modal.Title className="cancel-header">
                        <span className="info-icon"></span>
                        <div className="cancel-header-text">Complete transaction - {transactionID}</div>
                    </Modal.Title>
                    <div className="newRequest-content">
                        <div className="delete-confirmation-text">
                            <p>
                                By selecting Yes, I affirm the information entered is truthful and accurate. This action will move the transaction to a distribution queue and cannot be undone.
                            </p>
                        </div>
                        <Row className="button-align">
                            <Col xs={6} md={6} className="button-position-cancel">
                                <a href="#!" onClick={onclickCancelhandler} className="modal-cancel">
                                    Cancel
                                </a>
                            </Col>
                            <Col xs={6} md={6} className="button-position-ok">
                                <IconWithButton
                                    buttonText={"Yes"}
                                    onButtonClick={onClickYesHandler}
                                    isSave={true}
                                    hideArrowIcon={true}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export const CompleteTransactionWaiting = (props) =>{
    return (
        <div id='wait' className='tx-completing-background' >
         <div className='tx-completing-modal'>
          <div className='tx-completing-header'></div>
          <p>Completing Transaction...</p>
         </div>
      </div> 
    )
}

export default CompleteTransactionPopUp