export const PUSH = 'ROUTER/PUSH';
export const REPLACE = 'ROUTER/REPLACE';
export const GO = 'ROUTER/GO';
export const GO_BACK = 'ROUTER/GO_BACK';
export const GO_FORWARD = 'ROUTER/GO_FORWARD';
export const LOCATION_CHANGE = "ROUTER/LOCATION_CHANGE"
export const push = (href: string) => {
    return {
      type: PUSH,
      payload: href,
    }
  };
  export const replace = (href: any) => ({
    type: REPLACE,
    payload: href,
  });
  export const go = (index: any) => ({
    type: GO,
    payload: index,
  });
  export const goBack = () => ({
    type: GO_BACK,
  });
  export const goForward = () => ({
    type: GO_FORWARD,
  });

  const initialState = {
    pathname: '/',
    search: '',
    hash: '',
  };

 export const navigationState = (state = initialState, action: any) => {
    switch (action.type) {
      case LOCATION_CHANGE:
        return {
          ...state,
          ...action.payload,
        };
      default:
        return state;
    }
  };

  export const routerHistoryMiddleware = (history: any) => () => (next: any) => (action: any) => {
    switch (action.type) {
      case PUSH:
        history.push(action.payload);
        break;
      case REPLACE:
        history.replace(action.payload);
        break;
      case GO:
        history.go(action.payload);
        break;
      case GO_BACK:
        history.goBack();
        break;
      case GO_FORWARD:
        history.goForward();
        break;
      default:
        return next(action);
    }
  };

  export const locationChange = (payload: any) => {
    return(getState: any) =>
    {
        switch (payload.pathname) {
          default:
               return({
                type: LOCATION_CHANGE,
                payload: {
                  pathname: payload.pathname,
                  search: payload.search,
                  hash: payload.hash,
              }});
        }
    }
  }
