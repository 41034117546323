import React, { Fragment } from 'react';
import { Col, Row } from "react-bootstrap";
import clock from "../../../assets/images/editIcons/clock.png";
import deleteFilled from "../../../assets/images/Delete_filled.png";
import CommentIcon from "../../../assets/images/comment-text.png";
import EditIcon from "../../../assets/images/editIcons/editSmallIcon.png";
import { getMomentDate, LightTooltip, normalizeData, isPastDate } from '../../../common/utils/appUtils'
import { TASK_STATUS, TASK_STATUS_VALUES } from '../../../common/constants/ApiConstants';

function getStyles(status) {
    switch (status) {
        case TASK_STATUS.COMPLETED:
            return { backgroundColor: "rgba(99, 185, 108, 0.2)" };
        case TASK_STATUS.IN_PROGRESS:
            return { backgroundColor: "rgba(95, 158, 197, 0.2)" };
        case TASK_STATUS.NEW:
            return { backgroundColor: "rgba(228, 129, 72, 0.2)" };
        default:
            return { backgroundColor: "rgba(228, 129, 72, 0.2)" };
    }
}

export function TaskStatusDropdown(props) {
    return (
        <ul
            className="nav navbar-nav navbar-right"
            style={props.style || { height: "2rem" }}
        >
            <li className="dropdown">
                <span
                    className="dropdown-toggle dropdown-style"
                    style={getStyles(props.selectedStatus)}
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    {props.selectedStatus}
                    <span className="caret"></span>
                </span>
                <ul className="dropdown-menu">
                    <li
                        className={!props.disableListItem ? "list-item": "list-item list-item-disabled"}
                        onClick={() => props.onChange(TASK_STATUS_VALUES.Completed, TASK_STATUS.COMPLETED)}
                    >
                        <span className="dot completed"></span> {TASK_STATUS.COMPLETED}{" "}
                    </li>
                    <li
                        className={!props.disableListItem ? "list-item": "list-item list-item-disabled"}
                        onClick={() => props.onChange(TASK_STATUS_VALUES["In-Progress"], TASK_STATUS.IN_PROGRESS)}
                    >
                        <span className="dot inProgress"></span> {TASK_STATUS.IN_PROGRESS}{" "}
                    </li>
                    <li
                        className="list-item"
                        onClick={() => props.onChange(TASK_STATUS_VALUES.New, TASK_STATUS.NEW)}
                    >
                        <span className="dot assigned"></span> {TASK_STATUS.NEW}{" "}
                    </li>
                </ul>
            </li>
        </ul>
    );
}

function NewTaskCollapsed(props) {

    const deleteTask = (taskId) => {
        props.deleteTaskbyId(taskId)
        props.setDeleteTaskPopUp(true)
    }

    const onChangeTaskStatus = (id: any, status: string) => {
        let updatedData = { ...props.taskDetails };
        updatedData = {
            ...updatedData,
            taskStatus: status,
            taskStatusID: id,
            transactionId: props.transactionId
        };
        const requestObject = {
            index: props.index,
            updatedData,
            isNewTask: false
        }
        props.updateTask({...updatedData, transactionId: props.transactionId, dueDate: getMomentDate(updatedData.dueDate)})
        //This seems to be creating a new task
        //props.updateUserTasks && props.updateUserTasks(requestObject)
    };

    const onClickEdit = () => {
        let updatedId = props.editingTaskId === props.taskDetails.id ? 0 : props.taskDetails.id
        props.setEditingTaskId(updatedId)
        props.updateCreateTaskStatus(false)
    }
    const getUserGroupDefaultValue = () => {
        const groupId = props.taskDetails.assignedToGroupIds
        if (!groupId) {
            return props.taskDetails.assignedTo?.displayName
        } else {
            const normalizedData = normalizeData(props.userGroups, "value")
            return normalizedData[groupId]?.label
        }
    }
    let commentsFiltered = props.userTaskComments[props.taskDetails.id]?.filter(comment => comment.taskID === 1050);
    return (
        <Fragment>
            <div className="taskAccordionCollapsed">
                <Row>
                    <Col lg={5} style={{ paddingRight: "0px" }}>
                        <div className="d-flex flex-row">
                            <div className="d-flex flex-column">
                                <div>
                                    <span>To: </span>{getUserGroupDefaultValue()}
                                </div>
                                <div>
                                    <span>By: </span>{props.taskDetails.assignedBy?.displayName}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={5} style={{ padding: "0px" }}>
                        <div className="d-flex flex-column">
                            <TaskStatusDropdown
                                id={props.taskDetails.taskStatusID}
                                selectedStatus={props.taskDetails.taskStatus}
                                onChange={onChangeTaskStatus}
                            />
                            <div className={isPastDate(props.taskDetails.dueDate) ? "red-text" : ""}>
                                <img
                                    className="cancelButton image-margin-reject edit-icon"
                                    src={clock}
                                    alt="X"
                                />
                                <span>Due: </span>{getMomentDate(props.taskDetails.dueDate)}
                            </div>
                        </div>
                    </Col>
                    <Col lg={2}>
                        <div style={{ float: "right" }}>
                            <Row>
                                <Col>
                                    <LightTooltip title="Edit Task" placement="right">
                                        <span>
                                            <img src={EditIcon} alt="Edit Icon" className="pointer-cursor text-center edit-task-icon" onClick={onClickEdit} />
                                        </span>
                                    </LightTooltip>
                                    <br />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {Number(props.taskDetails.createdBy?.id) === global.userID && props.taskDetails.taskStatusID === TASK_STATUS_VALUES.New ?
                                        <LightTooltip title="Delete Task" placement="right">
                                            <span onClick={() => deleteTask(props.taskDetails.id)}>
                                                <img src={deleteFilled} className="pointer-cursor text-center" alt="Delete Icon" />
                                            </span>
                                        </LightTooltip>
                                        : null}
                                    <br />
                                </Col>
                            </Row>


                            <Row>
                                {commentsFiltered?.length ?
                                    <Col>
                                        <span
                                            style={{ color: "#5F9EC5", fontWeight: "bold", position: "absolute", marginTop: '14px' }}
                                        >
                                            {commentsFiltered?.length}
                                        </span>
                                        <LightTooltip title="Count of Comments" placement="right">
                                            <span onClick={onClickEdit}>
                                                <img src={CommentIcon}  className="pointer-cursor text-center" alt="Comment Icon" />
                                            </span>
                                        </LightTooltip>
                                    </Col>

                                    : null}
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="d-flex flex-column margin-bottom">
                            <span
                                className="subjectDescription"
                                style={{ fontWeight: "bold" }}
                            >
                                {props.taskDetails.subject}
                            </span>

                        </div>
                        <span className="taskdescriptionstyle">
                            {props.taskDetails.description}
                        </span>
                    </Col>
                </Row>

            </div>

        </Fragment>
    );
}

export default NewTaskCollapsed;
