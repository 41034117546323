import { call, put, all } from 'redux-saga/effects'
import { API_INITIAL, API_FETCHING, API_SUCCESS, API_FAILURE, isSuccessStatusCode, TASK_STATUS_VALUES }
    from '../../common/constants/ApiConstants'
import Api from "../../services/Api";
import {
    createNewTransactionAPI, generateOrGetTransactionIdAPI, getTransactionSearchResultsAPI, assignTransactionIdAPI, unAssignTransactionAPI, getHealthOrganizationSearchAPI,
    clearUserLocksAPI, getInitials, getTransactionAttributesAPI, getRequestorDetailsAPI, getTransactionDocumentsAPI, getUserTasksAPI, getUserTaskCommentsAPI,
    updateUserTaskAPI, adNewUserTaskAPI, createUserTaskCommentAPI, deleteTransactionTaskAPI, getTransactionNotesAPI, updateTransactionNotesAPI, getTransactionNoteTypesAPI,
    getRequestorBillingDetailsAPI, getAuditTrailAPI, getSystemBulletinMessage, userAcknowledgeBulletin, uploadDocumentAPI, postWebPrintScanAPI, getHealthSystemByFacilityAPI,
    getFacilitySpotlightDetailsAPI, getFacilitySpotlightFiltersAPI, putResendTransactionAPI, getRejectOptionsAPI, getLogoUrlAPI, getDefinedTemplateName, getOutboundTemplateFields,
    SendOutboundTemplateFields, getTrackingLinkAPI
} from '../dataEntryDetails/test/DataEntry.APIs'
import { push } from '../../redux/navigation/navigationDucks'
import { FacilitiesCountsModal, FacitiesModal, HealthSystemCountsModal, HealthSystemModal, OrganizationDetailsModal } from '../dataEntryDetails/dataEntryComponents/DataEntryDetails'
import { AccessTimeTwoTone } from '@material-ui/icons'
import { get } from '../../services/Network'
import {
    generatePickerOptionsNew,
    getSortedTasks,
    sortDateObject,
    updateUserTaskDetailsHelperFunction,
    updateUserTaskCommentsHelperFunction
} from '../../common/utils/reduxUtils';
//Constants
export const LandingPageActionConstants = {
    //getHealthOrganizationsListRequest: "getHealthOrganizationsListRequest/LandingPage",
    //getHealthOrganizationsListSuccess: "getHealthOrganizationsListSuccess/LandingPage",
    //getHealthOrganizationsListFailure: "getHealthOrganizationsListFailure/LandingPage",

    API_ERROR_STATE: "API_ERROR_STATE",

    getHealthOrganizationsSearchRequest: "getHealthOrganizationsSearchRequest/LandingPage",
    getHealthOrganizationsSearchSuccess: "getHealthOrganizationsSearchSuccess/LandingPage",
    getHealthOrganizationsSearchFailure: "getHealthOrganizationsSearchFailure/LandingPage",

    getHltOrgTransactionsCountSuccess: "getHltOrgTransactionsCountSuccess/LandingPage",
    getHltOrgTransactionsCountFailure: "getHltOrgTransactionsCountFailure/LandingPage",

    onClickHealthOrganization: "onClickHealthOrganization/LandingPage",
    resetSelectedHealthOrganizatonDetails: "resetHealthOrganizationDetails/LandingPage",

    setSelectedFacilityDetails: "setSelectedFacilityDetails/LandingPage",
    resetSelectedFacilityDetails: "resetSelectedFacilityDetails/LandingPage",

    getTransactionIdSuccess: "getTransactionIdSuccess/LandingPage",
    getTransactionIdFailure: "getTransactionIdFailure/LandingPage",

    createTransactionRequest: "createTransactionRequest/LandingPage",
    createTransactionSuccess: "createTransactionSuccess/LandingPage",
    updateSelectedFacilityDetails: "updateFacilityDetails/LandingPage",
    updateAPIStatus: "updateAPIStatus/LandingPage",
    setNextTransactionId: "setNextTransactionId/LandingPage",
    setPreviousTransaction: "setPreviousTransaction/LandingPage",

    getTransactionSearchResultsReset: "getTransactionSearchResultsReset/DataEntryDetails",
    getTransactionSearchResultsRequest: "getTransactionSearchResultsRequest/DataEntryDetails",
    getTransactionSearchResultsSuccess: "getTransactionSearchResultsSuccess/DataEntryDetails",
    HEADER_ASSIGN_TRANSACTION: "HEADER_ASSIGN_TRANSACTION",
    HEADER_ASSIGN_TRANSACTION_SAGA: "HEADER_ASSIGN_TRANSACTION_SAGA",
    HEADER_UNASSIGN_TRANSACTION: "HEADER_UNASSIGN_TRANSACTION",
    HEADER_UNASSIGN_TRANSACTION_SAGA: "HEADER_UNASSIGN_TRANSACTION_SAGA",
    HEADER_ASSIGN_TRANSACTION_INFORMATION: "HEADER_ASSIGN_TRANSACTION_INFORMATION",
    HEADER_ASSIGN_TRANSACTION_INFORMATION_CLEAR: "HEADER_ASSIGN_TRANSACTION_INFORMATION_CLEAR",
    CLEAR_SEARCH_SELECTED_TRANSACTION_ID: "CLEAR_SEARCH_SELECTED_TRANSACTION_ID",
    CLEAR_SELECTED_TRANSACTION_ID: "CLEAR_SELECTED_TRANSACTION_ID",
    clearUserLocksRequest: "clearUserLocksRequest/LandingPage",
    clearUserLocksSuccess: "clearUserLocksSuccess/LandingPage",

    getInitialsRequest: 'GET_INITIALS_REQUEST',
    getInitialsSuccess: 'GET_INITIALS_SUCCESS',
    getInitialsFailure: 'GET_INITIALS_FAILURE',

    GET_NEXT_TRANSACTION_REQUEST: "GET_NEXT_TRANSACTION_REQUEST",
    getPreviousTransactionAPISuccess: "getPreviousTransactionAPISuccess/DataEntryDetails",
    
    SHOW_READONLY_TRANSACTION_REQUEST: "SHOW_READONLY_TRANSACTION_REQUEST",
    SHOW_READONLY_TRANSACTION_RESPONSE: "SHOW_READONLY_TRANSACTION_RESPONSE",

    updateReadOnlyUserTasksListRequest: "updateReadOnlyUserTasksListRequest",
    updateReadOnlyUserTasksListResponse: "updateReadOnlyUserTasksListResponse",
    updateReadOnlyUserTaskComments: "updateReadOnlyUserTaskComments",
    createReadOnlyUserTaskComment: "createReadOnlyUserTaskComment",
    deleteReadOnlyTransactionTaskRequest: "deleteReadOnlyTransactionTaskRequest",
    deleteReadOnlyTransactionTaskResponse: "deleteReadOnlyTransactionTaskResponse",
    getReadOnlyUserTasksRequest: "getReadOnlyUserTasksRequest",
    getReadOnlyUserTasksSuccess: "getReadOnlyUserTasksSuccess",
    getReadOnlyUserTasksFailure: "getReadOnlyUserTasksFailure",
    addNewReadOnlyUserTaskRequest: "addNewReadOnlyUserTaskRequest",
    updateReadOnlyUserTaskRequest: "updateReadOnlyUserTaskRequest",
    updateReadOnlyUserTaskResponse: "updateReadOnlyUserTaskResponse",

    getReadOnlyTransactionNoteTypesRequest: "getReadOnlyTransactionNoteTypesRequest",
    getReadOnlyTransactionNoteTypesSuccess: "getReadOnlyTransactionNoteTypesSuccess",

    getReadOnlyTransactionNotesRequest: "getReadOnlyTransactionNotesRequest",
    getReadOnlyTransactionNotesSuccess: "getReadOnlyTransactionNotesSuccess",
    getReadOnlyTransactionNotesFailure: "getReadOnlyTransactionNotesFailure",

    updateReadOnlyTransactionNotes: "updateReadOnlyTransactionNotes",
    changeRequestContext: "changeRequestContext",
    resetRequestContext: "resetRequestContext",

    getAuditTrailRequest: "LP_GET_AUDIT_TRAIL_REQUEST",
    getAuditTrailResponse: "LP_GET_AUDIT_TRAIL_RESPONSE",

    getSystemBulletinMessagesRequest: "GET_SYSTEM_BULLETIN_MESSAGE_REQUEST",
    getSystemBulletinMessagesResponse: "GET_SYSTEM_BULLETIN_MESSAGE_RESPONSE",
    userAcknowledgesBulletinRequest: "USER_ACKNOWLEDGES_BULLETIN_REQUEST",
    userAcknowledgesBulletinResponse: "USER_ACKNOWLEDGES_BULLETIN_RESPONSE",
    setIsShowingSystemBulletinMessage: "SET_IS_SHOWING_SYSTEM_BULLETIN_MESSAGE",

    uploadMultipleDocumentsRequest: "uploadMultipleDocumentsRequest/ReadOnly",

    uploadDocumentRequest: "uploadDocumentRequest/ReadOnly",
    uploadDocumentSuccess: "uploadDocumentSuccess/ReadOnly",
    uploadDocumentFailure: "uploadDocumentFailure/ReadOnly",
    uploadDocumentInitial: "uploadDocumentInitial/ReadOnly",

    getTransactionDocumentsRequest: "getTransactionDocumentsRequest/ReadOnly",
    getTransactionDocumentsSuccess: "getTransactionDocumentsSuccess/ReadOnly",
    getTransactionDocumentsFailure: "getTransactionDocumentsFailure/ReadOnly",

    postWPSRequest: "GET_READONLY_WPS_REQUEST",
    postWPSResponse: "GET_READONLY_WPS_RESPONSE",
    getFacilitySpotlightDetailsRequest: "getFacilitySpotlightDetailsRequest",
    getFacilitySpotlightDetailsResponse: "getFacilitySpotlightDetailsResponse",
    getFacilitySpotlightFiltersRequest: "getFacilitySpotlightFiltersRequest",
    getFacilitySpotlightFiltersResponse: "getFacilitySpotlightFiltersResponse",

    resendTransactionRequest: "resendTransactionRequest",
    resendTransactionRequestStatus: "resendTransactionRequestStatus",

    getRejectOptionsRequest: "getRejectOptionsRequest/ReadOnly",
    getRejectOptionsSuccess: "getRejectOptionsSuccess/ReadOnly",
    getRejectOptionsFailure: "getRejectOptionsFailure/ReadOnly",

    getLogoUrlRequest: "getLogoUrlRequest",
    getLogoUrlSuccess: "getLogoUrlSuccess",
    getLogoUrlFailure: "getLogoUrlFailure",

    getDefinedTemplateNamesRequest: "getDefinedTemplateNamesRequest",
    getDefinedTemplateNamesSuccess: "getDefinedTemplateNamesSuccess",

    getOutboundTemplateFieldsRequest: "getOutboundTemplateFieldsRequest",
    getOutboundTemplateFieldsSuccess: "getOutboundTemplateFieldsSuccess",
    getOutboundTemplateFieldsFailure: "getOutboundTemplateFieldsFailure",

    changeDelayReason:"changeDelayReason",
    changeOutboundFieldValue:"changeOutboundFieldValue",

    sendOutboundTemplateFieldsRequest: "sendOutboundTemplateFieldsRequest/readonly",
    sendOutboundTemplateFieldsSuccess: "sendOutboundTemplateFieldsSuccess/readonly",
    sendOutboundTemplateFieldsFailure: "sendOutboundTemplateFieldsFailure/readonly",

    getTrackingLinkRequest: "getTrackingLinkRequest",
    getTrackingLinkSuccess: "getTrackingLinkSuccess",
    getTrackingLinkFailure: "getTrackingLinkFailure"
}

const reduxScope = {
    requestContext:  1
};

// create reducers here

const defaultState = {

    getHealthOrganizationsSearch: null,
    getHealthOrganizationAPIStatus: API_INITIAL,

    hltOrgTransactionsData: null,
    getHltOrgTransactionsAPIStatus: API_INITIAL,

    selectedHealthOrganizationDetails: null,

    selectedFacilityDetails: null,

    getTransactionIdAPIStatus: API_INITIAL,

    transactionId: null,

    createdTransactionDetails: {},
    createTransactionAPIStatus: API_INITIAL,
    transactionSearchResults: [],
    transactionSearchResultsStatus: API_INITIAL,
    searchSelectedTransactionId: null,
    searchSelectedFacilityId: null,
    assignAPIInfo: null,
    searchSelectedTransactionIdMemory: null,
    isNewTransaction: false,
    userPersonalInfo: {},
    userPersonalInfoAPIStatus: API_INITIAL,
    userTasks: [],
    userTasksComments: [],
    transactionNotes: [],
    requestContext: 1,

    getAuditTrailStatus: API_INITIAL,
    auditTrail: [],
    systemBulletinMessages: [],
    readOnlyTransactionDetails: <any>null,
    facilitySpotlightFilters: [],
    facilitySpotlightDetails: [],

    resendTransactionRequestStatus: API_INITIAL,
    
    definedOutboundTemplates: [],
    outboundTemplateFields: [{}],
    outboundTemplateFieldsResponseStatusCode: API_INITIAL,
    sendOutboundTemplateFieldsResponseStatusCode: API_INITIAL,

    logoUrlStatusCode: API_INITIAL,
    logoUrl: "",

    getTrackingLinkAPIStatus: API_INITIAL,
}

export const landingPageState = (state = defaultState, action: any) => {
    switch (action.type) {
        case LandingPageActionConstants.CLEAR_SELECTED_TRANSACTION_ID:
            return {
                ...state,
                transactionId: null
            }
        case LandingPageActionConstants.getHealthOrganizationsSearchRequest:
            return {
                ...state,
                getHealthOrganizationAPIStatus: API_FETCHING
            }
        case LandingPageActionConstants.getHealthOrganizationsSearchSuccess:
            return {
                ...state,
                getHealthOrganizationsSearch: action.data
                    .sort((a, b) => a.healthSystem.name < b.healthSystem.name ? -1 : a.healthSystem.name > b.healthSystem.name ? 1 : 0),
                getHealthOrganizationAPIStatus: API_SUCCESS
            }
        case LandingPageActionConstants.getHealthOrganizationsSearchFailure:
            return {
                ...state,
                getHealthOrganizationAPIStatus: API_FAILURE
            }
        case LandingPageActionConstants.getHltOrgTransactionsCountSuccess:
            return {
                ...state,
                getHltOrgTransactionsAPIStatus: API_SUCCESS,
                hltOrgTransactionsData: action.data
            }
        case LandingPageActionConstants.getHltOrgTransactionsCountFailure:
            return {
                ...state,
                getHltOrgTransactionsAPIStatus: API_FAILURE
            }
        case LandingPageActionConstants.onClickHealthOrganization:
            return {
                ...state,
                selectedHealthOrganizationDetails: action.data,
                hltOrgTransactionsData: action.data,
                getHltOrgTransactionsAPIStatus: API_FETCHING,
                isNewTransaction: false
            }
        case LandingPageActionConstants.resetSelectedHealthOrganizatonDetails:
            return {
                ...state,
                selectedHealthOrganizationDetails: null,
                selectedFacilityDetails: null,
                transactionId: null,
                isNewTransaction: false
            }
        case LandingPageActionConstants.setSelectedFacilityDetails:
            return {
                ...state,
                selectedFacilityDetails: action.data,
                getTransactionIdAPIStatus: API_FETCHING
            }
        case LandingPageActionConstants.updateSelectedFacilityDetails:
            return {
                ...state,
                selectedFacilityDetails: action.data
            }
        case LandingPageActionConstants.resetSelectedFacilityDetails:
            return {
                ...state,
                selectedFacilityDetails: null,
                transactionId: null
            }
        case LandingPageActionConstants.getTransactionIdSuccess:
            return {
                ...state,
                transactionId: action.data,
                getTransactionIdAPIStatus: API_SUCCESS
            }
        case LandingPageActionConstants.getTransactionIdFailure:
            return {
                ...state,
                getTransactionIdAPIStatus: API_FAILURE
            }
        case LandingPageActionConstants.createTransactionRequest:
            return {
                ...state,
                createTransactionAPIStatus: API_FETCHING
            }
        case LandingPageActionConstants.createTransactionSuccess:
            return {
                ...state,
                createdTransactionDetails: action.data,
                isNewTransaction: true
            }
        case LandingPageActionConstants.updateAPIStatus:
            return {
                ...state,
                createTransactionAPIStatus: API_SUCCESS
            }
        case LandingPageActionConstants.setNextTransactionId:
            return {
                ...state,
                transactionId: action.data,
                isNewTransaction: false
            }
        case LandingPageActionConstants.setPreviousTransaction:
            return {
                ...state,
                transactionId: action.data,
                isNewTransaction: false
            }
        case LandingPageActionConstants.getTransactionSearchResultsReset:
            return {
                ...state,
                transactionSearchResultsStatus: API_INITIAL,
                transactionSearchResults: []
            }
        case LandingPageActionConstants.getTransactionSearchResultsRequest:
            return {
                ...state,
                transactionSearchResultsStatus: API_FETCHING
            }
        case LandingPageActionConstants.getTransactionSearchResultsSuccess:
            return {
                ...state,
                transactionSearchResults: action.data,
                transactionSearchResultsStatus: API_SUCCESS
            }
        case LandingPageActionConstants.HEADER_ASSIGN_TRANSACTION:
            return {
                ...state,
                searchSelectedTransactionId: action.data,
                searchSelectedFacilityId: action.facilityId,
                searchSelectedTransactionIdMemory: action.data,
                transactionId: action.data,
                selectedHealthOrganizationDetails: action.healthorg,
                selectedFacilityDetails: action.facility
            }
        case LandingPageActionConstants.CLEAR_SEARCH_SELECTED_TRANSACTION_ID:
            return {
                ...state,
                searchSelectedTransactionId: null,
                searchSelectedFacilityId: null
            }
        case LandingPageActionConstants.HEADER_ASSIGN_TRANSACTION_INFORMATION:
            return {
                ...state,
                assignAPIInfo: action.data
            }
        case LandingPageActionConstants.HEADER_ASSIGN_TRANSACTION_INFORMATION_CLEAR:
            return {
                ...state,
                assignAPIInfo: null
            }
        case LandingPageActionConstants.HEADER_UNASSIGN_TRANSACTION:
            return {
                ...state,
                isTransactionIdAssigned: action.data,
                transactionId: null
            }
        case LandingPageActionConstants.getInitialsSuccess:
            return {
                ...state,
                userPersonalInfo: action.data
            }
        case LandingPageActionConstants.getInitialsRequest:
            return {
                ...state,
                userPersonalInfoAPIStatus: API_FETCHING
            }
        case LandingPageActionConstants.getInitialsFailure:
            return {
                ...state,
                userPersonalInfoAPIStatus: API_FAILURE
            }
        case LandingPageActionConstants.GET_NEXT_TRANSACTION_REQUEST:
            return{
                ...state,
                transactionId: action.data
            }
        case LandingPageActionConstants.getPreviousTransactionAPISuccess:
            return{
                ...state,
                transactionId: action.data.id
            }
        case LandingPageActionConstants.SHOW_READONLY_TRANSACTION_RESPONSE:
            return{
                ...state,
                readOnlyTransactionDetails: action.data
            }
        case LandingPageActionConstants.getReadOnlyUserTasksRequest:
            return {
                ...state,
                getUserTasksStatus: API_FETCHING
            }
        case LandingPageActionConstants.getReadOnlyUserTasksSuccess:
            return {
                ...state,
                getUserTasksStatus: API_SUCCESS,
                userTasks: action.data,
                userTasksComments: action.comments
            }
        case LandingPageActionConstants.getReadOnlyUserTasksFailure:
            return {
                ...state,
                getUserTasksStatus: API_FAILURE,
            }

        case LandingPageActionConstants.updateReadOnlyUserTaskComments:
            return {
                ...state,
                userTaskComments: updateUserTaskCommentsHelperFunction({ ...state.userTasksComments }, action.data)
            }
        case LandingPageActionConstants.updateReadOnlyUserTasksListRequest:
            return {
                ...state,
                userTasks: updateUserTaskDetailsHelperFunction([...state.userTasks], action.data.index, action.data.updatedData, action.data.isNewTask)
            }
        case LandingPageActionConstants.deleteReadOnlyTransactionTaskResponse:
            let tempState = { ...state }
            let tempTaskArray: any = tempState.userTasks;
            let newTaskArray: any = [];
            for (let i = 0; i < tempTaskArray.length; i++) {
                if (tempTaskArray[i].id !== action.data) {
                    newTaskArray.push(tempTaskArray[i]);
                }
            }
            return {
                ...state,
                userTasks: newTaskArray
            }
        case LandingPageActionConstants.getReadOnlyTransactionNoteTypesSuccess:
            return {
                ...state,
                transactionNoteTypes: generatePickerOptionsNew(action.data, "name", "id")
            }
        case LandingPageActionConstants.getReadOnlyTransactionNotesRequest:
            return {
                ...state,
                getTransactionNotesAPIStatus: API_FETCHING
            }
        case LandingPageActionConstants.getReadOnlyTransactionNotesSuccess:
            return {
                ...state,
                getTransactionNotesAPIStatus: API_SUCCESS,
                transactionNotes: action.data
            }
        case LandingPageActionConstants.getReadOnlyTransactionNotesFailure:
            return {
                ...state,
                getTransactionNotesAPIStatus: API_FAILURE
            }
        case LandingPageActionConstants.changeRequestContext:
            reduxScope.requestContext = action.data
            return {
                ...state,
                requestContext: action.data
            }
        case LandingPageActionConstants.resetRequestContext:
            reduxScope.requestContext = 1
            return{
                ...state,
                requestContext: 1
            }
        case LandingPageActionConstants.getAuditTrailRequest:
            return {
                ...state,
                getAuditTrailStatus: API_FETCHING
            }
        case LandingPageActionConstants.getAuditTrailResponse:
            return {
                ...state,
                auditTrail: action.data,
                getAuditTrailStatus: API_INITIAL
            }
        case LandingPageActionConstants.getSystemBulletinMessagesResponse:
            return {
                ...state,
                systemBulletinMessages : action.data
            }
        case LandingPageActionConstants.userAcknowledgesBulletinResponse:
            return {
                ...state,
                systemBulletinMessages : []
            }
        case LandingPageActionConstants.uploadDocumentRequest:
            return {
                ...state,
                readOnlyTransactionDetails: {
                    ...state.readOnlyTransactionDetails,
                    attributes: {
                        ...state.readOnlyTransactionDetails.attributes,
                        uploadDocumentAPIStatus: API_FETCHING,
                        uploadDocumentFailure: null,
                        uploadDocumentSuccess: null
                    }
                }
            }
        case LandingPageActionConstants.uploadDocumentSuccess:
            return {
                ...state,
                readOnlyTransactionDetails: {
                    ...state.readOnlyTransactionDetails,
                    attributes: {
                        ...state.readOnlyTransactionDetails.attributes,
                        uploadDocumentAPIStatus: API_SUCCESS,
                        uploadDocumentFailure: null,
                        uploadDocumentSuccess: action.data
                    }
                }
            }
        case LandingPageActionConstants.uploadDocumentFailure:
            return {
                ...state,
                readOnlyTransactionDetails: {
                    ...state.readOnlyTransactionDetails,
                    attributes: {
                        ...state.readOnlyTransactionDetails.attributes,
                        uploadDocumentAPIStatus: API_FAILURE,
                        uploadDocumentFailure: action.data,
                        uploadDocumentSuccess: null
                    }
                }
            }
        case LandingPageActionConstants.uploadDocumentInitial:
            return {
                ...state,
                readOnlyTransactionDetails: {
                    ...state.readOnlyTransactionDetails,
                    attributes: {
                        ...state.readOnlyTransactionDetails.attributes,
                        uploadDocumentAPIStatus: API_INITIAL,
                        uploadDocumentFailure: null,
                        uploadDocumentSuccess: null
                    }
                }
            }
        case LandingPageActionConstants.getTransactionDocumentsRequest:
            return {
                ...state,
                readOnlyTransactionDetails: {
                    ...state.readOnlyTransactionDetails,
                    attributes: {
                        ...state.readOnlyTransactionDetails.attributes,
                        getTransactionDocumentsAPIStatus: API_FETCHING
                    }
                }
            }
        case LandingPageActionConstants.getTransactionDocumentsSuccess:
            return {
                ...state,
                readOnlyTransactionDetails:{
                    ...state.readOnlyTransactionDetails,
                    attributes: {
                        ...state.readOnlyTransactionDetails.attributes,
                        copyTransactionComplete: false,
                        getTransactionDocumentsAPIStatus: API_SUCCESS,
                        deleteDocumentAPIStatus: API_INITIAL
                    },
                    documentList: action.data.map(item => {
                        const fileSize = item.file?.size?.bytes
                        return {
                            ...item,
                            size: isNaN(fileSize) ? fileSize : (Number(fileSize) * 0.00000095367432).toFixed(2)
                        }
                    })
                }
            }
        case LandingPageActionConstants.getTransactionDocumentsFailure:
            return {
                ...state,
                readOnlyTransactionDetails: {
                    ...state.readOnlyTransactionDetails,
                    attributes: {
                        ...state.readOnlyTransactionDetails.attributes,
                        getTransactionDocumentsAPIStatus: API_FAILURE
                    }
                }
            }
        case LandingPageActionConstants.postWPSRequest:
            return {
                ...state,
                readOnlyTransactionDetails: {
                    ...state.readOnlyTransactionDetails,
                    attributes: {
                        ...state.readOnlyTransactionDetails.attributes,
                        transactionId: action.data.transactionId,
                        docType: action.data.docType,
                        postWPSStatus: API_FETCHING
                    }
                }
            }
        case LandingPageActionConstants.postWPSResponse:
            return {
                ...state,
                readOnlyTransactionDetails: {
                    ...state.readOnlyTransactionDetails,
                    attributes: {
                        ...state.readOnlyTransactionDetails.attributes,
                        wpsToken: action.data,
                        postWPSStatus: API_INITIAL
                    }
                }
            }
        case LandingPageActionConstants.getFacilitySpotlightFiltersResponse:
            return {
                ...state,
                facilitySpotlightFilters: action.data
            }
        case LandingPageActionConstants.getFacilitySpotlightDetailsResponse:
            return {
                ...state,
                facilitySpotlightDetails: action.data
            }

        case LandingPageActionConstants.resendTransactionRequestStatus:{
            return{
                ...state,
                resendTransactionRequestStatus: action.data
            }
        }
        case LandingPageActionConstants.getRejectOptionsSuccess:
            return {
                ...state,
                rejectOptions: generatePickerOptionsNew(action.data, "name", "description", "id", true)
            }
        case LandingPageActionConstants.getLogoUrlRequest:
            return{
                ...state,
                logoUrlStatusCode: API_FETCHING
            }
        case LandingPageActionConstants.getLogoUrlSuccess:
            return{
                ...state,
                logoUrlStatusCode: API_SUCCESS,
                logoUrl: action.data
            }
        case LandingPageActionConstants.getLogoUrlFailure:
            return{
                ...state,
                logoUrlStatusCode: API_FAILURE
            }
        case LandingPageActionConstants.getDefinedTemplateNamesSuccess:
            return{
                ...state,
                definedOutboundTemplates: action.data

            }
        case LandingPageActionConstants.getOutboundTemplateFieldsRequest:
            return{
                ...state,
                outboundTemplateFieldsResponseStatusCode: API_FETCHING
            }
        case LandingPageActionConstants.getOutboundTemplateFieldsSuccess:
            return{
                ...state,
                outboundTemplateFieldsResponseStatusCode: API_SUCCESS,
                outboundTemplateFields: action.data
            }
        case LandingPageActionConstants.getOutboundTemplateFieldsFailure:
            return{
                ...state,
                outboundTemplateFieldsResponseStatusCode: API_FAILURE
            }
        case LandingPageActionConstants.changeDelayReason:
            let index = state.outboundTemplateFields.findIndex(x => x['pdfName'] === "delayReason")
            state.outboundTemplateFields[index] ={
                ...state.outboundTemplateFields[index],
                userValue: action.data
            } 
            return { 
                ...state
            }
        case LandingPageActionConstants.changeOutboundFieldValue:{
            let index = state.outboundTemplateFields.findIndex(x => x['pdfName'] === action.data.name)
            state.outboundTemplateFields[index] ={
                ...state.outboundTemplateFields[index],
                userValue: action.data.value
            } 
            return { 
                ...state
            }
        }
        case LandingPageActionConstants.sendOutboundTemplateFieldsRequest:
            return{
                ...state,
                sendOutboundTemplateFieldsResponseStatusCode: API_INITIAL
            }
        case LandingPageActionConstants.sendOutboundTemplateFieldsSuccess:
            return{
                ...state,
                sendOutboundTemplateFieldsResponseStatusCode: API_SUCCESS,
                outboundTemplateFields: [{}]
            }
        case LandingPageActionConstants.sendOutboundTemplateFieldsFailure:
            return{
                ...state,
                sendOutboundTemplateFieldsResponseStatusCode: API_FAILURE
            }
        case LandingPageActionConstants.getTrackingLinkRequest:
            return {
                ...state,
                getTrackingLinkAPIStatus: API_FETCHING
            }
        case LandingPageActionConstants.getTrackingLinkSuccess:
            return {
                ...state,
                trackingLink: action.data,
                getTrackingLinkAPIStatus: API_SUCCESS
            }
        case LandingPageActionConstants.getTrackingLinkFailure:
            return {
                ...state,
                getTrackingLinkAPIStatus: API_FAILURE
            }
        default:
            return state
    }
}


// create action creators here
export const clearTransactionId = () => {
    return {
        type: LandingPageActionConstants.CLEAR_SELECTED_TRANSACTION_ID

    }
}

export const getTransactionSearchResultsReset = () => {
    return {
        type: LandingPageActionConstants.getTransactionSearchResultsReset,
        data: null
    }
}

export const getTransactionSearchResultsRequest = (data) => {
    return {
        type: LandingPageActionConstants.getTransactionSearchResultsRequest,
        data
    }
}
export const updateSelectedFacilityDetails = (data: any) => {
    return {
        type: LandingPageActionConstants.updateSelectedFacilityDetails,
        data
    }
}
export const createTransaction = (data: any) => {
    return {
        type: LandingPageActionConstants.createTransactionRequest,
        data
    }
}

//export const getHealthOrganizationsListRequest = () => {
//    return {
//        type: LandingPageActionConstants.getHealthOrganizationsListRequest
//    }
//}

export const getHealthOrganizationsSearchRequest = (offset: number, limit: number, searchTerm: string, returnEmpty: boolean) => {
    return {
        type: LandingPageActionConstants.getHealthOrganizationsSearchRequest,
        data: {
            offset, limit, searchTerm, returnEmpty
        }
    }
}
export const getInitialsSuccess = (userData) => {
    return {
        type: LandingPageActionConstants.getInitialsSuccess,
        data: userData
    }
}

export const getInitialsFailure = () => {
    return {
        type: LandingPageActionConstants.getInitialsFailure,
    }
}

export const getInitialsRequest = () => {
    return {
        type: LandingPageActionConstants.getInitialsRequest,
    }
}

export const onClickHealthOrganization = (healthOrgDetails: any) => {
    return {
        type: LandingPageActionConstants.onClickHealthOrganization,
        data: healthOrgDetails
    }
}

export const setSelectedFacilityDetails = (facilityDetails: any) => {

    return {
        type: LandingPageActionConstants.setSelectedFacilityDetails,
        data: facilityDetails
    }
}

export const setPreviousTransactionActionCreator = (previousTransactionId: string) => {
    return {
        type: LandingPageActionConstants.setPreviousTransaction,
        data: previousTransactionId
    }
}

export const resetSelectedFacilityDetails = () => {
    return {
        type: LandingPageActionConstants.resetSelectedFacilityDetails
    }
}

export const resetSelectedHealthOrganizatonDetails = () => {
    return {
        type: LandingPageActionConstants.resetSelectedHealthOrganizatonDetails
    }
}

export const setNextTransactionIdActionCreator = (nextTransactionId: string) => {
    return {
        type: LandingPageActionConstants.setNextTransactionId,
        data: nextTransactionId
    }
}

export const getTransactionIdSuccess = (transactionId: any) => {
    return {
        type: LandingPageActionConstants.getTransactionIdSuccess,
        data: transactionId
    }
}

export const headerAssignTransactionActionHeader = (transactionId: any, facilityId: any) => {
    return {
        type: LandingPageActionConstants.HEADER_ASSIGN_TRANSACTION_SAGA,
        data: transactionId,
        facilityId
    }
}
export const clearSelectedSearchTransaction = () => {
    return {
        type: LandingPageActionConstants.CLEAR_SEARCH_SELECTED_TRANSACTION_ID
    }
}

export const assignTransactionInformationActioncreator = () => {
    return {
        type: LandingPageActionConstants.HEADER_ASSIGN_TRANSACTION_INFORMATION
    }
}

export const clearTransactionInformationActioncreator = () => {
    return {
        type: LandingPageActionConstants.HEADER_ASSIGN_TRANSACTION_INFORMATION_CLEAR
    }
}


export const headerUnassignTransactionIDActionCreator = (transactionId: string) => {
    return {
        type: LandingPageActionConstants.HEADER_UNASSIGN_TRANSACTION_SAGA,
        data: transactionId
    }
}

export const clearUserLocksActionRequest = () => {
    return {
        type: LandingPageActionConstants.clearUserLocksRequest
    }
}

export const showReadOnlyTransactionRequest = (transactionId: string) => {
    return {
        type: LandingPageActionConstants.SHOW_READONLY_TRANSACTION_REQUEST,
        data: transactionId
    }
}

export const clearReadOnlyTransactionRequest = () => {
    return {
        type: LandingPageActionConstants.SHOW_READONLY_TRANSACTION_RESPONSE,
        data: null
    }
}

export const getReadOnlyUserTasksActionCreator = (data) => {
    return {
        type: LandingPageActionConstants.getReadOnlyUserTasksRequest,
        data
    }
}


export const updateReadOnlyUserTasksActionCreator = (data: any) => {
    return {
        type: LandingPageActionConstants.updateReadOnlyUserTasksListRequest,
        data
    }
}

export const updateReadOnlyUserTaskCommentsActionCreator = (data: any) => {
    return {
        type: LandingPageActionConstants.updateReadOnlyUserTaskComments,
        data
    }
}

export const createReadOnlyUserTaskCommentActionCreator = (data: any) => {
    return {
        type: LandingPageActionConstants.createReadOnlyUserTaskComment,
        data
    }
}

export const addNewReadOnlyTaskActionCreator = (data: any) => {
    return {
        type: LandingPageActionConstants.addNewReadOnlyUserTaskRequest,
        data
    }
}

export const updateReadOnlyTaskActionCreator = (data: any) => {
    return {
        type: LandingPageActionConstants.updateReadOnlyUserTaskRequest,
        data
    }
}

export const deleteReadOnlyTransactionTaskActionCreator = (transactionId: string, usertaskId: string) => {
    return {
        type: LandingPageActionConstants.deleteReadOnlyTransactionTaskRequest,
        data: { transactionId, usertaskId }
    }
}

export const getTransactionNoteTypes = (facilityId: string) => {
    return {
        type: LandingPageActionConstants.getReadOnlyTransactionNoteTypesRequest,
        data: facilityId
    }
}

export const updateReadOnlyTransactionNotes = (data: any) => {
    return {
        type: LandingPageActionConstants.updateReadOnlyTransactionNotes,
        data
    }
}

export const updateRequestContext = (data: any) => {
    return{
        type: LandingPageActionConstants.changeRequestContext,
        data
    }
}

export const resetRequestContext = () => {
    return{
        type: LandingPageActionConstants.resetRequestContext
    }
}

export const getAuditTrail = (transactionId: string) => {
    return {
        type: LandingPageActionConstants.getAuditTrailRequest,
        data: { transactionId }
    }
}

export const getSystemBulletinRequestActionCreator = (data: any) => {
    return {
        type: LandingPageActionConstants.getSystemBulletinMessagesRequest,
        data: data
    }
}

export const userAcknowledgeBulletinRequestActionCreator = (data: any) =>{
    return {
        type: LandingPageActionConstants.userAcknowledgesBulletinRequest,
        data: data
    }
}

export const uploadReadOnlyMultipleDocumentsActionCreator = (data: any, onSuccess?: Function) => {
    return {
        type: LandingPageActionConstants.uploadMultipleDocumentsRequest,
        data,
        onSuccess
    }
}

export const getReadOnlyTransactionDocuments = (transactionId: string) => {
    return {
        type: LandingPageActionConstants.getTransactionDocumentsRequest,
        data: transactionId
    }
}

export const postReadOnlyWPSToken = (transactionId: string, docType: string) => {
    return {
        type: LandingPageActionConstants.postWPSRequest,
        data: { transactionId, docType }
    }
}

export const getFacilitySpotlightFliters = (facilityId: string) => {
    return {
        type: LandingPageActionConstants.getFacilitySpotlightFiltersRequest,
        data: facilityId
    }
}
export const getFacilitySpotlightDetails = (data: any) => {
    return {
        type: LandingPageActionConstants.getFacilitySpotlightDetailsRequest,
        data: data
    }
}
export const putResendTransaction = (transactionId: string, contactId: number) => {
    return {
        type: LandingPageActionConstants.resendTransactionRequest,
        data: { transactionId, contactId }
    }
}
export const getRejectOptions = (data: string) => {
    return {
        type: LandingPageActionConstants.getRejectOptionsRequest,
        data
    }
}
export const getLogoUrlActionCreator = (facilityId: any) => {
    return{
        type: LandingPageActionConstants.getLogoUrlRequest,
        data: facilityId
    }
}
// out bound template action creators
export const getOutboundTemplateRequest = () => {
    return {
        type: LandingPageActionConstants.getDefinedTemplateNamesRequest
    }
}

export const getOutboundTemplateFieldsRequest = (templateId: string, transactionId: string) => {
    return {
        type: LandingPageActionConstants.getOutboundTemplateFieldsRequest,
        data: {
            templateId: templateId,
            transactionId: transactionId
        }
    }
}

export const changeDelayReasonTemplate = (data: any) => {
    return {
        type: LandingPageActionConstants.changeDelayReason,
        data: data
    }
}

export const sendOutboundTemplateFieldsActionCreator = (templateId: any, transactionId: any, action: any, templateFields: any, facilityId: any, context: any, contact:any) => {
    return{
        type: LandingPageActionConstants.sendOutboundTemplateFieldsRequest,
        data:{
            templateId: templateId,
            transactionId: transactionId,
            action: action,
            fields: templateFields,
            facilityId: facilityId,
            context: context,
            contact: contact
        }
    }
}

export const changeOutBoundFieldValue = (fieldName: any, value: any) =>{
    return {
        type: LandingPageActionConstants.changeOutboundFieldValue,
        data: {
            name: fieldName,
            value: value
        }
    }
}

export function getTrackingLinkRequest() {
    return {
        type: LandingPageActionConstants.getTrackingLinkRequest
    }
}

function ToOrgDetailsModal(item:any){
    const facilityCounts = item.facilities
        .map(fac => ({
            facility: fac as FacitiesModal,
            model: fac.model,
            uri: fac.uri,
            transactionCounts: {
                dataEntry: fac.transactionCounts.dataEntry,
                recordAttachment: fac.transactionCounts.recordAttachment,
                recordValidation: fac.transactionCounts.recordValidation,
                userTasks: (fac.userTaskCounts?.dataEntry ?? 0) + (fac.userTaskCounts?.recordAttachment ?? 0) + (fac.userTaskCounts?.recordValidation ?? 0),
            } as HealthSystemCountsModal
        } as FacilitiesCountsModal)) as FacilitiesCountsModal[];

    return {
        facilityCounts: facilityCounts,
        healthSystem: {
            facilities: facilityCounts,
            id: item.id,
            model: item.model,
            name: item.name,
            uri: item.uri
        } as HealthSystemModal,
        model: item.model,
        transactionCounts: {
            dataEntry: facilityCounts.reduce((acc, cur) => acc + (cur.transactionCounts.dataEntry ?? 0), 0),
            recordAttachment: facilityCounts.reduce((acc, cur) => acc + (cur.transactionCounts.recordAttachment ?? 0), 0),
            recordValidation: facilityCounts.reduce((acc, cur) => acc + (cur.transactionCounts.recordValidation ?? 0), 0),
            userTasks: facilityCounts.reduce((acc, cur) => acc + (cur.transactionCounts.userTasks ?? 0), 0),
        } as HealthSystemCountsModal
    } as OrganizationDetailsModal
}

// create sagas here
// GET ORGANIZATIONS LIST
export function* getHealthOrganizationsSearch(action: any) {
    try {
        const result = yield call(getHealthOrganizationSearchAPI, { offset: action.data.offset, limit: action.data.limit, searchTerm: action.data.searchTerm, returnEmpty: action.data.returnEmpty = true })
        if (isSuccessStatusCode(result.status)) {
            if (result.data.length) {
                const healthOrgArray: Array<OrganizationDetailsModal> =
                    result.data.filter((item: any) => item.facilities.length)
                        .map((item: any) => ToOrgDetailsModal(item))

                yield put({ type: LandingPageActionConstants.getHealthOrganizationsSearchSuccess, data: healthOrgArray })

            } else if (action.data.returnEmpty) {
                yield put({ type: LandingPageActionConstants.getHealthOrganizationsSearchSuccess, data: [] })
            }
        }
    } catch (err) {
        yield put({ type: LandingPageActionConstants.getHealthOrganizationsSearchFailure })
        yield put({ type: LandingPageActionConstants.getHltOrgTransactionsCountFailure })

    }
}

export function* generateOrGetTransactionId(action: any) {
    try {
        const result = yield call(generateOrGetTransactionIdAPI, {...action, requestContext: reduxScope.requestContext})
        if (isSuccessStatusCode(result.status) && result.data.id) {
            if(result.data.isReadOnly){
                yield showReadOnlyTransactionSaga({ data: result.data.id });
            }else{
                yield put(push("/data"));
                yield put({ type: LandingPageActionConstants.getTransactionIdSuccess, data: result.data.id });
            }
        }
        else {
            yield put({ type: LandingPageActionConstants.HEADER_ASSIGN_TRANSACTION_INFORMATION, data: 'No next transaction available.' })
            yield call(clearUserLocksSaga, {data: global.userID}) 
            yield put(push("/home"))
        }
    } catch (err) {
        yield put({ type: LandingPageActionConstants.getTransactionIdFailure })
    }
}

export function* createTransactionSaga(action: any) {
    try {
        const result = yield call(createNewTransactionAPI, action)

        if (isSuccessStatusCode(result.status)) {
            yield put({ type: LandingPageActionConstants.createTransactionSuccess, data: result.data })
            yield put({ type: LandingPageActionConstants.getTransactionIdSuccess, data: result.data.uri.split("/")[2] })
            yield put({ type: LandingPageActionConstants.updateAPIStatus })
            yield put(push("/data"))
        }
    } catch (err) {
    }
}


export function* getTransactionSearchResultsSaga(action: any) {
    try {
        const response = yield call(getTransactionSearchResultsAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: LandingPageActionConstants.getTransactionSearchResultsSuccess, data: response.data })
        }else{
            yield put({ type: LandingPageActionConstants.getTransactionSearchResultsSuccess, data: [] })
        }
    } catch (err) {
        yield put({ type: LandingPageActionConstants.getTransactionSearchResultsSuccess, data: [] })
     }
}

export function* getInitialsSaga(action: any) {
    try {
        const response = yield call(getInitials, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: LandingPageActionConstants.getInitialsSuccess, data: response.data })
        }
    } catch (err) { }
}

export function* headerAssignTransactionIDSaga(action: any) {
    try {
        const response = yield call(assignTransactionIdAPI, action)
        if (isSuccessStatusCode(response.status) && !response.data.code) {
            const healthOrg = yield call(getHealthSystemByFacilityAPI, { data: { facilityId: action.facilityId }})
            const OrgDetailsModal: OrganizationDetailsModal = ToOrgDetailsModal(healthOrg.data);
            const facility = OrgDetailsModal.facilityCounts.find(f => f.facility.id === action.facilityId);
            yield put({ type: LandingPageActionConstants.HEADER_ASSIGN_TRANSACTION, data: action.data, facilityId: action.facilityId, healthorg: OrgDetailsModal, facility: facility })
        }
        else {
            yield put(push("/home"))
            yield put({ type: LandingPageActionConstants.HEADER_ASSIGN_TRANSACTION_INFORMATION, data: response.data.message })
        }
    } catch (err) {
        yield put({ type: LandingPageActionConstants.getTransactionSearchResultsSuccess, data: [] })
    }
}

export function* headerUnssignTransactionIDSaga(action: any) {
    try {
        const response = yield call(unAssignTransactionAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: LandingPageActionConstants.HEADER_UNASSIGN_TRANSACTION, data: false })
        }
    } catch (err) {
    }
}

export function* clearUserLocksSaga(action: any) {
    try {
        const response = yield call(clearUserLocksAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: LandingPageActionConstants.clearUserLocksSuccess, data: response.data })
        }
    } catch (err) {
    }
}


export function* showReadOnlyTransactionSaga(action: any){
    try {
        const transactionIdAttributesRes = yield call(getTransactionAttributesAPI, action)

        if (isSuccessStatusCode(transactionIdAttributesRes.status)) {
            yield put({ type: LandingPageActionConstants.getReadOnlyUserTasksRequest, data: action.data })
            yield put({ type: LandingPageActionConstants.getReadOnlyTransactionNotesRequest, data: action.data })
            yield put({ type: LandingPageActionConstants.getReadOnlyTransactionNoteTypesRequest, data: transactionIdAttributesRes.data.facility.id })

            const requestorDetails =
                transactionIdAttributesRes.data?.sendToRequestor && transactionIdAttributesRes.data?.sendToContact ?
                yield call(getRequestorDetailsAPI, {
                    requestorId: transactionIdAttributesRes.data.sendToRequestor?.id,
                    requestorContactId: transactionIdAttributesRes.data.sendToContact?.id
                }) :
                null
            
            const patientDetails = 
                transactionIdAttributesRes.data?.patient ? 
                yield call(get, Api.getTransactionPatientAttributes + transactionIdAttributesRes.data?.patient?.id + "?userID=" + global.userID + "&transactionid=" + action.data) :
                null

            const billingDetails = 
                transactionIdAttributesRes.data?.billToContact ? 
                yield call(getRequestorBillingDetailsAPI, {
                    requestorBillingId: transactionIdAttributesRes.data.billToContact?.id
                }) :
                null

            const documents = yield call(getTransactionDocumentsAPI, action)

            const transactionDetails = {
                transactionId: action.data,
                attributes: transactionIdAttributesRes.data,
                requestorDetails: requestorDetails ? requestorDetails.data : {},
                billingDetails: billingDetails ? billingDetails.data : {},
                patientDetails: patientDetails ? patientDetails.data : {},
                documentList: documents.data
            }

            yield put({ type: LandingPageActionConstants.SHOW_READONLY_TRANSACTION_RESPONSE, data: transactionDetails })
        }
    } catch (err) {
        yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: (err as any).response })
    } 
}

export function* getReadOnlyUserTasksSaga(action: any) {
    try {
        const response = yield call(getUserTasksAPI, action)
        if (isSuccessStatusCode(response.status) && Array.isArray(response.data)) {
            let normalizedUserTaskComments = {}
            let taskIdsList: any[] = []
            const responseAPI = yield all(response.data.map(item => {
                taskIdsList.push(item.id)
                return call(getUserTaskCommentsAPI, { data: item.id })
            }))
            taskIdsList.map((id, index) => {
                const sortedComments = responseAPI ? sortDateObject(responseAPI[index].data, "createdDate").reverse() : []
                normalizedUserTaskComments = {
                    ...normalizedUserTaskComments,
                    [id]: sortedComments
                }
                return null
            })
            let totalTasks = getSortedTasks(response.data)
            yield put({ type: LandingPageActionConstants.getReadOnlyUserTasksSuccess, data: totalTasks, comments: normalizedUserTaskComments })
        } else {
            yield put({ type: LandingPageActionConstants.getReadOnlyUserTasksFailure })
        }
    } catch (err) {
        yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: (err as any).response })
    } 
}

export function* updateReadOnlyUserTaskSaga(action: any) {
    try {
        yield call(updateUserTaskAPI, action)
        yield put({ type: LandingPageActionConstants.getReadOnlyUserTasksRequest, data: action.data.transactionId })
    } catch (err) {
        yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* addNewReadOnlyUserTaskSaga(action: any) {
    try {
        yield call(adNewUserTaskAPI, action)
        yield put({ type: LandingPageActionConstants.getReadOnlyUserTasksRequest, data: action.data.transactionId })
    } catch (err) {
        yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* createReadOnlyUserTaskCommentSagas(action: any) {
    try {
        yield call(createUserTaskCommentAPI, action)
    } catch (err) {
        yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* deleteReadOnlyTransactionTaskSaga(action: any) {
    try {
        const response = yield call(deleteTransactionTaskAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: LandingPageActionConstants.deleteReadOnlyTransactionTaskResponse, data: action.data.usertaskId })
        }
    } catch (err) {
        yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: (err as any).message })
    }
}

export function* getReadOnlyTransactionNoteTypesSaga(action: any) {
    try {
        const response = yield call(getTransactionNoteTypesAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: LandingPageActionConstants.getReadOnlyTransactionNoteTypesSuccess, data: response.data })
        }
    } catch (err) {
        yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* getReadOnlyTransactionNotes(action: any) {
    try {
        const response = yield call(getTransactionNotesAPI, action)
        if (isSuccessStatusCode(response.status) && Array.isArray(response.data)) {
            const sortedResults = sortDateObject(response.data, "created").reverse()
            yield put({ type: LandingPageActionConstants.getReadOnlyTransactionNotesSuccess, data: sortedResults })
        } else {
            yield put({ type: LandingPageActionConstants.getReadOnlyTransactionNotesFailure })
        }
    } catch (err) {
        yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* updateReadOnlyTransactionNotesSaga(action: any) {
    try {
        yield call(updateTransactionNotesAPI, action)
        yield call(getReadOnlyTransactionNotes, { data: action.data.transactionId })
    } catch (err) {
        yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* getLPAuditTrailSaga(action: any) {
    try {
        const response = yield call(getAuditTrailAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: LandingPageActionConstants.getAuditTrailResponse, data: response.data })
        } else {
            yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: response.data })
        }
    } catch (err) {
        yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* getSystemBulletinMessagesSaga(action: any) {
    try {
        const response = yield call(getSystemBulletinMessage, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: LandingPageActionConstants.getSystemBulletinMessagesResponse, data: response.data })
        } else {
            yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: response.data })
        }
    } catch (err) {
        yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* userAcknowledgeBulletinSaga(action: any) {
    try {
        const response = yield call(userAcknowledgeBulletin, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: LandingPageActionConstants.userAcknowledgesBulletinResponse })
        } else {
            yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: response.data })
        }
    } catch (err) {
        yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* readOnlyUploadMultipleDocumentsSaga(action: any) {
    try {
        const response = yield call(uploadDocumentAPI, action)
        if (isSuccessStatusCode(response.status)) {
            if (response.data.verified) {
                yield put({
                    type: LandingPageActionConstants.uploadDocumentSuccess, data: {
                        filename: action.data.fileName,
                        fileID: action.data.fileID,
                        url: response.data.url,
                        error: null,
                        arePageizesValid: response.data.arePagesLetterSize,
                        invalidPagesNumbers: response.data.invalidPagesNumbers

                    },
                    timestamp: new Date()
                })
            } else {
                throw new Error("Verification failed. Please manually refresh.")
            }
        }
    } catch (err) {
        yield put({
            type: LandingPageActionConstants.uploadDocumentFailure, data: {
                filename: action.data.fileName,
                fileID: action.data.fileID,
                error: (err as any).message
            },
            timestamp: new Date()
        })
        yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: (err as any).response })
        yield put({ type: LandingPageActionConstants.uploadDocumentInitial })
    }
}

export function* getReadOnlyTransactionDocumentsSaga(action: any) {
    try {
        const response = yield call(getTransactionDocumentsAPI, action)
        if (isSuccessStatusCode(response.status) && Array.isArray(response.data)) {
            yield put({ type: LandingPageActionConstants.getTransactionDocumentsSuccess, data: response.data })
        } else {
            yield put({ type: LandingPageActionConstants.getTransactionDocumentsFailure })
        }
    } catch (err) {
        yield put({ type: LandingPageActionConstants.getTransactionDocumentsFailure })
        yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* postReadOnlyWPSTokenSaga(action: any) {
    try {
        const response = yield call(postWebPrintScanAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: LandingPageActionConstants.postWPSResponse, data: response.data })
        } else {
            yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: response.status })
        }
    } catch (err) {
        yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* getFacilitySpotlightFiltersSaga(action: any) {
    try {
        const response = yield call(getFacilitySpotlightFiltersAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: LandingPageActionConstants.getFacilitySpotlightFiltersResponse, data: response.data })
        } else {
            yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: response.status })
        }
    } catch (err) {
        yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* getFacilitySpotlightDetailsSaga(action: any) {
    try {
        const response = yield call(getFacilitySpotlightDetailsAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: LandingPageActionConstants.getFacilitySpotlightDetailsResponse, data: response.data })
        } else {
            yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: response.status })
        }
    } catch (err) {
        yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* resendTransactionSaga(action: any) {
    try {
        yield put({ type: LandingPageActionConstants.resendTransactionRequestStatus, data: API_FETCHING})
        const response = yield call(putResendTransactionAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield showReadOnlyTransactionSaga({ data: action.data.transactionId });
            yield put({ type: LandingPageActionConstants.resendTransactionRequestStatus, data: API_SUCCESS })
        }else{
            yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: response.status })
            yield put({ type: LandingPageActionConstants.resendTransactionRequestStatus, data: API_FAILURE })
        }
    } catch (err) {
        yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: (err as any).response })
        yield put({ type: LandingPageActionConstants.resendTransactionRequestStatus, data: API_FAILURE })
    } finally {
        yield put({ type: LandingPageActionConstants.resendTransactionRequestStatus, data: API_INITIAL })
    }
}

export function* getRejectOptionsSaga(action: any) {
    try {
        const response = yield call(getRejectOptionsAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: LandingPageActionConstants.getRejectOptionsSuccess, data: response.data })
        }
    } catch (err) {
        yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: (err as any).response })
    }

}

export function* getLogoUrlSaga(action: any){
    try {
        const response = yield call(getLogoUrlAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: LandingPageActionConstants.getLogoUrlSuccess, data: response.data})
        } else{
            yield put({ type: LandingPageActionConstants.getLogoUrlFailure})
        }
    } catch (err) {
        yield put({ type: LandingPageActionConstants.getLogoUrlFailure})
        yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: (err as any).response})
    }
}

// outbound template sagas

export function* getDefinedOutboundTemplateNamesSaga() {
    try {
        const response = yield call(getDefinedTemplateName)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: LandingPageActionConstants.getDefinedTemplateNamesSuccess, data: response.data })
        } 
    } catch (err) {
        yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: (err as any).response})
    }
}

export function* getOutboundTemplateFieldsSaga(action: any){
    try {
        const response = yield call(getOutboundTemplateFields, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: LandingPageActionConstants.getOutboundTemplateFieldsSuccess, data: response.data })
        } else{
            yield put({ type: LandingPageActionConstants.getOutboundTemplateFieldsFailure})
        }
    } catch (err) {
        yield put({ type: LandingPageActionConstants.getOutboundTemplateFieldsFailure})
        yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: (err as any).response})
    }
}

export function* sendOutboundTemplateFieldsSaga(action: any){
    try {
        const response = yield call(SendOutboundTemplateFields, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: LandingPageActionConstants.sendOutboundTemplateFieldsSuccess})
        } else{
            yield put({ type: LandingPageActionConstants.sendOutboundTemplateFieldsFailure})
        }
    } catch (err) {
        yield put({ type: LandingPageActionConstants.sendOutboundTemplateFieldsFailure})
        yield put({ type: LandingPageActionConstants.API_ERROR_STATE, data: (err as any).response})
    }
}

export function* getTrackingLinkSaga() {
    try {
        const response = yield call(getTrackingLinkAPI, {})
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: LandingPageActionConstants.getTrackingLinkSuccess, data: response.data })
        }
    } catch (err) {
        yield put({ type: LandingPageActionConstants.getTrackingLinkFailure, data: err })
    }
}