import React, {useEffect} from 'react';
import DateField from '../formFields/DateField'
import { mirrorData } from '../utils/appUtils';

/**
 * to be wrapped with redux-form Field component
 */
export default function ReduxFormValidationError(props) {

    // const { disabled, input, meta, label, onChangeRTRDateRange, placeholder, maxDate = null, minDate = null, required, errorMessage, setErrMsgPresent, onChange, onBlur } = props

    // const customOnBlur = () => {
    //     mirrorData(input.name, input.value)
    //     onBlur && onBlur(input.value, input.name)
    // }

    // // do not display warning if the field has not been touched or if it's currently being edited
    // let error = errorMessage || ""
    // if (meta.touched) {
    //     if (meta.valid) {
    //     } else {
    //         error = meta.error
    //     }
    // }

    // if (error && setErrMsgPresent) {
    //     setErrMsgPresent(true)
    // }

    return (
        (props.errorMessage ?? "").trim() != ""
            ? <div className="contact-error"><span className="error-message">{props.errorMessage}</span></div>
            : null
    );
}