import React, { useState, useEffect } from 'react';
import { Field } from 'redux-form';
import ReduxFormTextField from "../reduxFormFields/ReduxFormTextField";
import "../../sass/main.scss";
import './FormField.scss';
import { LightTooltip } from '../utils/appUtils';
import crossIcon from "../../assets/images/x_cancel_icon.png";
import checkIcon from '../../assets/images/check_green_icon.png';

interface MultilineTextboxButtonsProps{
    text?: string,
    label?: string,
    maxLength?: number,
    className?: string,
    onSave: Function,
    saveRollover: string,
    cancelRollover: string
}

function MultilineTextboxButtons(props: MultilineTextboxButtonsProps){
    const [text, setText] = useState("");
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        setText(props.text || "")
    },[props.text])

    const onCancel = () => {
        setText(props.text || "")
        setDisabled(true);
    }

    const onChange = (e) => {
        setText(e)
        if(disabled)
            setDisabled(false);
    }

    const onSave = () => {
        props.onSave(text);
        setDisabled(true);
    }

    return (
        <>
            <div>
                <Field 
                    component={ReduxFormTextField}
                    input={{
                        value: text,
                        onChange: onChange}}
                    props={{
                        label: props.label,
                        multiline: true,
                        maxLength: props.maxLength,
                        className: props.className
                    }}
                />
            </div>
            <div className="text-right">
                <LightTooltip title={props.cancelRollover} placement="right">
                    <span onClick={onCancel} onKeyDown={k => { k.key === 'Enter' && onCancel() }} tabIndex={disabled ? undefined : 0} style={disabled ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <img src={crossIcon} alt={props.cancelRollover} className="pointer-cursor" />
                    </span>
                </LightTooltip>

                <LightTooltip title={props.saveRollover} placement="right" aria-disabled={true}>
                    <span onClick={onSave} onKeyDown={k => { k.key === 'Enter' && onSave() }} tabIndex={disabled ? undefined : 0} style={disabled ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <img src={checkIcon} alt={props.saveRollover} className="pointer-cursor" />
                    </span>
                </LightTooltip>
            </div>
        </>
    )

}

export default MultilineTextboxButtons;