import { call, put } from 'redux-saga/effects'
import { isSuccessStatusCode } from "../../common/constants/ApiConstants"
import { getBuildNumberAPI } from "../../components/dataEntryDetails/test/DataEntry.APIs"
//Constants
export const HeaderActionConstants = {
    onChangeDarkMode: "onChangeDarkMode/Header",
    builNumberSuccess: "buildNumver/Success",
    buildNumberFailure: "buildNumber/Failure",
    buildNumberRequest: "buildNumber/Request"
}


// create reducers here
const initialState = {
    isDarkModeEnabled : false,
    theme: 'light',
    buildNumber:'100000.1'
}

export const headerState = (state = initialState, action: any) => {
    switch(action.type){
        case HeaderActionConstants.onChangeDarkMode:
            let theme = ''
            if(action.data){
                document.body.classList.add('dark-theme');
                theme = 'dark'               
            }else{
                document.body.classList.remove('dark-theme'); 
                theme = 'light'              
            }
            return {
                ...state,
                isDarkModeEnabled: action.data,
                theme
            }
        case HeaderActionConstants.builNumberSuccess:
                return {
                    ...state,
                   buildNumber:action.buildNumber
                }
        case HeaderActionConstants.buildNumberFailure:
                    return {
                        ...state
                    }
        case HeaderActionConstants.buildNumberRequest:
                    return {
                    ...state
                    }
        default:
            return state
    }
}


/*********************************create action creators here**********************************/
// on changing the dark mode
export const onChangeDarkMode = (data: boolean) => {
    return {
        type: HeaderActionConstants.onChangeDarkMode,
        data
    }
}
export function buildNumberSuccessAction(action){
    return {
        type: HeaderActionConstants.builNumberSuccess,
        buildNumber: action.buildNumber
    }
}
export function buildNumberFailure(){
    return {
        type: HeaderActionConstants.buildNumberFailure
    }
}
export function buildNumberRequest(){
    return {
        type: HeaderActionConstants.buildNumberRequest
    }
}


// create sagas here
export function * getBuildNumber(action: any){
    try {
    const response = yield call(getBuildNumberAPI)
      if(isSuccessStatusCode(response.status)){
    
        yield put({type: HeaderActionConstants.builNumberSuccess,
            buildNumber: response.data})
      }
      else{
       yield put({type: HeaderActionConstants.buildNumberFailure})
      }
    } catch (err) {
        yield put({type: HeaderActionConstants.buildNumberFailure})
    }
}