import _validations from 'lodash'
import moment from "moment"
import FormConstants, { BillingFormconstants, RequestorFormConstants } from '../../components/dataEntryDetails/dataEntryComponents/DataEntryFieldsConstants'
import { DataEntryActionConstants } from '../../components/dataEntryDetails/DataEntryDetailsDucks'
import { store } from '../../redux/index'
import { DEFAULT_DROPDOWN_NAME, DEFAULT_DROPDOWN_VALUE, API_FETCHING, API_INITIAL} from '../constants/ApiConstants'
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { escape } from 'querystring'
import { useEffect, useRef, useState } from 'react'
export const _ = _validations
const REQUIRED = 'Please enter valid '

export const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: '#474849',
        border: '1px solid #BFD8E8',
        borderRadius: '0.3em',
        fontSize: 12,
        margin: 2
    },
}))(Tooltip);

export function getMomentDate(date: any, dateFormat: string = "MM/DD/YYYY") {
    if (date) {
        return moment(date).format(dateFormat);
    }
    return "";
}

export function isAPIInitial(...args) {
    const status = false

    return Array.from(args).reduce(
        (returnStatus, item) => returnStatus || parseInt(item, 10) === API_INITIAL,
        status
    )
}

export function isAPIFetching(...args) {
    const status = false

    return Array.from(args).reduce(
        (returnStatus, item) => returnStatus || parseInt(item, 10) === API_FETCHING,
        status
    )
}
export const setGlobalId = async () => {
    if (!global.userID) {
        const id = await sessionStorage.getItem("userID")
        global.userID = id ? Number(id) : 0
        return global.userID
    } else {
        return global.userID
    }
}

export const normalizeData = (data: any, key: any) => {
    let normalizedData = {}

    data && data.length && data.map((item: any) => {
        let objectKey = item[key];

        return normalizedData = {
            ...normalizedData,
            [objectKey]: item
        }
    });
    return normalizedData;
}
export const getArrayFromNormalizedObject = (data: any) => {
    let updatedData: any[] = []
    data && Object.keys(data).map((key) => {
        updatedData.push(data[key])
        return null
    })
    updatedData = updatedData.sort((a, b) => {
        let nameA = a.text.toUpperCase();
        let nameB = b.text.toUpperCase();
        if (nameA > nameB) { return -1 }
        if (nameA < nameB) { return 1 }
        return 0;
    });
    return updatedData
}

export const isValidEmail = (email: string) => {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(email)) {
        if (email.indexOf("@verisma.com", email.length - "@verisma.com".length) !== -1) {
            const name = email.replace("@verisma.com", "")
            if (name.length > 64) { return false; }
            return true
        }
    }
    return false
}


export const isValid = (value: any) => {
    if (!_.isNil(value) && value && value.toString().length > 0 && value.toString() !== "Invalid Date") {
        return { display_error: false }
    } else {
        return {
            error_msg: REQUIRED,
            display_error: true
        }
    }
}
export const isPHISelected = (value: any) => {
    if (Object.entries(value).length > 0) {
        return {}
    } else {
        return {
            error_msg: REQUIRED,
            display_error: true
        }
    }
}

export const isRecordsTypeSelected = (value: any) => {
    if (Object.entries(value).length > 0) {
        return {}
    } else {
        return {
            error_msg: REQUIRED,
            display_error: true
        }
    }
}

export const isValidCharacters = (value: any, chars: string) => {
    let re = new RegExp("^[" + chars.replace('\\', '\\\\').replace('-', '\\-').replace(']', '\\]').replace('^', '\\^') + "]*$")
    if (re.test(value)) {
        return { display_error: false }
    } else {
        return {
            error_msg: REQUIRED,
            display_error: true
        }
    }
}

interface InormalizedType {
    [key: string]: any;
}

export const pickerHelperFunction = (dataArray: any, keyName: string, keyValue: string) => {
    let pickerOptions: InormalizedType = []
    dataArray && dataArray.map(item => {
        pickerOptions.push({ value: item[keyValue], label: item[keyName] })
        return null
    })
    return pickerOptions
}

export const pickerHelperFeeScheduleFunction = (dataArray: any, keyName: string, keyValue: string, billingTypeID: string) => {
    let pickerOptions: InormalizedType = []
    dataArray && dataArray.map(item => {
        pickerOptions.push({ value: item[keyValue], label: item[keyName], billingType: item[billingTypeID] })
        return null
    })
    return pickerOptions
}

export const generatePurposeOfRequestOptions = (data: any) => {
    if (!data) { return [] }
    let pickerOptions: InormalizedType = []
    pickerOptions.push({ value: DEFAULT_DROPDOWN_VALUE, label: DEFAULT_DROPDOWN_NAME })
    Object.keys(data).map((key) => {
        let options = pickerHelperFunction(data[key], "name", "id")
        pickerOptions = pickerOptions.concat(options)
        return null
    })
    return pickerOptions
}
export const generatePickerOptions = (data: any, keyName: string, keyValue: string, keyId = "999999") => {
    if (!data) { return [] }
    let pickerOptions: InormalizedType = []
    pickerOptions.push({ value: DEFAULT_DROPDOWN_VALUE, label: DEFAULT_DROPDOWN_NAME })
    Object.keys(data).map((key) => {
        if (keyId !== "999999") {
            pickerOptions.push({ value: data[key][keyValue], label: data[key][keyName], rid: data[key][keyId] })
        } else
            pickerOptions.push({ value: data[key][keyValue], label: data[key][keyName] })
        return null
    })
    return pickerOptions
}
export const generatePickerOptionsNoDefault = (data: any, keyName: string, keyValue: string) => {
    if (!data) { return [] }
    let pickerOptions: InormalizedType = []
    Object.keys(data).map((key) => {
        pickerOptions.push({ value: data[key][keyValue], label: data[key][keyName] })
        return null
    })
    return pickerOptions
}
export const normalizeCharacters = (value: any, name: string) => {
    if (!value) {
        return value
    }
    let matches = ""
    if (name === RequestorFormConstants.CITY) {
        matches = value.match(/[a-zA-Z\s]+/g);
    }
    else if (name === RequestorFormConstants.LAST_NAME) {
        matches = value.match(/[a-zA-Z, ]+/g);
    } else
        matches = value.match(/[a-zA-Z]+/g);

    if (matches && matches.length) {
        return matches[0]
    }
    return ""
}

export const normalizePhone = (value: any) => {
    if (!value) {
        return value;
    }

    const onlyNums = value.replace(/[^\d]/g, "");

    if (onlyNums.length <= 3) {
        return onlyNums;
    }else if (onlyNums.length <= 6) {
        return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
    }else if (onlyNums.length <= 10) {
        return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6)}`;
    }
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}x${onlyNums.slice(10)}`;
};


export const numericAmount = (value: any) => {
    if (!value) {
        return value;
    }
    const onlyNums = value.replace(/[^\d.]/g, "");
    return `${onlyNums}`;
};


export function getTransactionNotesDataFromObject(data: any, key: string) {
    switch (key) {
        case "date":
            return moment(data?.created).format("MM/DD/YYYY")
        case "type":
            return data?.type?.name
        case "notes":
            return data?.text
        default:
            return ""
    }
}

export function mirrorData(fieldName: string, value: any) {
    switch (fieldName) {
        case FormConstants.PATIENT_NAME:
            return store.dispatch<any>({ type: DataEntryActionConstants.updatePatientDetails, data: { firstName: value } })
        case FormConstants.PATIENT_MIDDLE_NAME:
            return store.dispatch<any>({ type: DataEntryActionConstants.updatePatientDetails, data: { middleName: value } })
        case FormConstants.PATIENT_LAST_NAME:
            return store.dispatch<any>({ type: DataEntryActionConstants.updatePatientDetails, data: { lastName: value } })
        case FormConstants.DOB:
            return store.dispatch<any>({ type: DataEntryActionConstants.updatePatientDetails, data: { dob: value } })
        case FormConstants.MRN_ID:
            return store.dispatch({ type: DataEntryActionConstants.updateTransactionDetails, data: { mrn: value } })
        case FormConstants.EMPI_NUMBER:
            return store.dispatch({ type: DataEntryActionConstants.updatePatientDetails, data: { empi: value } })
        case RequestorFormConstants.REQUESTOR_NAME:
            return store.dispatch({ type: DataEntryActionConstants.updateRequestorDetails, data: { fullName: value } })
        case "requestorNotes":
            return store.dispatch({ type: DataEntryActionConstants.updateRequestorDetails, data: { "notes": value } })
        case RequestorFormConstants.CITY:
            return store.dispatch({ type: DataEntryActionConstants.updateRequestorDetails, data: { city: value } })
        case RequestorFormConstants.STATE:
            return store.dispatch({ type: DataEntryActionConstants.updateRequestorDetails, data: { state: value } })
        case RequestorFormConstants.ZIP:
            return store.dispatch({ type: DataEntryActionConstants.updateRequestorDetails, data: { zipCode: value } })
        case RequestorFormConstants.FIRST_NAME:
            return store.dispatch({ type: DataEntryActionConstants.updateRequestorDetails, data: { firstName: value } })
        case RequestorFormConstants.LAST_NAME:
            return store.dispatch({ type: DataEntryActionConstants.updateRequestorDetails, data: { lastName: value } })
        case RequestorFormConstants.EMAIL:
            return store.dispatch({ type: DataEntryActionConstants.updateRequestorDetails, data: { email: value } })
        case RequestorFormConstants.PHONE_NUMBER:
            return store.dispatch({ type: DataEntryActionConstants.updateRequestorDetails, data: { phone: value } })
        case RequestorFormConstants.FAX_NUMBER:
            return store.dispatch({ type: DataEntryActionConstants.updateRequestorDetails, data: { fax: value } })
        case RequestorFormConstants.ADDRESS:
            return store.dispatch({ type: DataEntryActionConstants.updateRequestorDetails, data: { address1: value } })
        case RequestorFormConstants.ADDRESS2:
            return store.dispatch({ type: DataEntryActionConstants.updateRequestorDetails, data: { address2: value } })
        case FormConstants.AUTHORIZATION_DATE:
            return store.dispatch({ type: DataEntryActionConstants.updateTransactionDetails, data: { authorization: value } })
        case FormConstants.REQUEST_DATE:
            return store.dispatch({ type: DataEntryActionConstants.updateTransactionDetails, data: { request: value } })
        case FormConstants.RECEIVED_DATE:
            return store.dispatch({ type: DataEntryActionConstants.updateTransactionDetails, data: { received: value } })
        case FormConstants.DUE_DATE:
            return store.dispatch({ type: DataEntryActionConstants.updateTransactionDetails, data: { due: value } })
        case "notes":
            return store.dispatch<any>({ type: DataEntryActionConstants.updatePatientDetails, data: { notes: value } })
        default:
            return null
    }
}

export const generateSendToRequestorObject = (data: any) => {
    let requestObject = {}
    if (data) {
        requestObject = {
            requestorname: data[RequestorFormConstants.REQUESTOR_NAME],
            address1: data[RequestorFormConstants.ADDRESS],
            city: data[RequestorFormConstants.CITY],
            state: data[RequestorFormConstants.STATE],
            zipcode: data[RequestorFormConstants.ZIP],
            requestType: data[FormConstants.REQUEST_TYPE],
            country: "United States",
            prepay: false,
            isverified: false
        }
        if (data[RequestorFormConstants.ADDRESS2]) {
            requestObject['address2'] = data[RequestorFormConstants.ADDRESS2]
        }
        if (data[RequestorFormConstants.EMAIL]) {
            requestObject['email'] = data[RequestorFormConstants.EMAIL]
        }
        if (data[RequestorFormConstants.FAX_NUMBER]) {
            requestObject['fax'] = data[RequestorFormConstants.FAX_NUMBER]
        }

        requestObject['userID'] = global.userID

    }
    return requestObject
}



export const generateSendToContactObject = (data: any) => {
    let requestObject = {}
    if (data) {
        requestObject = {
            isverified: false,
            userID: global.userID
        }
        if (data[RequestorFormConstants.EMAIL]) {
            requestObject['email'] = data[RequestorFormConstants.EMAIL]
        }
        if (data[RequestorFormConstants.FAX_NUMBER]) {
            requestObject['fax'] = data[RequestorFormConstants.FAX_NUMBER]
        }

        if(data[RequestorFormConstants.FIRST_NAME]){
            requestObject['firstname'] = data[RequestorFormConstants.FIRST_NAME]
        }

        if(data[RequestorFormConstants.LAST_NAME]){
            requestObject['lastname'] = data[RequestorFormConstants.LAST_NAME]
        }

        if(data[RequestorFormConstants.PHONE_NUMBER]){
            requestObject['phone'] = data[RequestorFormConstants.PHONE_NUMBER]
        }

    }
    return requestObject
}

export const generateUpdateBillToRequestorObject = (data: any) => {
    let requestObject = {}
    if (data) {
        requestObject = {
            requestorbillinglocation: data[BillingFormconstants.BILL_TO_REQUESTOR_NAME],
            firstname: data[BillingFormconstants.BILL_TO_FIRST_NAME],
            lastname: data[BillingFormconstants.BILL_TO_LAST_NAME],
            address1: data[BillingFormconstants.BILL_TO_ADDRESS_LINE1],
            city: data[BillingFormconstants.BILL_TO_CITY],
            state: data[BillingFormconstants.BILL_TO_STATE],
            zipcode: data[BillingFormconstants.BILL_TO_ZIP],
            country: "United States",
            prepay: data[BillingFormconstants.REQUIRES_PREPAYMENT],
            email: data[BillingFormconstants.BILL_TO_EMAIL_ADDRESS],
            phone: data[BillingFormconstants.BILL_TO_PHONE],
            fax: data[BillingFormconstants.BILL_TO_FAX],
            billingtypeid: 1,
            userID: global.userID,
            requestorNameId: data.requestorNameId
        }
        if (data[BillingFormconstants.BILL_TO_ADDRESS_LINE2]) {
            requestObject['address2'] = data[BillingFormconstants.BILL_TO_ADDRESS_LINE2]
        }
        if(data[BillingFormconstants.REQUIRES_PREPAYMENT] != null){
            requestObject['prepay'] = data[BillingFormconstants.REQUIRES_PREPAYMENT]
        }
       
    }
    return requestObject
}

export const generateUpdateDisclosureInformationObject = (data: any) => {
    let requestObject = {}
    if (data) {
        requestObject = {
            disclosureDate: data[FormConstants.DISCLOSURE_DATE] ? moment(data[FormConstants.DISCLOSURE_DATE]).format("MM/DD/YYYY") : null,
            disclosureReasonID: data[FormConstants.DISCLOSURE_REASON],
            protocolNumber: data[FormConstants.PROTOCOL_NUMBER] ? data[FormConstants.PROTOCOL_NUMBER] : '0',
        }
        if (data[FormConstants.SPECIAL_NOTES]) {
            requestObject['specialNote'] = data[FormConstants.SPECIAL_NOTES]
        }
        if (data[FormConstants.PAGES_DISCLOSED]) {
            requestObject['numberOfPages'] = data[FormConstants.PAGES_DISCLOSED]
        }
        if (data[FormConstants.PROTOCOL_NAME]) {
            requestObject['protocolName'] = data[FormConstants.PROTOCOL_NAME]
        }
        requestObject['userID'] = global.userID
    }
    return requestObject
}

export const generateUpdateSendToRequestorObject = (data: any) => {
    let requestObject = {}
    if (data) {
        requestObject = {
            requestorname: data[RequestorFormConstants.REQUESTOR_NAME],
            firstname: data[RequestorFormConstants.FIRST_NAME],
            lastname: data[RequestorFormConstants.LAST_NAME],
            address1: data[RequestorFormConstants.ADDRESS],
            address2: data[RequestorFormConstants.ADDRESS2],
            city: data[RequestorFormConstants.CITY],
            state: data[RequestorFormConstants.STATE],
            zipcode: data[RequestorFormConstants.ZIP],
            country: "United States",
            prepay: false,
            email: data[RequestorFormConstants.EMAIL],
            phone: data[RequestorFormConstants.PHONE_NUMBER],
            fax: data[RequestorFormConstants.FAX_NUMBER],
            isverified: false,
            userID: global.userID
        }
    }

    return requestObject
}

export const generateSendToRequestorContactObject = (data: any, transactionid: string = '') => {
    let requestObject = {}
    if (data) {
        requestObject = {
            emailconfirmed: false,
            requestType: data[FormConstants.REQUEST_TYPE],
            userID: global.userID,
            transactionId: transactionid
        }

        if(data[RequestorFormConstants.FIRST_NAME]){
            requestObject['firstname'] = data[RequestorFormConstants.FIRST_NAME]
        }

        if(data[RequestorFormConstants.LAST_NAME]){
            requestObject['lastname'] = data[RequestorFormConstants.LAST_NAME]
        }

        if(data[RequestorFormConstants.EMAIL]){
            requestObject['email'] = data[RequestorFormConstants.EMAIL]
        }

        if(data[RequestorFormConstants.PHONE_NUMBER]){
            requestObject['phone'] = data[RequestorFormConstants.PHONE_NUMBER]
        }

        if(data[RequestorFormConstants.FAX_NUMBER]){
            requestObject['fax'] = data[RequestorFormConstants.FAX_NUMBER]
        }
    }
    return requestObject
}

export function generateSearchResponseObject(data: any) {
    let responseObject: any = [];
    let requestorName, zip, city, state, firstName, lastName, phone, fax, email, requestorId = '', eTransfer, national, requestorContactId, comments, prePay;
    let requestorAddress = {}
    data.map((requestor) => {
        comments = requestor.comments;
        eTransfer = requestor.eTransfer;
        national = requestor.national;
        requestorName = requestor.fullName ? requestor.fullName : '';
        requestorAddress = {
            address1: requestor.requestorAddress.address1 ? requestor.requestorAddress.address1 : '',
            address2: requestor.requestorAddress.address2 ? requestor.requestorAddress.address2 : '',
            city: requestor.requestorAddress.city ? requestor.requestorAddress.city : '',
            state: requestor.requestorAddress.state ? requestor.requestorAddress.state : '',
            zip: requestor.requestorAddress.zipCode ? requestor.requestorAddress.zipCode : ''

        }
        requestorId = requestor.requestorID || "";
        prePay = requestor.prePay;
        requestor.contacts.length === 0 ? responseObject.push({ requestorId, requestorName, zip, city, state, prePay })
            : requestor.contacts.map((el: any) => {
                firstName = el.firstName ? el.firstName : '';
                lastName = el.lastName ? el.lastName : '';
                phone = el.phone ? el.phone : '';
                fax = el.fax ? el.fax : '';
                email = el.email ? el.email : '';
                requestorContactId = el.id;
                responseObject.push({ requestorName, requestorContactId, requestorAddress, firstName, lastName, phone, fax, email, requestorId, national, eTransfer, comments, prePay });
                return null;
            })
        return null
    });

    return responseObject

}

export function generateContactSearchReponseObject(data: any) {
    let firstName, lastName, phone, fax, email = '';
    let id = 0;
    let contactObject: any = [];
    data.map((el) => {
        id = el.id ? el.id : 0;
        firstName = el.firstName ? el.firstName : '';
        lastName = el.lastName ? el.lastName : '';
        phone = el.phone ? el.phone : '';
        fax = el.fax ? el.fax : '';
        email = el.email ? el.email : '';
        contactObject.push({  id, firstName, lastName, phone, fax, email });
        return null;
    });
    return contactObject
}

export function generateRejectLetterResponseObject(data: any) {
    let address1, address2, city, state, zipcode, organizationWebsite, phone, fax, fullname, requestorFirstname, requestorLastname,
        requestorAddress1, requestorAddress2, requestorCity, requestorState, requestorZipCode,
        patientFirstName, patientLastName, patientMiddleName, caseNumber, medicalProviderName, logoUrl,
        invoiceNumber = '', requestorEmail, requestorFax, verismaName;
    let rejectLetterObject: any = {};
    address1 = data.organizationReturn?.organizationAddress.address1;
    address2 = data.organizationReturn?.organizationAddress.address2;
    city = data.organizationReturn?.organizationAddress.city;
    state = data.organizationReturn?.organizationAddress.state;
    zipcode = data.organizationReturn?.organizationAddress.zipCode;
    organizationWebsite = data.organizationReturn?.organizationWebsite;
    phone = data.organizationReturn?.contactInfo.phone;
    fax = data.organizationReturn?.contactInfo.fax;
    fullname = data.requestor?.fullName;
    requestorFirstname = data.requestor?.requestorInfo.firstName;
    requestorLastname = data.requestor?.requestorInfo.lastName;
    requestorAddress1 = data.requestor?.requestorAddress.address1;
    requestorAddress2 = data.requestor?.requestorAddress.address2;
    requestorCity = data.requestor?.requestorAddress.city;
    requestorState = data.requestor?.requestorAddress.state;
    requestorZipCode = data.requestor?.requestorAddress.zipCode;
    patientFirstName = data.patient?.contact.firstName;
    patientLastName = data.patient?.contact.lastName;
    patientMiddleName = data.patient?.contact.middleName;
    caseNumber = data.caseNumber;
    medicalProviderName = data.medicalProvider?.organizationName;
    invoiceNumber = data.invoiceNumber;
    logoUrl = data.logoUrl;
    requestorEmail = data.requestor?.requestorInfo.email;
    requestorFax = data.requestor?.requestorInfo.fax;
    verismaName = data.verismaName;
    rejectLetterObject = {
        address1, address2, city, state, zipcode,
        phone, fax, organizationWebsite, fullname, requestorFirstname, requestorLastname,
        requestorAddress1, requestorAddress2, requestorCity, requestorState, requestorZipCode,
        patientFirstName, patientLastName, patientMiddleName, caseNumber, medicalProviderName, invoiceNumber, logoUrl, requestorEmail,
        requestorFax, verismaName
    }
    return rejectLetterObject
}

export const isPastDate = (date: any) => {
    if (date) {
        const now = moment();
        const numberOfDays = now.diff(date, "days");
        return numberOfDays > 0
    }
    return false
}

export const setFormValuesInSessionStorate = (values: any) => {
    try {
        sessionStorage.setItem("formValues", JSON.stringify(values))
    } catch (err) {
        sessionStorage.removeItem("formValues")
        sessionStorage.setItem("formValues", JSON.stringify(values))
    }
}

export const areValuesIdentical = (val1, val2) => {
    const valuesIdentical = (v1, v2) => {
        if (v1 === 'object') {
            if (!objectsIdentical(v1, v2))
                return false;
        } else if (v1 instanceof Array) {
            if (v1.length != v2.length)
                return false;

            v1.forEach((v, i) => {
                if (!valuesIdentical(v, v2[i]))
                    return false;
            })
        } else if (v1 !== v2) {
            return false;
        }
    }

    const objectsIdentical = (o1, o2) => {
        const o1Keys = Object.keys(o1);
        const o2Keys = Object.keys(o2);

        if (o1Keys.length != o2Keys.length)
            return false;

        let keysIdentical = true;

        o1Keys.every((c, i) => {
            if (c !== o2Keys[i] || typeof o1[c] !== o2[c] || !valuesIdentical(o1[c], o2[c])){
                keysIdentical = false;
            }
            return keysIdentical;
        })

        return keysIdentical;
    }

    return valuesIdentical(val1, val2);
}

// export const useStateCallback = <T>(initialState: T): [state: T, setState: (updatedState: React.SetStateAction<T>, callback?: (updatedState: T) => void) => void] => {
//     const [state, setState] = useState<T>(initialState);
//     const callbackRef = useRef<(updated: T) => void>();

//     const handleSetState = (updatedState: React.SetStateAction<T>, callback?: (updatedState: T) => void) => {
//         callbackRef.current = callback;
//         setState(updatedState);
//     };

//     useEffect(() => {
//         if (typeof callbackRef.current === "function") {
//             callbackRef.current(state);
//             callbackRef.current = undefined;
//         }
//     }, [state]);

//     return [state, handleSetState];
// }

export const compareDDSFields = (newFields, oldFields) => {
    // turns nulls and undefined into empty str
    Object.keys(newFields).forEach(x => !newFields[x]? newFields[x] = '':'') 

    if(oldFields.docReturnCode && newFields.docReturnCode !== oldFields.docReturnCode){
        return false;
    }

    if(oldFields.docType && newFields.docType !== oldFields.docType){
        return false;
    }

    if(oldFields.routingField && newFields.routingField !== oldFields.routingField){
        return false;
    }

    if(oldFields.rqSiteCode && newFields.rqSiteCode !== oldFields.rqSiteCode){
        return false;
    }

    if(oldFields.rqid && newFields.rqid !== oldFields.rqid){
        return false;
    }

    if(oldFields.ssn && newFields.ssn !== oldFields.ssn){
        return false;
    }

    if(oldFields.txSiteCode && newFields.txSiteCode !== oldFields.txSiteCode){
        return false;
    }

    return true;

}
