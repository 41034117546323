import { SubmissionError } from 'redux-form'

import localValidations from './localValidations'


const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

function submit(values, fields, props) {
    return sleep(0).then(() => {
        const editFormLocalValidations = localValidations(values, props)
        if (editFormLocalValidations && Object.keys(editFormLocalValidations).length > 0) {
            throw new SubmissionError(editFormLocalValidations)
        } else {
        }
    })
}
export default submit