import React from 'react';
import { Card, Figure } from 'react-bootstrap';
import RecordAttachmentLogo from '../../assets/images/tabImages/RecordAttachmentSVG.svg';

interface RecordAttachmentProps {
  recordAttachment: number
}

function recordAttachment(props: RecordAttachmentProps) {
  return (
     <Card className="cardBorderStyle">
      <Card.Body className='border-none'>
        <Figure className='mb-0'>
          <Figure.Image
            src={RecordAttachmentLogo}
            alt='RecordValidationLogo'
            className='mr-2 float-left'
          />
          <Figure.Caption className='figure-caption-custom mt-1'>
            Record Attachment
          </Figure.Caption>
        </Figure>
        <span className='card-data'>
          <span className="totalRecordCount">{props.recordAttachment}</span>
        </span>
      </Card.Body>
    </Card>
  );
}
export default recordAttachment;
