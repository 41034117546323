import React, { useEffect, useRef, useState } from "react";
import { change, Field, InjectedFormProps, reduxForm, untouch, stopSubmit } from 'redux-form';
import { connect } from "react-redux";
import FormConstants from "../../components/dataEntryDetails/dataEntryComponents/DataEntryFieldsConstants";
import { getContactSearchDetailsActionCreator } from "../../components/dataEntryDetails/DataEntryDetailsDucks";
import { putResendTransaction } from "../../components/landingPage/LandingPageDucks";
import CloseIcon from '@material-ui/icons/Close';
import "./ResendPopup.scss";
import { Button, Dropdown } from "react-bootstrap";
import { API_FAILURE, API_FETCHING, API_SUCCESS, DEFAULT_DROPDOWN_NAME, DEFAULT_DROPDOWN_VALUE, } from "../constants/ApiConstants";
import { pickerHelperFunction } from "../../common/utils/appUtils";
import DropdownField from "../formFields/DropdownField";

interface IpropType {
    transactionId: string,
    requestorId: number,
    selectedContactId: number,
    requestorList?: object,
    getContactSearchDetails: Function,
    resendTransaction: Function;
    contactSearchDetails?: any[],
    getContactSearchDetailsAPIStatus: any;
    onClose?: Function;
    resendTransactionRequestStatus: number;
}

function ResendPopup(props: IpropType) {
    const [filteredContacts, setFilteredContacts] = useState<any[]>([]);
    const [selectedContactId, setSelectedContactId] = useState<number>(0);
    const [selectedContact, setSelectedContact] = useState<any>(null);
    const [state, setState] = useState("init");

    useEffect(() => {
        props.requestorId && props.getContactSearchDetails(props.requestorId, true)
    }, [ props.requestorId ])

    useEffect(() => {
        setSelectedContactId(props.selectedContactId);
        if (props.contactSearchDetails)
            setSelectedContact(props.contactSearchDetails?.find(c => c.id === props.selectedContactId));
    }, [props.selectedContactId, props.contactSearchDetails])

    useEffect(() => {
        if (props.contactSearchDetails && props.contactSearchDetails.length){
            const filtered = props.contactSearchDetails?.filter(contact => (contact.email && contact.email.indexOf("@") > -1 && (contact.firstName || contact.lastName)));
            filtered.sort((a,b) => {
                if (a.id === props.selectedContactId || b.id === props.selectedContactId){
                    return a.id === props.selectedContactId ? -1 : 1;
                }else if(a.lastName === b.lastName){
                    return a.firstName.localeCompare(b.firstName);
                }else{
                    return a.lastName.localeCompare(b.lastName)
                }
            });
            const options = filtered.map(d => ({ 
                value: d.id, 
                label: d.firstName + (d.firstName && d.lastName && " ") + d.lastName + " - " + d.email
            }))
            setFilteredContacts(options || [])
        }else
            setFilteredContacts([])
    }, [selectedContact, props.contactSearchDetails])

    useEffect(() => { 
        if (props.resendTransactionRequestStatus === API_SUCCESS)
            setState("success")
        else if (props.resendTransactionRequestStatus === API_FAILURE)
            setState("failure");
        else if (props.resendTransactionRequestStatus === API_FETCHING)
            setState("saving")
    }, [props.resendTransactionRequestStatus])

    const onSelectContact = (ev) => {
        const id = parseInt(ev.target.value);
        const selected = props.contactSearchDetails?.find(c => c.id === id);
        setSelectedContactId(id);
        setSelectedContact(id === -9999 ? null : selected);
        setState("init");
    }

    function send(){
        if (selectedContactId !== -9999 && selectedContact)
            props.resendTransaction(props.transactionId, selectedContact?.id || -1)
    }

    function close(){
        props.onClose && props.onClose();
    }

    return <div className="resendPopupBackground">
        <div className="resendPopup">
            <div className="resendPopupHeader">
                <b>Resend Transaction</b>
                <a className="resendPopupClose" onClick={close}><CloseIcon style={{ fontSize: "24px" }} /></a>
            </div>
            <div className="resendPopupEmail">Email: {selectedContact?.email || "No Email Assigned"}</div>
            <div className="resendPopupSelect">
                <select disabled={state !== "init"} onChange={onSelectContact} value={selectedContact?.id}>
                    {!filteredContacts.some(c => c.value === props.selectedContactId) && <option value={-9999}>- Select a contact -</option>}
                    {filteredContacts.map(c => 
                        <option value={ c.value }>{ c.label }</option>
                    )}
                </select>
            </div> 
            <div className="resendPopupSendButton">
                <Button disabled={state !== "init" || selectedContactId === -9999 || !selectedContact} onClick={send}>Send</Button>
            </div>
            {state === "saving" && <div className="status">Saving contact and emailing, please wait...</div>}
            {state === "success" && <div className="status success">Email sent</div>}
            {state === "failure" && <div className="status failure">There was an error saving the new contact</div>}
        </div>
    </div>
}

function mapStateToProps(state: any, props: any) {
    return {
        contactSearchDetails: state.dataEntryState.contactSearchDetails,
        getContactSearchDetailsAPIStatus: state.dataEntryState.getContactSearchDetailsAPIStatus,
        resendTransactionRequestStatus: state.landingPageState.resendTransactionRequestStatus
    }
}

export function mapDispatchToProps(dispatch: Function) {
    const ret = {
        getContactSearchDetails: (requestorId: any, distinct: boolean | undefined) => (dispatch(getContactSearchDetailsActionCreator(requestorId, distinct))),
        resendTransaction: (transactionId: string, contactId: number) => (dispatch(putResendTransaction(transactionId, contactId)))
    }
    return ret;
}

export default connect(mapStateToProps, mapDispatchToProps)(ResendPopup);