import React from "react";
import './alertPopup.scss';
import { clearTransactionInformationActioncreator } from '../../components/landingPage/LandingPageDucks'
import {
  Modal, Row, Col
} from "react-bootstrap";
import IconWithButton from "../IconWithButton/IconWithButton";
import { connect } from "react-redux";


interface InformationPromptProps {
  show?: Function,
  assignAPIInfo?: any,
  clearTransactionInformation?: Function
}

function InformationPrompt(props: InformationPromptProps) {
  const onClickOkHnadler = () => {
    props.clearTransactionInformation && props.clearTransactionInformation()
  }

 if(props.assignAPIInfo?.length) {
  return (
    <Modal className="cancel-dimension information-prompt" show={true}>
      <div className ="alert-container">
      <Modal.Title className="cancel-header">
      <span className="info-icon"></span>
        <div className="information-header-text">Information</div>
      </Modal.Title>
      <div className="newRequest-content" />
      <h4 className="alert-text">{props.assignAPIInfo}</h4>
      <Row>
        <Col className="mb-4 button-position"> 
          <IconWithButton
                    buttonText={"OK"}
                    onButtonClick={() => onClickOkHnadler()}
                    isSave={true}
                    hideArrowIcon={true}
          />          
        </Col>
      </Row> 
      </div>     
    </Modal>
  );
 }
 else {
   return null
 }
  
}
export function mapDispatchToProps(dispatch: Function) {
  return {
    clearTransactionInformation: () => dispatch(clearTransactionInformationActioncreator()),
  };
}

export default connect(null, mapDispatchToProps)(InformationPrompt);

