import { TASK_STATUS_VALUES } from '../constants/ApiConstants'
import { DEFAULT_DROPDOWN_NAME, DEFAULT_DROPDOWN_VALUE, API_FETCHING, API_INITIAL} from '../constants/ApiConstants'

export const sortDateObject = (data: any, sortType = "dueDate") => {
    return data.sort(function (a, b) {
        let firstDate: any = new Date(a[sortType])
        let secondDate: any = new Date(b[sortType])
        return firstDate - secondDate
    });
}

export const getSortedTasks = (tasksList: any) => {
    let newTasks = tasksList.filter((data) => data.taskStatusID === TASK_STATUS_VALUES.New)
    const sortedNewTasks = sortDateObject(newTasks)
    let inProgressTasks = tasksList.filter((data) => data.taskStatusID === TASK_STATUS_VALUES["In-Progress"])
    const sortedInprogress = sortDateObject(inProgressTasks)
    let completedTasks = tasksList.filter((data) => data.taskStatusID === TASK_STATUS_VALUES.Completed)
    const sortedCompletedTasks = sortDateObject(completedTasks)
    let totalTasks = sortedNewTasks.concat(sortedInprogress)
    totalTasks = totalTasks.concat(sortedCompletedTasks)
    return totalTasks
}

export const updateUserTaskDetailsHelperFunction = (taskList, index, updatedData, isNewTask) => {
    let updatedList = [...taskList]
    if (isNewTask) {
        updatedList.unshift({ ...updatedData })
        return updatedList
    }
    updatedList[index] = { ...updatedData }
    let totalTasks = getSortedTasks(updatedList)
    return totalTasks
}

export const updateUserTaskCommentsHelperFunction = (taskComments, data) => {
    let updatedCommentsList = taskComments[data.taskId] || []
    updatedCommentsList.unshift(data)
    let updatedCommentsData = {
        ...taskComments,
        [data.taskId]: updatedCommentsList
    }
    return updatedCommentsData
}

interface InormalizedType {
    [key: string]: any;
}

export const generatePickerOptionsNew = (data: any, keyName: string, keyValue: string, keyId = "999999", includeDefault: boolean = false) => {
    if (!data) { return [] }
    let pickerOptions: InormalizedType = []
    if(includeDefault){
        pickerOptions.push({ value: DEFAULT_DROPDOWN_VALUE, label: DEFAULT_DROPDOWN_NAME })
    }
    Object.keys(data).map((key) => {
        if (keyId !== "999999") {
            pickerOptions.push({ value: data[key][keyValue], label: data[key][keyName], rid: data[key][keyId] })
        } else {
            pickerOptions.push({ value: data[key][keyValue], label: data[key][keyName] })
        }
        return null
    })
    return pickerOptions
}