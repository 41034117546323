import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import DataEntryDetailsLeftPane from "../../components/dataEntryDetails/dataEntryComponents/DataEntryDetailsLeftPane"
import UploadProgressBars from '../../common/uploadProgressBars/UploadProgressBars'
import { connect } from "react-redux";
import "./ReadOnlyTransaction.scss";
import { clearReadOnlyTransactionRequest,
    getReadOnlyUserTasksSaga,
    updateReadOnlyUserTasksActionCreator,
    updateReadOnlyUserTaskCommentsActionCreator,
    createReadOnlyUserTaskCommentActionCreator,
    addNewReadOnlyTaskActionCreator,
    updateReadOnlyTaskActionCreator,
    deleteReadOnlyTransactionTaskActionCreator,
    updateReadOnlyTransactionNotes,
    getAuditTrail,
    uploadReadOnlyMultipleDocumentsActionCreator,
    getReadOnlyTransactionDocuments,
    postReadOnlyWPSToken,
    getHealthOrganizationsSearchRequest,
    putResendTransaction,
    getRejectOptions,
    getOutboundTemplateRequest
    } from "../../components/landingPage/LandingPageDucks";
import CloseIcon from '@material-ui/icons/Close';
import { documentURL } from '../../services/Network';
import { BillingFormconstants, FormConstants } from '../../components/dataEntryDetails/dataEntryComponents/DataEntryFieldsConstants';
import { reduxForm } from 'redux-form';
import { getUserGroupsActionCreator } from "../../components/dataEntryDetails/DataEntryDetailsDucks";
import ButtonWithDropdown from "../buttonWithDropdown/ButtonWithDropdown";
import UploadFileModal from "../modal/UploadFileModal";
import PdfViewer from "../pdfViewer/PdfViewer";
import toast, { Toaster} from "react-hot-toast";
import ResendPopup from "../resendPopup/ResendPopup";
import { putResendTransactionAPI } from "../../components/dataEntryDetails/test/DataEntry.APIs";


interface ReadOnlyTransactionProps{
    readOnlyTransactionDetails?: any,
    readOnlyFormState?: any,
    userTasks?: any,
    userTasksComments?: any,
    clearReadOnly?: Function,
    getUserTasks?: Function,
    updateUserTasks?: Function,
    updateUserTaskComments?: Function,
    createUserTaskComment?: Function,
    addNewTask?: Function,
    updateTask?: Function,
    deleteTransactionTask?: Function,
    transactionNotes?: any,
    updateTransactionNotes?: Function,
    transactionNoteTypes?: any,
    getAuditTrailData?: Function,
    auditTrail?: Array<any>,
    formState?: any,
    getUserGroups?: Function,
    facilityId?: any,
    postWPSToken?: Function,
    uploadMultipleDocuments?: Function,
    wpsToken?: string,
    getTransactionDocuments?: Function,
    uploadDocumentSuccess?: any,
    uploadDocumentFailure?: any,
    featureFlags?: any,
    loadUnwaitedTx?: Function,
    getHealthOrganizationsSearch?: Function,
    getRejectOptions?: Function,
    getOutboundTemplateRequest?: Function,
}

function ReadOnlyTransaction(props: ReadOnlyTransactionProps) {

    const close = () => {
        const values = props.formState?.values || { values: {} };
        sessionStorage.setItem("formValues", JSON.stringify(values));
        props.getHealthOrganizationsSearch && props.getHealthOrganizationsSearch(null, null, null, false);
        props.clearReadOnly && props.clearReadOnly();
    }

    const [documents, setDocuments] = useState<any>({});
    const [selectedDocument, setSelectedDocument] = useState<string | undefined>("")
    const [active, setActive] = useState(false);
    const [showAttachFilesModal, setShowAttachFilesModal] = useState<any>({ state: false });
    const [showResendModal, setShowResendModal] = useState<any>({ state: false });
    const [uploadProgress, setUploadProgress] = useState<any>({});
    const [iframeKey, setIframeKey] = useState<string | null>(null);

    const [userInteractTime, setUserInteractTime] = useState<Date>(new Date());

    const fileTypes = {
        REQ: { label: "Request Document", allowAppend: true, modalType: "request document", ListLabel: "Request", AllowOnArchive: false, AllowOnComplete: false, ListOrder: 1 },
        MED: {label: 'Medical Records', allowAppend: false, modalType:"medical records", ListLabel: "Medical Records", AllowOnArchive: false, AllowOnComplete: false, ListOrder: 2 },
        AUX: { label: "Auxiliary Document", allowAppend: true, modalType: "auxiliary document", ListLabel: "Auxiliary", AllowOnArchive: false, AllowOnComplete: false, ListOrder: 3 },
        INT: { label: 'Internal File', allowAppend: false, modalType: "internal records", ListLabel: "Internal", AllowOnArchive: true, AllowOnComplete: true, ListOrder: 4 },
        NOTE: { label: "Notifications", allowAppend: false, modalType: "notification document", ListLabel: "Notification", AllowOnArchive: false, AllowOnComplete: false, ListOrder: null },
        INVOICE: { label: "Invoice Document", allowAppend: false, modalType: "invoice document", ListLabel: "Invoice", AllowOnArchive: false, AllowOnComplete: false, ListOrder: null },
        OTHER: { label: "Other", allowAppend: false, modalType: "other", ListLabel: "Other", AllowOnArchive: false, AllowOnComplete: false, ListOrder: null }
    }
    
    useEffect(() => {
        if(props?.readOnlyTransactionDetails?.attributes?.facility && props.getRejectOptions){
            props.getRejectOptions(props.readOnlyTransactionDetails?.attributes?.facility?.id)
        }
        if(props?.getOutboundTemplateRequest){
            props.getOutboundTemplateRequest();
        }
        setActive(props.readOnlyTransactionDetails != null)
    }, [props?.readOnlyTransactionDetails, props?.getRejectOptions, props?.getOutboundTemplateRequest])

    useEffect(() => {
        if(!active){
            setSelectedDocument("");
            setDocuments({});
        }
    },[active]);

    useEffect(() => {
        if (props.readOnlyTransactionDetails?.documentList){
            const newDocuments = props.readOnlyTransactionDetails.documentList.reduce((acc, cur) => {
                (acc[cur.type.role] = acc[cur.type.role] || []).push(cur);
                return acc;
            },{});

            if (documentRefresh){
                const mostRecentDoc = props.readOnlyTransactionDetails?.documentList?.reduce((acc, cur) => {
                    return acc?.created?.date ? 
                        new Date(acc.created.date) > new Date(cur.created.date) ? acc : cur :
                        cur;
                }, null);

                if (mostRecentDoc && new Date(mostRecentDoc.created.date) > userInteractTime){
                    setSelectedDocument(mostRecentDoc.id);
                }

            }

            const uris = Object.keys(fileTypes).reduce<string[]>((acc, cur) => {
                const u = newDocuments[cur]?.map(doc => doc.uri);
                if(u) acc.push(...u)
                return acc;
            }, [])

            newDocuments["OTHER"] = props.readOnlyTransactionDetails?.documentList.filter(doc => uris.indexOf(doc.uri) === -1 )

            setDocuments(newDocuments);
        }
    }, [props.readOnlyTransactionDetails?.documentList])

    const selectFile = (filename) => {
        setUserInteractTime(new Date());
        if(filename){
            setSelectedDocument(filename)
            if(selectedDocument === filename){
                resetIframeKey();
            }
        }else{
            setSelectedDocument(undefined)
        }
    }

    const getFileName = (file: any) => {
        const name = file.description || file.file?.name;
        return name && name.length > 0 ? name : "no file name";
    }
    
    const getUserGroups = () =>{
        props.getUserGroups && props.getUserGroups(props.readOnlyTransactionDetails?.attributes?.facility?.id)
    }

    const attachSelect = (eventKey) => {
        if (eventKey.id === "a") {
            const m = {
                state: true,
                type: eventKey.type,
                ...fileTypes[eventKey.type],
                allowAppend: Object.keys(documents).indexOf(eventKey.type) === -1 || documents[eventKey.type].length === 0 ? false : fileTypes[eventKey.type].allowAppend
            };
            setShowAttachFilesModal(m)
        } else if (eventKey.id === "w") {
            props.postWPSToken && props.postWPSToken(props.readOnlyTransactionDetails.transactionId, eventKey.type);
        }
    }

    const hideAttachFilesModal = () => {
        setShowAttachFilesModal({ state: false, type: "", modalType: "" })
    }
    
    useEffect(() => {
        if (props.uploadDocumentSuccess && Object.keys(uploadProgress).length && Object.keys(uploadProgress).every(f => uploadProgress[f].loaded === 100)) {
            setUploadProgress(prev => ({}));
            selectFile(props.uploadDocumentSuccess.url);
            refreshDocuments();
        }

        // if(props.uploadDocumentSuccess && props.uploadDocumentSuccess.arePageizesValid 
        //     && props.uploadDocumentSuccess.arePageizesValid === "False" && props.readOnlyTransactionDetails.attributes.releaseMethod.id == 5){
        //       toast(`Pages: ${props.uploadDocumentSuccess.invalidPagesNumbers} uploaded contain an invalid page size. Pages were modified to fit letter size please review the document`, {id: "baddoc", duration: 5000, position: 'top-center'});
        //    }
    }, [props.uploadDocumentSuccess?.fileID])

    const UploadProgressCreator = (fileId, filename) => (progress) => {
        let percentage = Math.floor((progress.loaded * 100) / progress.total)
        setUploadProgress(prev => ({
            ...prev,
            [fileId]: {
                name: filename,
                loaded: percentage
            }
        }));
    }

    const refreshDocuments = () => {
        props.getTransactionDocuments && props.readOnlyTransactionDetails && props.getTransactionDocuments(props.readOnlyTransactionDetails.transactionId)
    }
    
    const [documentRefresh, setDocumentRefresh] = useState<any>(null);

    useEffect(() => {
        if(selectedDocument){
            resetIframeKey();
        }
    },[selectedDocument])

    useEffect(() => {
        if (props.wpsToken && props.wpsToken != "") {
            window.open("verismawps:" + props.wpsToken, '_blank');
            const interval = setInterval(() => {
                refreshDocuments();
            }, 5000)

            setDocumentRefresh(interval);
            setUserInteractTime(new Date());

            return () => clearInterval(interval);
        }
    }, [props.wpsToken]);

    const uploadFiles = (files: any, append: boolean = false) => {
        hideAttachFilesModal();
        const requestObjs: any[] = [];
        const initProgress = {};
        Object.keys(files).forEach((fileKey: string, index: any) => {
            let requestObject = {
                fileName: files[fileKey].name,
                fileID: fileKey,
                recordType: files[fileKey]["fileType"],
                file: files[fileKey].file,
                transactionId: props.readOnlyTransactionDetails.transactionId,
                append: append,
                uploadProgress: UploadProgressCreator(fileKey, files[fileKey].name)
            }

            initProgress[fileKey] = { name: files[fileKey].name, loaded: 0 };

            requestObjs.push({ obj: requestObject });
        })

        setUploadProgress(prev => initProgress);
        process.nextTick(() => {
            requestObjs.forEach(o => props.uploadMultipleDocuments && props.uploadMultipleDocuments(o.obj));
        });

    }

    const resetIframeKey = () => {
        const key = Math.random().toString(36).substring(2, 6);
        setIframeKey(key);
    }

    const documentData = () => {
        if (selectedDocument) {
            return {
                ...props.readOnlyTransactionDetails?.documentList.find(d => d.id === selectedDocument),
                filePath: documentURL + selectedDocument + "/file?userid=" + global.userID + "&addTrailEntry=false"
            }
        } else {
            return {
                filePath: ''
            }
        }
    }

    return props.readOnlyTransactionDetails ? (
        <div className="readOnlyBackdrop">
            <UploadProgressBars uploadProgress={uploadProgress} uploadDocumentFailure={props.uploadDocumentFailure} setUploadProgress={setUploadProgress} />
            <div className="readOnlyWindow">
                <Row className="readOnlyContainer">
                    <Col className="readOnlyLeftColumn col-4">
                        <DataEntryDetailsLeftPane
                            dataEntryFormState={{ values: props.readOnlyFormState, initial: props.readOnlyFormState}}
                            transactionId={props.readOnlyTransactionDetails.transactionId}
                            transactionDetails={props.readOnlyTransactionDetails.attributes}
                            userTasks={props.userTasks || []}
                            userTaskComments={props.userTasksComments || []}
                            resetForm={() => {}}
                            requestorDetails={props.readOnlyTransactionDetails.requestorDetails}
                            patientDetails={props.readOnlyTransactionDetails.patientDetails}
                            transactionNotes={props.transactionNotes}
                            transactionNoteTypes={props.transactionNoteTypes}
                            updateTransactionNotes={props.updateTransactionNotes}
                            updateUserTasks={props.updateUserTasks}
                            updateUserTaskComments={props.updateUserTaskComments}
                            createUserTaskComment={props.createUserTaskComment }
                            addNewTask={props.addNewTask}
                            updateTask={props.updateTask}
                            deleteTransactionTask={props.deleteTransactionTask }
                            closeReadOnlyModal={props.clearReadOnly}
                            getUserGroups={getUserGroups}
                            facilityId={props.facilityId}
                            getAuditTrailData={props.getAuditTrailData}
                            auditTrail={props.auditTrail}
                            loadUnwaitedTx={props.loadUnwaitedTx}
                        /> 
                    </Col>
                    <Col className="readOnlyRightColumn col-8">
                        <div className="readOnlyRightTitle">
                            <div className="readOnlySelectContainer">
                                <select onChange={(event) => { selectFile(event.target.value) }}>
                                    <option value={""}>Select File</option>
                                    {Object.keys(fileTypes).map(type => {
                                        return documents[type] && documents[type].length && (
                                            <optgroup label={fileTypes[type].label}>
                                                {documents[type].map((f: any, i) => (
                                                    <option value={f.id} id={i} selected={f.id === selectedDocument}>{getFileName(f)}</option>
                                                ))}
                                            </optgroup>
                                        )
                                    })}
                                </select>
                            </div> 
                            <div className="readOnlyAttachContainer"> 
                                <span className='upload attach-records dropup'>
                                    <ButtonWithDropdown
                                        drop='down'
                                        style={{ marginTop: '-.5em' }}
                                        title={"Attach Documents"}
                                        onSelect={(event) => attachSelect(event)}
                                        items={Object.keys(fileTypes)
                                            .filter(key => fileTypes[key].ListOrder && 
                                                           (props.readOnlyTransactionDetails.attributes.transactionStatus.id != 260 || fileTypes[key].AllowOnArchive) &&
                                                           (props.readOnlyTransactionDetails.attributes.transactionStatus.id < 130 || fileTypes[key].AllowOnComplete))    
                                            .sort((a, b) => fileTypes[a].ListOrder > fileTypes[b].ListOrder ? 1 : -1)
                                            .flatMap((key, i) => [
                                                    i === 0 ? null :{ divider: true },
                                                    { optgroup: fileTypes[key].ListLabel },
                                                    { title: 'Attach Files', id: 'a', type: key },
                                                    { title: 'Web print / scan', id: 'w', type: key }
                                                ]
                                                .filter(e => e)
                                            )
                                        }
                                    ></ButtonWithDropdown>
                                    {showAttachFilesModal.state ? <UploadFileModal onClickSave={uploadFiles} type={showAttachFilesModal.modalType} handleClose={hideAttachFilesModal} showModal={showAttachFilesModal.state} allowAppend={showAttachFilesModal.allowAppend} /> : null}
                                </span>
                            </div>
                            {[240, 260].indexOf(props.readOnlyTransactionDetails.attributes.transactionStatus.id) > -1 &&
                            <div className="readOnlyResendContainer">
                                <Button onClick={() => setShowResendModal({state: true})}>Resend Transaction</Button>
                                {showResendModal.state ? <ResendPopup 
                                    transactionId={props.readOnlyTransactionDetails.transactionId}
                                    requestorId={props.readOnlyTransactionDetails.requestorDetails.requestorID} 
                                    email={props.readOnlyTransactionDetails.requestorDetails?.requestorInfo?.email}
                                    selectedContactId={props.readOnlyTransactionDetails.requestorDetails?.requestorInfo?.id}
                                    onClose={() => setShowResendModal({state: false})}
                                    /> : null}
                            </div>
                            }
                            <div className="readOnlyCloseContainer">
                                <a className="readOnlyClose" onClick={close}><CloseIcon style={{fontSize: "48px"}} /></a>
                            </div>
                        </div>
                        <Row className="readOnlyDocContainer">
                            <PdfViewer
                                key={iframeKey}
                                fileData={documentData()}
                            /> 
                        </Row>
                    </Col>
                </Row>
            </div>
            <Toaster toastOptions={{
                  className: 'toast-notification',
                }}
                position="top-right" />
        </div>) :
        <></>;
}

const readOnlyReduxForm = reduxForm<{}, ReadOnlyTransactionProps>({
    form: FormConstants.READ_ONLY_FIELDS_FORM,
    destroyOnUnmount: false,
})(ReadOnlyTransaction)


function mapStateToProps(state: any, props: any) {
    const formState = {
        [FormConstants.SENSITIVE_INFORMATION]: state.landingPageState?.readOnlyTransactionDetails?.attributes?.sensitiveInfo?.reduce((acc, cur) => {
            acc[cur.name] = cur.stateID ? { "recordType": cur.name } : null;
            return acc;
        }, {}),
        
        [FormConstants.GENETIC_INFORMATION]: state.landingPageState?.readOnlyTransactionDetails?.attributes?.sensitiveInfo?.find(i => i.name === FormConstants.GENETIC_INFORMATION)?.stateID === 1,
        [FormConstants.HIV]: state.landingPageState?.readOnlyTransactionDetails?.attributes?.sensitiveInfo?.find(i => i.name === FormConstants.HIV)?.stateID === 1,
        [FormConstants.MENTAL_HEALTH]: state.landingPageState?.readOnlyTransactionDetails?.attributes?.sensitiveInfo?.find(i => i.name === FormConstants.MENTAL_HEALTH)?.stateID === 1,
        [FormConstants.SUBSTANCE_ABUSE]: state.landingPageState?.readOnlyTransactionDetails?.attributes?.sensitiveInfo?.find(i => i.name === FormConstants.SUBSTANCE_ABUSE)?.stateID === 1,
        [FormConstants.STD]: state.landingPageState?.readOnlyTransactionDetails?.attributes?.sensitiveInfo?.find(i => i.name === FormConstants.STD)?.stateID === 1,
        [FormConstants.RELEASE_INFO_COMMENTS]: state.landingPageState?.readOnlyTransactionDetails?.attributes?.releaseInfo?.filter(r => r.comment)?.reduce((acc, cur) => {
            return {
                
                ...acc,
                [cur.code]: { recordType: cur.code, text: cur.comment, checked: cur.checked}
            }
        }, {}),
        [FormConstants.RETRIEVE_RECORD_TYPE]: state.landingPageState?.readOnlyTransactionDetails?.attributes?.releaseInfo?.filter(r => r.checked)?.reduce((acc, cur) => {
            return {
                
                ...acc,
                [cur.code]: { recordType: cur.code, text: cur.comment}
            }
        }, {}),
        [FormConstants.RTR_FROM]: state.landingPageState?.readOnlyTransactionDetails?.attributes?.releaseInfo?.reduce((acc, cur) => {
            if (!cur.dateOfService?.from) return acc;
            const date = new Date(cur.dateOfService.from);
            if (!acc) return date;
            return date < acc ? date : acc;
        }, null),
        [FormConstants.RTR_TO]: state.landingPageState?.readOnlyTransactionDetails?.attributes?.releaseInfo?.reduce((acc, cur) => {
            if (!cur.dateOfService?.to) return acc;
            const date = new Date(cur.dateOfService.to);
            if (!acc) return date;
            return date > acc ? date : acc;
        }, null),
        [FormConstants.RTR_ALL]: state.landingPageState?.readOnlyTransactionDetails?.attributes?.releaseInfo?.every(i => !i.dateOfService?.from && !i.dateOfService?.to),
        [FormConstants.DISCLOSE_ALL_INFO]: state.landingPageState?.readOnlyTransactionDetails?.attributes?.sensitiveInfo?.every(i => i.stateID === 1),
        [FormConstants.DONT_DISCLOSE_ANY_INFO]: state.landingPageState?.readOnlyTransactionDetails?.attributes?.sensitiveInfo?.every(i => i.stateID === 0),
        [BillingFormconstants.BILL_TO_REQUESTOR_NAME]: state.landingPageState?.readOnlyTransactionDetails?.billingDetails?.requestorBillingLocation,
        [BillingFormconstants.BILL_TO_FIRST_NAME]: state.landingPageState?.readOnlyTransactionDetails?.billingDetails?.firstName,
        [BillingFormconstants.BILL_TO_LAST_NAME]: state.landingPageState?.readOnlyTransactionDetails?.billingDetails?.lastName,
        [BillingFormconstants.BILL_TO_ADDRESS_LINE1]: state.landingPageState?.readOnlyTransactionDetails?.billingDetails?.address?.address1,
        [BillingFormconstants.BILL_TO_ADDRESS_LINE2]: state.landingPageState?.readOnlyTransactionDetails?.billingDetails?.address?.address2,
        [BillingFormconstants.BILL_TO_CITY]: state.landingPageState?.readOnlyTransactionDetails?.billingDetails?.address?.city,
        [BillingFormconstants.BILL_TO_STATE]: state.landingPageState?.readOnlyTransactionDetails?.billingDetails?.address?.state,
        [BillingFormconstants.BILL_TO_EMAIL_ADDRESS]: state.landingPageState?.readOnlyTransactionDetails?.billingDetails?.email,
        [BillingFormconstants.BILL_TO_ZIP]: state.landingPageState?.readOnlyTransactionDetails?.billingDetails?.address?.zipCode,
        [BillingFormconstants.BILL_TO_PHONE]: state.landingPageState?.readOnlyTransactionDetails?.billingDetails?.phone,
        [BillingFormconstants.BILL_TO_FAX]: state.landingPageState?.readOnlyTransactionDetails?.billingDetails?.fax,
    }

    return {
        readOnlyTransactionDetails: state.landingPageState.readOnlyTransactionDetails,
        userTasks: state.landingPageState.userTasks,
        userTasksComments: state.landingPageState.userTasksComments,
        transactionNotes: state.landingPageState.transactionNotes,
        transactionNoteTypes: state.landingPageState.transactionNoteTypes,
        readOnlyFormState: formState,
        auditTrail: state.landingPageState.auditTrail,
        formState: state.form && state.form[FormConstants.DATA_ENTRY_FIELDS_FORM],
        facilityId: state.landingPageState?.readOnlyTransactionDetails?.attributes.facility?.id,
        wpsToken: state.landingPageState.readOnlyTransactionDetails?.attributes?.wpsToken,
        uploadDocumentAPIStatus: state.landingPageState.readOnlyTransactionDetails?.attributes?.uploadDocumentAPIStatus,
        uploadDocumentFailure: state.landingPageState.readOnlyTransactionDetails?.attributes?.uploadDocumentFailure,
        uploadDocumentSuccess: state.landingPageState.readOnlyTransactionDetails?.attributes?.uploadDocumentSuccess,
        featureFlags: state.loginState.featureFlags,
    };
}

export function mapDispatchToProps(dispatch: Function) {
    return {
        clearReadOnly: () => dispatch(clearReadOnlyTransactionRequest()),
        getUserTasks: (data: any) => dispatch(getReadOnlyUserTasksSaga(data)),
        updateUserTasks: (data) => dispatch(updateReadOnlyUserTasksActionCreator(data)),
        updateUserTaskComments: (data) => dispatch(updateReadOnlyUserTaskCommentsActionCreator(data)),
        createUserTaskComment: (data) => dispatch(createReadOnlyUserTaskCommentActionCreator(data)),
        addNewTask: (data) => dispatch(addNewReadOnlyTaskActionCreator(data)),
        updateTask: (data) => dispatch(updateReadOnlyTaskActionCreator(data)),
        deleteTransactionTask: (transactionId: string, usertaskId: string) => dispatch(deleteReadOnlyTransactionTaskActionCreator(transactionId, usertaskId)),
        updateTransactionNotes: (data: any) => dispatch(updateReadOnlyTransactionNotes(data)),
        getAuditTrailData: (transactionId: string) => dispatch(getAuditTrail(transactionId)),
        getUserGroups: (facilityId: string) => dispatch(getUserGroupsActionCreator(facilityId)),
        postWPSToken: (transactionId, docType) => dispatch(postReadOnlyWPSToken(transactionId, docType)),
        uploadMultipleDocuments: (data: any) => dispatch(uploadReadOnlyMultipleDocumentsActionCreator(data)),
        getTransactionDocuments: (transactionId: string) => dispatch(getReadOnlyTransactionDocuments(transactionId)),
        getHealthOrganizationsSearch: (offset: number, limit: number, searchTerm: string, returnEmpty: boolean) => dispatch(getHealthOrganizationsSearchRequest(offset, limit, searchTerm, returnEmpty)),
        resendTransaction: (transactionId: string, contactId: number) => dispatch(putResendTransaction(transactionId, contactId)),
        getRejectOptions: (facilityId: string) => dispatch(getRejectOptions(facilityId)),
        getOutboundTemplateRequest: () => dispatch(getOutboundTemplateRequest())
    };
}

export default connect<{}, {}, ReadOnlyTransactionProps>(mapStateToProps, mapDispatchToProps)(readOnlyReduxForm);

//export default connect(mapStateToProps, mapDispatchToProps)(ReadOnlyTransaction);
