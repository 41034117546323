import React, { useState, useEffect } from "react";
import { Col, Form, InputGroup, Button } from "react-bootstrap";
import { Close, Search } from "@material-ui/icons";
import HomeIconBlue from "../../assets/images/headerImages/HomeIconBlue.png";

const SearchBox = (props) => {
  const [term, setTerm] = useState('');

  const onSearchChange = e => {
    setTerm(e.target.value);
    props.searchChange(e.target.value);
  }

  const onClearSearch = () => {
    setTerm('');
    props.searchChange('');
  }

  useEffect(() => {
    setTerm(props.searchterm)
  }, [props.searchterm])


  return props.searchChange == null ? null :
    <Col>
      <Form className='searchbox-container' onSubmit={(e) => e.preventDefault()} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <InputGroup className='searchbox-input' style={{ justifyContent: 'flex-end', width: 470 }}>
          <Form.Control
            style={{ backgroundColor: "transparent", borderRight: "none", paddingLeft: 35, borderTopLeftRadius: 17, borderBottomLeftRadius: 17, }}
            type="text"
            placeholder={props.placeholder}
            value={term}
            onChange={onSearchChange}
            className="col-md-8"
            id="searchBox"
          />
          <InputGroup.Append>
            <Button onClick={onClearSearch} className="form-control" style={{ backgroundColor: "transparent", color: "black", borderLeft: "none", borderTopRightRadius: 17, borderBottomRightRadius: 17, }}>
              <Close style={{ fontSize: 20, padding: 0, margin: -8, marginBottom: 0 }} ></Close>
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Form>
    </Col>
};

export default SearchBox;