import { createStore, applyMiddleware,compose } from 'redux';
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer } from 'redux-persist'
import {routerMiddleware} from 'react-router-redux';
import storage from 'redux-persist/lib/storage'
import rootReducer from './rootReducer';
import rootSaga from '../services/sagas/index';
import {routerHistoryMiddleware} from './navigation/navigationDucks';
const createLogger = require('redux-logger').createLogger;
const createHistory = require("history").createBrowserHistory;

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['loginState']
}

const loggerMiddleware = createLogger();
const sagaMiddleware = createSagaMiddleware()

const persistedReducer = persistReducer(persistConfig, rootReducer)
export const history = createHistory();
export const store = configureStore(history);

export const persistor = persistStore(store)

export function configureStore(history: any) {
    const middleware = routerHistoryMiddleware(history);
    const reduxMiddleware = routerMiddleware(history)
    // const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    // todo ,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    
    let reduxStore = createStore(
      persistedReducer,
		compose(
            applyMiddleware(
                sagaMiddleware,
                middleware,
                reduxMiddleware
            )
      )
    );
    sagaMiddleware.run(rootSaga)
    return reduxStore;
}
