import { pl } from 'date-fns/locale';
import React from 'react'; 
import DropdownField from '../formFields/DropdownField'
import { mirrorData } from '../utils/appUtils';

/**
 * to be wrapped with redux-form Field component
 */
export default function ReduxFormDropdownField(props) {

  const { input, meta, label, required, options, onChange, errMsgs, onBlur, disabled, placeholder } = props

  const onChangeValue = (value: any) => {
    input && input.onChange && input.onChange(value);
    onChange && onChange(value.target.value, input.name);
  }

  const customOnBlur = () => {
    mirrorData(input.name, input.value)
    onBlur && onBlur(input.value, input.name)
  }

  // do not display warning if the field has not been touched or if it's currently being edited
  let error = errMsgs || '';
  if (meta.touched && !meta.active) {
    if (meta.valid) {
    } else {
      error = meta.error
    }
  }

  return (
    <DropdownField 
        label={label} 
        required={required}
        errMsg={error}
        onChange={onChangeValue}
        onBlur={customOnBlur}
        options={options}
        input={input}
        meta={meta}
        disabled={disabled}
        placeholder={placeholder}
    />
  );
}