import React, { useState, useEffect } from "react";
import './NewRequestModal.scss';
import {
  Modal,
  Row,
  Col,
} from "react-bootstrap";
import { CopyTransactionModalConstants } from '../../components/dataEntryDetails/dataEntryComponents/DataEntryFieldsConstants';
import IconWithButton from "../IconWithButton/IconWithButton";
import { reduxForm, Field } from 'redux-form';
import ReduxFormTextField from "../../common/reduxFormFields/ReduxFormTextField";
import { connect } from 'react-redux'
import localValidations from '../../components/dataEntryDetails/dataEntryComponents/validations/localValidations';
import CopyRecordsTable from '../../common/table/CopyRecordsTable';
import { getTransactionDocumentsToCopy,updateTransactionOnFileCreater } from '../../components/dataEntryDetails/DataEntryDetailsDucks'

interface IProps {
  show: Function,
  handleClose: any,
  transactionId: string,
  getTransactionDocumentsToCopy?: Function,
  updateTransactionOnFileCreater?: Function,
  showCopyTransaction?: any
}

function CopyTransactionModal(props: IProps) {
  const [searchTransactionId, setSearchText] = useState('')
  const [selectedRowsCount, setSelectedRowsCount] = useState(0)
  const [selectedDocumentIds, setSelectedDocumentIds] = useState('')
  const [copyDisabled, setCopyDisabled] = useState(false);
  const [clickedSearch, setClickedSearch] = useState(false);

  const onClickSearch = () => {
    let requestObject = {
      transactionId: props.transactionId,
      sourceTransactionId: searchTransactionId
    }
    props.getTransactionDocumentsToCopy && props.getTransactionDocumentsToCopy(requestObject)
    setClickedSearch(true)
  }

  const localValidations = (values, props) => {
    let errors = {}
  }
  const onChange = (value) => {
    setSearchText(value)
  }
  const getSelectedRowsCount = (count) => {
    setSelectedRowsCount(count)
  }
  const getSelectedDocumentIds = (ids) => {
    setSelectedDocumentIds(ids)
  }
  const onClickYesHandler = () => {
    if (selectedDocumentIds.length){
      setSearchText('');
      let requestObject = {
          transactionId: props.transactionId,
          sourceTransactionId: searchTransactionId,
          documentIds: selectedDocumentIds
        }
      props.updateTransactionOnFileCreater && props.updateTransactionOnFileCreater(requestObject);
      setCopyDisabled(true);
    }
}
  const buttonText = selectedRowsCount === 1 ? 'Copy ' + selectedRowsCount + ' record to this transaction' : selectedRowsCount > 0 ? 'Copy ' + selectedRowsCount + ' records to this transaction' 
    : 'Copy records to this transaction';
  return (
    <Modal className="model-dimension modal-ext" show={props.showCopyTransaction}>
      <Modal.Title className="new-request-header"><span className="info-icon"></span>
        <p className="upload-header-text">Copy records from a Transaction</p><span className="close-icon" onClick={props.handleClose}></span></Modal.Title>
      <div className="new-request-content">
        <div style={{ marginLeft: "0" }} className="row">
            <div style={{ flexBasis: "33%" }}>
                <Field
                    name={CopyTransactionModalConstants.SEARCH_TRANSACTION}
                    component={ReduxFormTextField}
                    props={{
                        placeholder: "Transaction #",
                        maxLength: 50,
                        required: false,
                        onChange: onChange
                    }}
                  /> 
            </div>
            <div className="data-entry requestor-search-icon">
                <IconWithButton
                    onButtonClick={onClickSearch}
                    buttonText='View records'
                  />
             </div>
        </div>
        <div className='tab-records'>
        {clickedSearch? <CopyRecordsTable getSelectedRowsCount={getSelectedRowsCount} getSelectedDocumentIds={getSelectedDocumentIds} /> : ""}
        </div>
      </div>
      <Row className="button-align">
        <Col xs={6} md={6} className="button-position-cancel">
          <a href="#!" onClick={props.handleClose} className="modal-cancel">
            Cancel
          </a>
        </Col>
        <Col xs={6} md={6} className="button-position-save">
          <IconWithButton
            buttonText={buttonText}
            onButtonClick={onClickYesHandler}
            hideArrowIcon={true}
            isSave={true}
            isDisabled={selectedRowsCount === 0 || copyDisabled}
          />
        </Col>
      </Row>

    </Modal>
  );
}
  const CopyTransactionModalReduxForm = reduxForm<{}, IProps>({
    form: CopyTransactionModalConstants.COPY_TRANSACTION_MODAL_FORM,
    validate: localValidations,
    enableReinitialize: true,
    touchOnChange: true
  })(CopyTransactionModal)
  
  function mapStateToProps(state: any) {
    return {
      formState: state.form && state.form[CopyTransactionModalConstants.COPY_TRANSACTION_MODAL_FORM],
    }
  }
  export function mapDispatchToProps(dispatch: Function) {
    return {
        getTransactionDocumentsToCopy: (data: any) => dispatch(getTransactionDocumentsToCopy(data)),
        updateTransactionOnFileCreater: (data: any) => dispatch(updateTransactionOnFileCreater(data))
  
    };
  }
export default connect<{}, {}, IProps>(mapStateToProps, mapDispatchToProps)(CopyTransactionModalReduxForm);
