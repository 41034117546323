import React, { useState, useEffect } from "react";
import './alertPopup.scss';
import { connect } from "react-redux";
import { updateShowAPIErrorActionCreator } from '../../components/dataEntryDetails/DataEntryDetailsDucks';


interface APIErrorObjectProps {
    id?: number,
    showAlert?: boolean,
    status?: number,
    statusText?: string,
    code?: number,
    message?: string,
    messages?: Array<{code:number, message:string}>,
    responseURL?: string
}

interface APIErrorPopUpProps {
    headerText?: String,
    apiErrorArray?: Array<APIErrorObjectProps>,
    updateShowAPIError?: any,
}

function APIErrorPopUp(props: APIErrorPopUpProps) {
    const [currentErrorObj, setCurrentErrorObj] = useState<APIErrorObjectProps[]>([])
    useEffect(() => {
        const tempArr: any = [];
        if (props.apiErrorArray) {
            for (let i = 0; i < props?.apiErrorArray?.length; i++) {
                if (props.apiErrorArray[i].showAlert) {
                    tempArr.push(props.apiErrorArray[i])
                }
            }
        }
        setCurrentErrorObj(tempArr);
    }, [props.apiErrorArray])

    if (currentErrorObj.length) {
        return (
            <React.Fragment>
                        {
                            currentErrorObj.map((obj, key) =>
                                (obj?.showAlert)
                                    ? (<React.Fragment>
                                <div className="error-backdrop" onClick={() => props.updateShowAPIError(obj.id)}>
                                        <div className="api-error-popup-main" style={{marginLeft: `${obj.id}%`}}>
                                            <div className="alert alert-danger alert-dismissible fade show api-error-popup" role="alert" style={{ backgroundColor: '#FAEEF0', paddingRight: '17px', marginTop: `${obj.id}%` }}>
                                                <div className="alert-icon"></div>
                                                <h5 className="alert-heading">Error occurred
                                                <span className="close-icon alert-close-icon" onClick={() => props.updateShowAPIError(obj.id)}></span>
                                                </h5>

                                                <p className="mb-0 pb-2" style={{ fontSize: '16px' }}>
                                                    Service is temporarily unavailable. Please try again later.
                                            </p>
                                                <p className="mb-0">
                                                    {obj.responseURL}
                                                </p>
                                                <p className="mb-0">
                                                    HTTP Code: {obj.status} - {obj.statusText}
                                                </p>
                                                {obj.message &&
                                                    <p className="mb-0">
                                                        API Error Code: {obj.code} - {obj.message}
                                                    </p>
                                                }
                                                {obj.messages &&
                                                    obj.messages.map(m => 
                                                        <p className="mb-0">
                                                            API Error Code: {m.code} - {m.message}
                                                        </p>
                                                    )
                                                }

                                            </div>
                                        </div>
                                        </div>
                                    </React.Fragment>
                                    ) : null
                            )
                        }
            </React.Fragment>

        );
    }
    else {
        return null
    }

}

function mapStateToProps(state: any) {
    return {
        apiErrorArray: state.dataEntryState?.apiErrorArray
    };
}

function mapDispatchToProps(dispatch: Function) {
    return {
        updateShowAPIError: (id: number) => dispatch(updateShowAPIErrorActionCreator(id)),
    }
}

export default connect<{}, {}, APIErrorPopUpProps>(mapStateToProps, mapDispatchToProps)(
    APIErrorPopUp
);