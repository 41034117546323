import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux'
import { logoutUserRequest } from '../../components/login/LoginPageDucks';
import { useHistory } from "react-router-dom";
import './timeoutWarning.scss';

const TimeoutWarning = (props) => {
  const totalTimeout = 20 * 60;
  const warningTime = 60;
  const history = useHistory();

  const { logoutUser } = props;

  const [countdown, setCountdown] = useState(totalTimeout);
  const [userId, setUserId] = useState(0);

  const muserInteract = useCallback(() => {
    const resetTimer = () => {
      setCountdown(() => totalTimeout);
    };

    if (countdown > warningTime) resetTimer();
  }, [countdown, totalTimeout]);

  useEffect(() => {
    window.addEventListener("click", muserInteract, { passive: true });
    window.addEventListener("mousemove", muserInteract, { passive: true });
    window.addEventListener("keydown", muserInteract, { passive: true });
    const interval = setInterval(deductCountdown, 1000);

    return () => {
      window.removeEventListener("click", muserInteract);
      window.removeEventListener("mousemove", muserInteract);
      window.removeEventListener("keydown", muserInteract);
      clearInterval(interval);
    };
  }, [muserInteract]);

  useEffect(() => {
    const logout = () => {
      logoutUser({ userId, redirect: () => history.push("/") });
    };

    if (countdown < 1) {
      logout();
    }
  }, [countdown, logoutUser, userId, history]);

  useEffect(() => {
    if (props.userState?.userAttributes?.id != null)
      setUserId(props.userState.userAttributes.id)
  }, [props.userState?.userAttributes?.id]);

  const deductCountdown = () => {
    setCountdown((countdown) => countdown - 1);
  };

  const resetTimer = () => {
    setCountdown(() => totalTimeout);
  };

  return <>
    {/* <span>Logout in: {Math.trunc(countdown / 60) + ":" + (countdown % 60).toString().padStart(2, '0')}</span> */}
    {countdown <= warningTime
      ? (<div id='timeoutWarningOverlay'>
        <div id='timeoutWarningModal'>
          <p id='timeoutWarningHeader'> Your session is about to expire </p>
          <p id='timeoutWarningCountdown'>You will be logged out in <span id='timeoutWarningDigits' >{countdown}</span> seconds</p>
          <div id='timeoutWarningButtons'>
            <button id='timeoutWarningContinueButton' onClick={() => resetTimer()}> Continue Session </button>
          </div>
        </div>
      </div>)
      : null}
  </>
}

function mapStateToProps(state: any) {
  return {
    userState: state.loginState,
  }
}

export function mapDispatchToProps(dispatch: Function) {
  return {
    logoutUser: (action) => dispatch(logoutUserRequest(action)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeoutWarning);