import React, { useState, ChangeEvent, useEffect } from "react";
import './NewRequestModal.scss';
import {
  Modal,
  Row,
  Col,
  Nav,
  Navbar
} from "react-bootstrap";
import filedragdropImage from "../../assets/images/tabImages/FileDragIcon.png";
import IconWithButton from "../IconWithButton/IconWithButton";
import FilePicker from "../filePicker/FilePicker";
import RemoveIcon from '../../../src/assets/images/tabImages/RemoveIcon.png';
import CheckMarkFilled from '../../../src/assets/images/tabImages/CheckMarkFilled.png';
import { DEFAULT_DROPDOWN_VALUE } from "../constants/ApiConstants";
import Dropzone from 'react-dropzone'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ReportProblemOutlined from '@material-ui/icons/ReportProblemOutlined'
import { v4 as uuid } from 'uuid';
import toast from "react-hot-toast";

interface Props {
  handleClose: any,
  tab?: string,
  onClickSave: Function,
  type?: string,
  showModal?: any,
  allowAppend?: boolean
}

function UploadFileModal(props: Props) {

  const [selectedFiles, setSelectedFiles] = useState({});
  const [isButtonEnabled, setButtonEnablity] = useState(true)
  const [uploadAction, setUploadAction] = useState("");
  const [randId, setRandId] = useState('');

  useEffect(() => {
    setRandId(uuid().slice(0,8))
  },[])

  enum ValueConstants {
    AUXILIARY = "AUX",
    MEDICAL = "MED",
    REQUEST = "REQ",
    INTERNAL = "INT"
  }

  function getFileType(param: string) {
    switch (param) {
      case "request document":
        return ValueConstants.REQUEST;
      case "internal records":
        return ValueConstants.INTERNAL;
      case "auxiliary document":
        return ValueConstants.AUXILIARY;
      case "medical records":
        return ValueConstants.MEDICAL;
      default:
        return "";
    }
  }

  const allowMultiple = props.type === "request document" || props.type === "auxiliary document" ? false : true;
  const canAddToList = () => allowMultiple || Object.keys(selectedFiles).length === 0;

  const onSelectFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (event && event.target && event.target.files && event.target.files[0]) {
      addFilesToList(Array.from(event.target.files));
    }
  }

  const allowedTypes = [
    { ext: ['pdf'], mimetype: 'application' },
    { ext: ['tif', 'tiff'], mimetype: 'image' },
    { ext: ['jpg', 'jpeg'], mimetype: 'image' }
  ]

  const getFileExt = () => {
    return allowedTypes.flatMap(t => t.ext).map(t => '.' + t);
  }

  const getMimeTypes = () => {
    return allowedTypes.flatMap(t => t.ext.map(e => t.mimetype + '/' + e));
  }

  const getFileTypeGroups = () => {
    return allowedTypes.map(t => t.ext.join("/")).join(", ");
  }

  const maxFileSizeBase = 400
  const maxFileSizeUnits = "MB"
  const maxFileSize = maxFileSizeBase * (1024 * 1024);

  const addFilesToList = (files) => {
    try {
      const fileType = props.type ? getFileType(props.type) : ''
      let selectedFilesList = {}
      const errors = {
        fileTooLarge: false,
        unsupportedType: false,
        multipleFiles: false
      };

      files.forEach((file, index) => {
        let error = false;
        if (!allowMultiple && files.length > 1){
          errors.multipleFiles = true;
          error=true;
        }
        if (!getFileExt().some(t => file.name.toLowerCase().endsWith(t.toLowerCase()))) {
          errors.unsupportedType = true;
          error = true;
        }
        if (file.size && file.size > maxFileSize) {
          errors.fileTooLarge = true;
          error = true;
        }
        if (!error) {
          const id = file.name + "-" + index + "-" + file.lastModified + Math.random()
          selectedFilesList = {
            ...selectedFilesList,
            [id]: {
              lastModified: file.lastModified,
              name: file.name,
              size: file.size,
              type: file.type,
              id,
              fileType: fileType,
              file
            }
          }
        }
      }
      )

      if (errors.multipleFiles || errors.fileTooLarge || errors.unsupportedType) {
        const errorMessage = "One or more of the files you attempted to upload could not be accepted for the following reasons:\n" +
          (errors.multipleFiles ? "\n-You attempted to upload multiple files when only one is permitted" : "") +
          (errors.fileTooLarge ? "\n-The file was more than " + maxFileSizeBase + maxFileSizeUnits : "") +
          (errors.unsupportedType ? "\n-The file was not of an accepted type (" + getFileExt().join(', ') + ")" : "");

        alert(errorMessage);
      }
      if (Object.keys(selectedFilesList).length > 0 ) {
        let updatedSelectedFiles = allowMultiple ?
          {
            ...selectedFiles,
            ...selectedFilesList
          } : {
            ...selectedFilesList
          }

        setSelectedFiles(updatedSelectedFiles)
      }
    } catch (e) {
      console.log(e);
    }
  }


  enum documentHeader {
    fileName = "File Name",
    fileType = "File Type",
    status = "Status",
    size = "Size",
    actions = "Actions"
  }
  const documentHeaderList = Object.values(documentHeader);

  const renderSwitch = (param: string) => {
    switch (param) {
      case "FILE NAME":
        return "file-name file-name-upload ";
      case "FILE TYPE":
        return "file-type file-type-upload";
      case "ACTIONS":
        return "actions action-upload";
      default:
        return "document-col";
    }
  };
  const isAllFileTypesSelected = () => {
    const keys = Object.keys(selectedFiles);
    if (!keys.length) { return true }
    let unselectedFiles = keys.filter(fileKey => selectedFiles[fileKey].fileType === DEFAULT_DROPDOWN_VALUE)
    return unselectedFiles.length > 0
  }

  const onRemoveSelectedFile = (id, index) => {
    let updatedSeletedFiles = { ...selectedFiles };
    delete updatedSeletedFiles[id];
    setSelectedFiles(updatedSeletedFiles)
  }
  const files = () => {
    const keys = Object.keys(selectedFiles);
    return keys.map((fileKey: any, index) => {
      const file = selectedFiles[fileKey]
      const name = file.name || file.file.path;
      const fileType = file.type ? 
        file.type.split("/")[1].toUpperCase():
        name.split(".").pop().toUpperCase();
      return (
        <div key={fileKey} className="selected-file-row-container">
          <div className="file-name file-name-upload">{name.split('.').slice(0, -1).join('.')}</div>
          <div className="file-type-selected">{fileType}</div>
          <div className="document-col-selected-status document-col-selected-status-upload upload-status upload-status-upload">
            <img className="status-icon" src={CheckMarkFilled} alt="X" /></div>
          <div className="document-col-selected-size upload-size">{(file.size * 0.00000095367432).toFixed(2)} MB</div>
          <div className="document-col document-col-upload">
          </div>
          <a className="upload-remove" href="#!" onClick={() => { onRemoveSelectedFile(fileKey, index) }}>
            <span className="actions remove action-upload"><img className="" src={RemoveIcon} alt="X" /></span>
          </a>
        </div>
      )
    })
  }

  const handleDrop = (files, rejectedFiles, dropevent) => {
    addFilesToList([
      ...files,
      ...rejectedFiles.map(r => r.file)
      ]);
  }

  const changeUploadAction = (val) => {
    setUploadAction(val);
  }

  return (
    <Modal className="model-dimension" show={props.showModal}>
      <Modal.Title className="new-request-header"><span className="info-icon"></span>
        <p className="upload-header-text">Attach  {props.type} </p><span className="close-icon" onClick={props.handleClose}></span></Modal.Title>
      <div className="newRequest-content">
        {!allowMultiple &&
          (props.allowAppend ?
            <div className="uploadActionSelect" style={{ textAlign: 'center' }}>
              <ReportProblemOutlined style={{ "fontSize": "20px" }} />Only one request per document is stored
              <span onChange={e => changeUploadAction(e.target["value"].toString())}>
                <input type="radio" value="R" name="action" id={"uploadActionReplace-" + randId} /><label htmlFor={"uploadActionReplace-" + randId}>REPLACE Existing</label>
                <input type="radio" value="A" name="action" id={"uploadActionAppend-" + randId} /><label htmlFor={"uploadActionAppend-" + randId}>APPEND to existing</label>
              </span>
            </div> :
            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Only single file permitted</div>)}
        <div style={{ position: 'relative' }}>
          <Dropzone onDrop={(acceptedFiles, rejectedFiles, dropevent) => handleDrop(acceptedFiles, rejectedFiles, dropevent)} multiple={allowMultiple}
            accept={getMimeTypes().join(', ')}>
            {({ getRootProps, isDragActive, isDragAccept, draggedFiles }) => (
              <section>
                <div {...getRootProps()} className={"add-files-row"}>
                  {isDragActive && <>
                    {isDragAccept ?
                      <div className="dragdropHover">
                        <div>
                          <div>
                            <p className="dragdropMainText">Drag and Drop</p>
                            <div className="dragdropIcon"><CloudUploadIcon fontSize='inherit' /></div>
                            <p className="dragdropSubtext">Allowed Types: {getFileTypeGroups().toUpperCase()} | Up to {maxFileSizeBase + " " + maxFileSizeUnits}</p>
                          </div>
                        </div>
                      </div> :
                      !allowMultiple && draggedFiles.length > 1 ?
                        <div className="dragdropHover reject">
                          <div>
                            <p>You are attempting to upload multiple files to a document type which can only accept one.</p>
                          </div>
                        </div>
                        :
                        draggedFiles.some(f => f.type === "") ?
                          <div className="dragdropHover unknown">
                            <div>
                              <p>The type of file you are trying to upload could not be determined.
                                This is most commonly caused by dragging from someplace other than the computer's file system, such as an email client.</p>
                              <p>You may attempt to drop the file, and the upload area will accept it if it complies with the following conditions.</p>
                              <ul>
                                <li>It is a supported file type ({getFileTypeGroups()}</li>
                                <li>It is no more than {maxFileSizeBase + maxFileSizeUnits}</li>
                              </ul>
                            </div>
                          </div> :
                          <div className="dragdropHover reject">
                            <div>
                              <p>A file you are trying to upload can not be accepted for one of the following reasons:</p>
                              <ul>
                                <li>It is not a supported file type ({getFileTypeGroups()}</li>
                                <li>It is more than {maxFileSizeBase + maxFileSizeUnits}</li>
                              </ul>
                            </div>
                          </div>}
                  </>
                  }
                  <div className="upload-scroll">
                  <Row >
                    <Col>
                      <div className="document-row">

                        {documentHeaderList.map((header: string, index: number) => (
                          <span className={renderSwitch(header.toUpperCase())} >
                            {header.toUpperCase()}
                          </span>
                        ))}
                      </div>

                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="document-row-data">
                        {files()}
                      </div>
                    </Col>
                  </Row>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </div>

        <div className="button-position-select">
          <FilePicker accept={getFileExt().join(", ")} multiple={allowMultiple} onFileChage={onSelectFile} />
        </div>
      </div>
      <Row className="button-align">
        <Col xs={6} md={6} className="button-position-cancel">
          <a href="#!" onClick={props.handleClose} className="modal-cancel">
            Cancel
          </a>
        </Col>
        <Col xs={6} md={6} className="button-position-save">
          <IconWithButton
            buttonText={"Save"}
            onButtonClick={() => {
              if (isButtonEnabled) {
                if (!allowMultiple && props.allowAppend && uploadAction === ""){
                  alert("Please select either Replace or Append")
                }else{
                  props.onClickSave(selectedFiles, uploadAction === "A")
                  setButtonEnablity(false)
                }
              }
            }}
            isDisabled={isAllFileTypesSelected() || !isButtonEnabled}
            hideArrowIcon={true}
            isSave={true}
          />
        </Col>
      </Row>

    </Modal>
  );
}
export default UploadFileModal;
