export const API_INITIAL = 0;
export const API_FETCHING = 100;
export const API_SUCCESS = 200
export const API_FAILURE = 500

export const isSuccessStatusCode = (statusCode: number) => {
    return statusCode && statusCode >= 200 && statusCode < 300
}

export const INVALID_DATE = "Invalid date"
export const DEFAULT_MIN_DATE = new Date("01/01/1000")
export const DEFAULT_MAX_DATE = new Date("01/01/9999")

export const DEFAULT_DROPDOWN_VALUE = "-9999"
export const DEFAULT_DROPDOWN_NAME = "-Select-"
export const DEFAULT_DISCLOSURE_REASON = "-Select reason-"

export const TASK_STATUS = {
    NEW: "New",
    IN_PROGRESS: "In Progress",
    COMPLETED:"Complete"
}

export const TASK_STATUS_VALUES = {
    "New": 1,
    "In-Progress": 2,
    "Completed": 3
}