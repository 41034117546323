import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Col, Row } from "react-bootstrap";
import crossIcon from "../../../assets/images/x_cancel_icon.png";
import checkIcon from '../../../assets/images/check_green_icon.png';
import deleteFilled from "../../../assets/images/Delete_filled.png";
import CommentIcon from "../../../assets/images/comment-text.png";
import AutoCompleteDropdown from "../../../common/AutoCompleteDropdown";
import EditIcon from "../../../assets/images/editIcons/editSmallIcon.png";
import TextField from "../../../common/formFields/TextField";
import DateField from '../../../common/formFields/DateField'
import { TaskStatusDropdown } from './NewTaskCollapsed';
import { getMomentDate, LightTooltip, normalizeData } from '../../../common/utils/appUtils';
import { TASK_STATUS, TASK_STATUS_VALUES, INVALID_DATE } from '../../../common/constants/ApiConstants'

function NewTask(props) {
    let taskDetails = props.taskDetails
    if (props.isNewTask) {
        taskDetails = {
            id: Math.random(),
            dueDate: null,
            assignedTo: {},
            assignedBy: {
                userID: global.userID,
                displayName: props.userAttributes?.lastName + ", " + props.userAttributes?.firstName
            },
            subject: "",
            description: "",
            taskStatusID: TASK_STATUS_VALUES.New,
            taskStatus: TASK_STATUS.NEW,
            assignedToGroupIds: null,
            createdDate: new Date().toString()
        }
    }
    const [dueDateError, setDueDateError] = useState("")
    const [subjectError, setSubjectError] = useState("")
    const [assignedTask, setAssignedTask] = useState(taskDetails)
    const [commentValue, setCommentValue] = useState("");
    const prevAssignedTask = usePrevious(assignedTask)
    let commentsList = props.userTaskComments[assignedTask.id] || []

    const onChangeTaskStatus = (id: any, status: string) => {
        let updatedData = { ...assignedTask };
        updatedData = {
            ...updatedData,
            taskStatus: status,
            taskStatusID: id
        };
        setAssignedTask(updatedData)
    };

    const onChangeValues = (keyName: string, value: any) => {
        let updatedData = { ...assignedTask }
        updatedData = {
            ...updatedData,
            [keyName]: value
        }
        if (keyName === "subject") {
            value.length ? setSubjectError("") : setSubjectError("Required")
        } else if (keyName === "dueDate") {
            if (value && value !== INVALID_DATE && !isNaN(value.getTime())) {
                setDueDateError("")
            } else {
                setDueDateError("Required")
            }
        }
        setAssignedTask(updatedData)
    }

    const onClickSaveComment = () => {
        let requestObject = {
            taskId: assignedTask.id,
            comment: commentValue,
            createdBy: {
                userID: global.userID,
                displayName: props.userAttributes?.lastName + ", " + props.userAttributes?.firstName
            },
            createdDate: new Date(),
            taskID: 1050
        }
        props.createUserTaskComment && props.createUserTaskComment(requestObject)
        props.updateUserTaskComments && props.updateUserTaskComments(requestObject)
        setCommentValue("")
    }
    const onChangeAssignedTo = (event, value, eventType, { option }) => {
        let updatedData = { ...assignedTask }
        if (eventType === "remove-option") {
            updatedData = {
                ...updatedData,
                assignedTo: null,
                assignedToGroupIds: null
            }
        } else if (option.label && option.label.split(",").length > 1) {
            updatedData = {
                ...updatedData,
                assignedTo: {
                    id: option.value,
                    displayName: option.label,
                },
                assignedToGroupIds: null
            }
        } else {
            updatedData = {
                ...updatedData,
                assignedTo: {
                    id: 0,
                },
                assignedToGroupIds: option.value
            }
        }
        setAssignedTask(updatedData)
    }

    const discardSave = () => {
        props.setEditingTaskId(0)
        props.updateCreateTaskStatus && props.updateCreateTaskStatus(false)
    }

    const onClickEdit = () => {
        let updatedId = props.editingTaskId === assignedTask.id ? 0 : assignedTask.id
        if (!assignedTask.subject.length || !assignedTask.dueDate) {
            !assignedTask.subject.length && setSubjectError("Required")
            !assignedTask.dueDate && setDueDateError("Required")
            return null
        }
        if (!props.isNewTask && prevAssignedTask != undefined) {
            if (prevAssignedTask != assignedTask) {
                props.setEditingTaskId(updatedId)
                props.updateCreateTaskStatus(false)
                props.updateTask({ ...assignedTask, transactionId: props.transactionId, dueDate: getMomentDate(assignedTask.dueDate) })
            }

        } else if (assignedTask.subject && assignedTask.dueDate && !dueDateError.length) {
            if (props.isNewTask) {
                props.addNewTask({ ...assignedTask, transactionId: props.transactionId, dueDate: getMomentDate(assignedTask.dueDate) })
            }
            props.updateCreateTaskStatus(false)
            props.setEditingTaskId(0)

        }
    }
    const getUserGroupDefaultValue = () => {
        const groupId = assignedTask.assignedToGroupIds
        if (!groupId) {
            return assignedTask.assignedTo?.id ? [{ value: assignedTask.assignedTo?.id, label: assignedTask.assignedTo?.displayName }] : []
        } else {
            const normalizedData = normalizeData(props.userGroups, "value")
            return [{ value: groupId, label: normalizedData[groupId].label }]
        }
    }
    // custom hook for getting previous value 
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    const commentsFiltered = commentsList?.filter(comment => comment.taskID === 1050);
    return (
        <Fragment>

            <div className="taskAccordion">
                <Row>
                    <Col lg={5} style={{ paddingRight: "0px" }}>
                        <div className="d-flex flex-row">
                            <div className="d-flex flex-column">
                                <div>
                                    <span>By: </span>{assignedTask.assignedBy?.displayName}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={5} style={{ padding: "0px" }}>
                        <div className="d-flex flex-column">
                            <TaskStatusDropdown
                                id={assignedTask?.taskStatusID || TASK_STATUS_VALUES.New}
                                selectedStatus={assignedTask?.taskStatus || TASK_STATUS.NEW}
                                onChange={onChangeTaskStatus}
                                disableListItem={true && props.isNewTask}
                            />
                            <div className="task-dueBy-field">
                                Due by: <span className="required" style={{ color: "red" }}>*</span>
                                <DateField onChangeRTRDateRange={() => { }} onChangeVal={() => { }} label={null} placeholder="mm/dd/yyyy" onChange={(value) => onChangeValues("dueDate", value)} errMsg={dueDateError}
                                    value={assignedTask.dueDate} />

                            </div>
                        </div>
                    </Col>
                    <Col lg={2}>
                        <div style={{ float: "right" }}>
                            <Row>
                                <Col>
                                    <LightTooltip title="Edit Task" placement="right">
                                        <span>
                                            <img src={EditIcon} onClick={discardSave} className="pointer-cursor text-center edit-task-icon" alt="Edit Icon" />
                                        </span>
                                    </LightTooltip>

                                    <br />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {Number(assignedTask.createdBy?.id) === global.userID && assignedTask.taskStatus === TASK_STATUS.NEW ?
                                        <LightTooltip title="Delete Task" placement="right">
                                            <span>
                                                <img src={deleteFilled} className="pointer-cursor text-center" alt="Delete Icon" />
                                            </span>
                                        </LightTooltip>
                                        : null}
                                </Col>
                            </Row>
                            <Row>
                                {commentsList && commentsList.length ?
                                    <Col>
                                        <span
                                            style={{ color: "#5F9EC5", fontWeight: "bold", position: "absolute", marginTop: '14px' }}
                                        >
                                            {commentsFiltered.length}
                                        </span>
                                        <LightTooltip title="Count of Comments" placement="right">
                                            <span>
                                                <img src={CommentIcon} className="pointer-cursor text-center" alt="Comment Icon" />
                                            </span>
                                        </LightTooltip>
                                    </Col>
                                    : null}
                            </Row>
                        </div>
                    </Col>
                </Row>

                <div>
                    {props.userGroups.length && <AutoCompleteDropdown onChange={onChangeAssignedTo} data={props.userGroups} defaultValue={getUserGroupDefaultValue()} />}

                    <div className="task-subject-field">
                        <TextField label="Subject:" placeholder="Enter subject" required={true}
                            maxLength={100}
                            onChange={(value) => onChangeValues("subject", value)} errMsg={subjectError} value={assignedTask.subject}
                            suppressEnterSubmit />
                    </div>

                    <Row>
                        <Col><div style={{ marginBottom: '8px' }}>Task description:</div>
                            <textarea
                                placeholder="Enter task description"
                                className="dialog"
                                name="textarea"
                                maxLength={700}
                                value={assignedTask.description}
                                onChange={(e) => onChangeValues("description", e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row className="text-right">
                        <Col>
                            <LightTooltip title="Cancel Task" placement="right">
                                <span onClick={discardSave} onKeyDown={k => { k.key === 'Enter' && discardSave() }}  tabIndex={0}>
                                    <img
                                        src={crossIcon}
                                        alt={"undo save"}
                                        className="add-new-icon pointer-cursor cancel-task-icon"
                                    />
                                </span>
                            </LightTooltip>

                            <LightTooltip title="Save Task" placement="right">
                                <span onClick={onClickEdit} onKeyDown={k => { k.key === 'Enter' && onClickEdit() }}  tabIndex={0}>
                                    <img
                                        src={checkIcon}
                                        alt={"Save Comment"}
                                        className="add-new-icon pointer-cursor save-task-icon"
                                    />
                                </span>
                            </LightTooltip>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="task-comment-field">
                        <label style={{ margin: "8px 12px 0px 2px" }}>
                                Comments and Actions:
                        </label>
                            <textarea
                                placeholder="Enter comments here"
                                className="dialog"
                                name="textarea"
                                style={{height:153}}
                                maxLength={700}
                                value={commentValue}
                                onChange={(e) => setCommentValue(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <LightTooltip title="Save Comments" placement="right">
                                <span className={commentValue && !props.isNewTask ? "" : "addtaskstyles"} 
                                onClick={commentValue && !props.isNewTask ? onClickSaveComment : () => null} 
                                onKeyDown={k => { k.key === 'Enter' && commentValue && !props.isNewTask && onClickSaveComment() }} 
                                tabIndex={0}>
                                    <img
                                        src={checkIcon}
                                        alt={"Save Comment"}
                                        className="add-new-icon pointer-cursor save-comment-icon"
                                    />
                                </span>
                            </LightTooltip>

                        </Col>
                    </Row>
                    <Row>
                        <Col className="comment-section">
                            <div className='task-pane-comment-section'>
                                {commentsList.length ? commentsList.map(comment => {
                                    return (
                                        <>
                                            <Row className="comment-section-one">
                                                <Col className="enter-section">
                                                    <span>{getMomentDate(comment.createdDate, "MM/DD/YYYY h:mm A")}</span>
                                                    <br />
                                                    <span>{comment.createdBy?.displayName}</span>
                                                </Col>
                                                <Col>
                                                    <span>
                                                        {comment.taskID === 1050 ? "Comment" : "Action"}:
                                                    </span>
                                                    <span> {comment.eventType === "TaskHistory" ? comment.taskDescription : comment.comment}</span>
                                                </Col>
                                            </Row>
                                            <Row className="extra-line"></Row></>

                                    )
                                }) : null}
                            </div>

                        </Col>
                    </Row>
                </div>
            </div>

        </Fragment>
    );
}

export default NewTask;
