import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import '../table/CustomTable.scss'
import { reduxForm } from 'redux-form';
import FormConstants from '../../components/dataEntryDetails/dataEntryComponents/DataEntryFieldsConstants';
import localValidations from '../../components/dataEntryDetails/dataEntryComponents/validations/localValidations';
import submitValidations from '../../components/dataEntryDetails/dataEntryComponents/validations/localValidations';
import { connect } from "react-redux";
import Checkbox from '@material-ui/core/Checkbox';
import { API_FETCHING, API_SUCCESS } from '../constants/ApiConstants';
import LoadingSpinner from '../loadingSpinner/loadingSpinner'

interface Data {
    description: string;
    source: string;
    sab: string;
    size: string;
    pageCount: number;
    actions: string;
}

type Order = 'asc' | 'desc';

function stableSort(array, order, orderBy){
    const compare = (a, b) => {
        if (a[orderBy] < b[orderBy]) {
            return -1;
        }
        if (a[orderBy] > b[orderBy]) {
            return 1;
        }
        return 0;
    }
    if(order === "asc"){
        return array.sort(compare)
    }else{
        return array.sort(compare).reverse()
    }
}

interface HeadCell {
    id: keyof Data;
    label: string;
}

const headCells: HeadCell[] = [
    { id: 'description', label: 'RECORD NAME' },
    { id: 'source', label: 'SOURCE' },
    { id: 'sab', label: 'S/A/B' },
    { id: 'size', label: 'SIZE' },
    { id: 'pageCount', label: 'PAGES' }
];

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    numSelected: Number;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    rowCount: Number;
}
const handleChange = () => {
 
    
  };
function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort, onSelectAllClick, numSelected, rowCount } = props;
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };
    let updatedHeadCells = [...headCells]
    
    return (
        <TableHead>
            <TableRow>
            <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{'aria-label': 'select all desserts'}}
                    />
                </TableCell>
                {updatedHeadCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false} >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
interface IpropTypes {
    documentsToCopyList?: any,
    getSelectedRowsCount: Function,
    getSelectedDocumentIds: Function,
    getTransactionDocumentsAPIStatus?: any
}

function CopyRecordsTable(props: IpropTypes) {
    const emptyArray= Array();
    let newSelected= Array();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('pageCount');
    const [selected, setSelected] = React.useState(emptyArray);
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = props.documentsToCopyList.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const getFileName = (file:any) => {
        return file.description ||  file.file?.name
    }
    const isSelected = (id) => selected.indexOf(id) !== -1;

    useEffect(() => {
        if(selected.length > 0){
            props.getSelectedRowsCount(selected.length);
            props.getSelectedDocumentIds(selected);
        }else{
            props.getSelectedRowsCount(0);
            props.getSelectedDocumentIds([]);
        }
    },[selected.length])
    return (
        <div className="records-table-main">
            {props.documentsToCopyList.length > 0 ? <Table
                size="small" stickyHeader className='records-table'
            >
                <div className="records-table-inner">
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        numSelected={selected.length}
                        onSelectAllClick={handleSelectAllClick}
                        rowCount={props.documentsToCopyList.length}
                    />
                    <TableBody>
                        {stableSort(props.documentsToCopyList, order, orderBy)
                            .map((row: any, index) => {
                                const fileSize = row.file?.size?.bytes
                                const fileName = getFileName(row)
                                const isItemSelected = isSelected(row.id);
                                return (
                                    <>
                                        <TableRow hover key={index} className='noEdit-record-row' onClick={(event) => handleClick(event, row.id)}>
                                            <TableCell><Checkbox id={row.id} checked={isItemSelected}/></TableCell>
                                            <TableCell className='record_name' >
                                            <a href="#!" style={fileName && fileName.length > 0 ? {} : { opacity: 0.5 }}>{fileName && fileName.length > 0 ? fileName : "No file name"}</a>
                                            </TableCell>
                                           <TableCell className='record_source'>{row.source}</TableCell>
                                            <TableCell className='record_sab'>{row.sab}</TableCell>
                                            <TableCell className='record_size'>{isNaN(fileSize) ? fileSize : ((Number(fileSize) * 0.00000095367432).toFixed(2) + "MB")}</TableCell>
                                            <TableCell className='record_pages'>{row.pageCount}</TableCell>
                                        </TableRow>
                                    </>
                                );
                            })}

                    </TableBody>
                </div>
            </Table>
            : props.getTransactionDocumentsAPIStatus == API_FETCHING?
                <p style={{marginBottom: "60%"}}><LoadingSpinner/></p>
                    : <p style={{ textAlign: "center" }}>No documents available</p>
              
            
            }
        </div>
    );
}

const DataEntryDetailsReduxForm = reduxForm<{}, IpropTypes>({
    form: FormConstants.DATA_ENTRY_FIELDS_FORM,
    validate: localValidations,
    onSubmit: submitValidations,
    enableReinitialize: true,
    touchOnChange: true,
})(CopyRecordsTable);

function mapStateToProps(state: any) {

    return {
        documentsToCopyList: state.dataEntryState.documentsToCopyList,
        getTransactionDocumentsAPIStatus: state.dataEntryState.getTransactionDocumentsAPIStatus
    };
}
export function mapDispatchToProps(dispatch: Function) {
    return {
    };
}

export default connect<{}, {}, IpropTypes>(
    mapStateToProps,
    mapDispatchToProps
)(DataEntryDetailsReduxForm);