import React, { useRef, MutableRefObject } from 'react';
import IconWithButton from '../IconWithButton/IconWithButton';


interface FilePickerProps {
  onFileChage: any,
  multiple?: boolean
}

const FilePicker = props => {
  const hiddenFileInput = useRef() as MutableRefObject<HTMLInputElement>;
  
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  
  return (
    <>
    <div className="add-files-btn">
    <IconWithButton
        buttonText={"Add files"}
        onButtonClick={handleClick}
        isDisabled={props.disableFlag}
        isAddFile={true}
        hideArrowIcon={true}
      />
    </div>
      
      <input type="file"
        ref={hiddenFileInput}
        onChange={props.onFileChage}
        style={{ display: 'none' }}
        onClick={(e: any) => (e.target.value = null)}
        accept={props.accept || ".pdf, .tiff, .tif"}
        multiple={props.multiple}
      />
    </>
  );
};
export default FilePicker;



