import React from 'react'

export const ProceedIcon = ({color}) => {
    return (
        <div className='forward-icon'>
            <svg width="19" height="19" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" stroke={color} stroke-width="2" />
                <path d="M9.5 7L13.5 11L9.5 15" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
    )
}

export const SameAsIcon = ({ color }) => {
    return (
        <div className='same-as-icon'>
            <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 8.99979C4.22243e-05 9.19754 0.0587139 9.39084 0.168598 9.55525C0.278482 9.71966 0.434646 9.84779 0.617345 9.92346C0.800045 9.99913 1.00108 10.0189 1.19503 9.98037C1.38898 9.9418 1.56715 9.8466 1.707 9.70679L5.707 5.70679C5.89447 5.51926 5.99979 5.26495 5.99979 4.99979C5.99979 4.73462 5.89447 4.48031 5.707 4.29279L1.707 0.292787C1.56715 0.152978 1.38898 0.057771 1.19503 0.0192034C1.00108 -0.0193641 0.800045 0.000439122 0.617345 0.0761092C0.434646 0.151779 0.278482 0.279919 0.168598 0.444329C0.0587139 0.608738 4.22243e-05 0.802037 0 0.999787V8.99979ZM8 8.99979C8.00004 9.19754 8.05871 9.39084 8.1686 9.55525C8.27848 9.71966 8.43465 9.84779 8.61735 9.92346C8.80005 9.99913 9.00108 10.0189 9.19503 9.98037C9.38898 9.9418 9.56715 9.8466 9.707 9.70679L13.707 5.70679C13.8945 5.51926 13.9998 5.26495 13.9998 4.99979C13.9998 4.73462 13.8945 4.48031 13.707 4.29279L9.707 0.292787C9.56715 0.152978 9.38898 0.057771 9.19503 0.0192034C9.00108 -0.0193641 8.80005 0.000439122 8.61735 0.0761092C8.43465 0.151779 8.27848 0.279919 8.1686 0.444329C8.05871 0.608738 8.00004 0.802037 8 0.999787V8.99979Z" fill={color} />
            </svg>
        </div>
    )
}