import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, reduxForm } from 'redux-form';
import { RejectModalConstants } from "../../components/dataEntryDetails/dataEntryComponents/DataEntryFieldsConstants";
import localValidations from "../../components/dataEntryDetails/dataEntryComponents/validations/localValidations";
import submitValidations from '../../components/dataEntryDetails/dataEntryComponents/validations/submitValidations';
import IconWithButton from "../IconWithButton/IconWithButton";
import ReduxFormDropdownField from "../reduxFormFields/ReduxFormDropDownField";
import './alertPopup.scss';
import {   postCancellationReasonsActionCreators } from "../../components/dataEntryDetails/DataEntryDetailsDucks";
import {resetRequestContext} from '../../components/landingPage/LandingPageDucks'
import { useHistory } from 'react-router-dom'
import ReduxFormTextField from "../reduxFormFields/ReduxFormTextField";

interface Props {
    show: Function,
    handleClose: any,
    headerText?: String,
    contentText?: String,
    buttonText: string,
    buttonOnClick?: Function,
    cancellationReasons?: any,
    formState?:any,
    cancelErrMsg?:string,
    transactionId?:any,
    postCancellationReasonsDispatch?:any,
    setShowCancelModal?:any,
    resetForm?: Function
    resetFormDataEntryState?: Function,
    resetRequestContext?: Function,
    statusIndicatorID?:any
}
function CancelPopup(props: Props) {

    const history = useHistory();
    const { handleClose, show, buttonText,  headerText } = props;
    const [cancelError, setCancelError] = useState("");
    const [otherReasoncancelError, setOtherReasoncancelError] = useState("");

    const otherReasonValue = props.cancellationReasons.filter(x => x.label === "Other")
    const cancelHandler = () => {
        if(props.formState.values[RejectModalConstants.TRANSACTION_CANCEL_REASONS]==="-9999"){
            setCancelError("Cancelation reason is required ") ;
        }
        else if(props.formState.values[RejectModalConstants.TRANSACTION_CANCEL_REASONS] === `${otherReasonValue[0].value}` && props.formState.values[RejectModalConstants.TRANSACTION_CANCEL_OTHER_REASONS_TEXT].length === 0 ){
            console.log("test")
            setOtherReasoncancelError("Provide reason for cancelation")
        }
        else{
            // reset state here
            if(props.resetForm){
                props.resetForm()
            }

            if(props. resetFormDataEntryState){
                props.resetFormDataEntryState()
            }
            
            props.formState.values[RejectModalConstants.TRANSACTION_CANCEL_OTHER_REASONS_TEXT].length > 0? 
                props.postCancellationReasonsDispatch(props.transactionId, props.formState.values[RejectModalConstants.TRANSACTION_CANCEL_REASONS], props.formState.values[RejectModalConstants.TRANSACTION_CANCEL_OTHER_REASONS_TEXT])
                : props.postCancellationReasonsDispatch( props.transactionId, props.formState.values[RejectModalConstants.TRANSACTION_CANCEL_REASONS])
                
            props.setShowCancelModal(false);
            props.resetRequestContext && props.resetRequestContext()
            history?.push('/home')
        }
    }

    const changeDropDown =() =>{
        if(props.formState.values[RejectModalConstants.TRANSACTION_CANCEL_REASONS]){
            setCancelError("") ;
        }
    }

    const onChangeOtherReasonTextField = () => {
        if(props.formState.values[RejectModalConstants.TRANSACTION_CANCEL_REASONS] === `${otherReasonValue[0].value}` && props.formState.values[RejectModalConstants.TRANSACTION_CANCEL_OTHER_REASONS_TEXT].length > 0){
            setOtherReasoncancelError("")
        }
    }

    return (
        <Modal className="cancel-dimension" show={props.setShowCancelModal}>
            <div className="alert-container">
                <Modal.Title className="cancel-header">
                    <span className="info-icon"></span>
                    <div className="cancel-header-text">{headerText}</div>
                </Modal.Title>
                <div className="newRequest-content">
                    <Field
                        name={RejectModalConstants.TRANSACTION_CANCEL_REASONS}
                        component={ReduxFormDropdownField}  
                        onChange = {changeDropDown }  
                        props={{
                            label: "Select a reason for cancelation:",
                            options: props.cancellationReasons,
                            errMsgs: cancelError                                               
                        }}  
                    />
                    { props?.formState?.values[RejectModalConstants.TRANSACTION_CANCEL_REASONS] === `${otherReasonValue[0].value}`?
                        <>
                            <Row>
                                <Col xs={6} md={6}>
                                    <Field
                                        name={RejectModalConstants.TRANSACTION_CANCEL_OTHER_REASONS_TEXT}
                                        component={ReduxFormTextField}
                                        placeholder=""
                                        props={{
                                            maxLength: 700,
                                            className: "description-input-other-cancelation-reason",
                                            multiline: true,
                                        }}
                                    />
                                    
                                </Col>
                            </Row>
                            <Row>
                            {otherReasoncancelError.length 
                                    ? <Row>
                                        <Col  xs={12} md={12}>
                                            <Row style={{marginLeft: '7em'}}>
                                                
                                                <div className="error-message Phi-text " style={{ marginLeft: '1em' }}>{otherReasoncancelError}</div>
                                                <div className="alert-icon-after acccordion-alert-icon"></div>
                                            </Row>
                                        </Col>
                                    </Row> 
                                    : null
                                }
                            </Row>
                        </> : null
                    }
                    <Row className="button-align">
                    <Col xs={6} md={6} className="button-position-cancel">
                            <a href="#!" onClick={handleClose} className="modal-cancel">
                                Cancel
                            </a>
                        </Col>
                        <Col xs={6} md={6} className="button-position-ok">
                            <IconWithButton
                                buttonText={buttonText}
                                onButtonClick={cancelHandler}
                                isSave={true}
                                hideArrowIcon={true}
                            />
                        </Col>    
                    </Row>
                </div>
            </div>
        </Modal>
    );
}

const cancelModalReduxForm = reduxForm<{}, Props>({
    form: RejectModalConstants.REJECT_MODAL_FORM,
    validate: localValidations,
    onSubmit: submitValidations,
    enableReinitialize: true,
    touchOnChange: true
})(CancelPopup)

function mapStateToProps(state: any) {
    const formValues = state.form && state.form[RejectModalConstants.REJECT_MODAL_FORM] ?
    state.form[RejectModalConstants.REJECT_MODAL_FORM]?.values : {}
    return {
        formState: state.form && state.form[RejectModalConstants.REJECT_MODAL_FORM],
        cancellationReasons: state.dataEntryState.transactionCancellationReasons,
        transactionId: state.landingPageState?.transactionId,
        statusIndicatorID: state.dataEntryState?.transactionDetails?.transactionStatus?.id,
        initialValues: {
            ...formValues,
            [RejectModalConstants.TRANSACTION_CANCEL_REASONS]: '-9999',
            [RejectModalConstants.TRANSACTION_CANCEL_OTHER_REASONS_TEXT]: ""
        }
    }
}
function mapDispatchToProps(dispatch: Function) {
    return {
    postCancellationReasonsDispatch: (transactionId: string, id:any, cancelReason: string = '') => 
        dispatch(postCancellationReasonsActionCreators(transactionId, id, cancelReason)),
    resetRequestContext: () => dispatch(resetRequestContext())
    };
  }
export default connect<{}, {}, Props>(mapStateToProps, mapDispatchToProps)(
    cancelModalReduxForm
)
