import React, { useState, useEffect } from "react";
import { CheckboxList, Item } from "../../../common/formFields/CheckboxList";
import { Modal, Row, Col, Button } from "react-bootstrap";
import '../../../common/formFields/FormField.scss';
import DateField from '../../../common/formFields/DateField';
import Checkbox from '@material-ui/core/Checkbox';
import { _, getMomentDate } from "../../../common/utils/appUtils"
import { max, min } from 'date-fns';

interface InfoToReleaseProps {
    labels?: { id: number, name: string, code: string, comment: string }[],
    data?: any[],
    onChange?: Function,
    fromErrorMsg: string,
    toErrorMsg: string,
    errorMsg: string,
    transactionId: string,
    activeTabId: Number,
    minDate?: Date,
    maxDate?: Date
}


class recordType {
    state: boolean = false;
    id: number = 0;
    name: string = "";
    code: string = "";
    text: string = "";
}

class output {
    saveOnly: number[] = [];
    model: string = "releaseInfoItem";
    transactionId: string = "";
    fromDate: Date | null = null;
    toDate: Date | null = null;
    allDates: boolean = false;
    auditable: boolean = true;
    recordTypes: recordType[] = [];
};

function InfoToRelease(props: InfoToReleaseProps) {
    const [labels, setLabels] = useState<{ id: number, name: string, code: string }[]>([])
    const [data, setData] = useState<any[]>([])
    const [propItems, setPropItems] = useState<Item[]>([])
    const [selectItems, setSelectItems] = useState<Item[]>([])
    const [propDates, setPropDates] = useState<{ fromDate: Date | null, toDate: Date | null, allDates: boolean }>({
        fromDate: null,
        toDate: null,
        allDates: false
    });
    const [selectDates, setSelectDates] = useState<{ fromDate: Date | null, toDate: Date | null, allDates: boolean }>({
        fromDate: null,
        toDate: null,
        allDates: false
    });

    const [fromMinDate, setFromMinDate] = useState<Date | null>(null)
    const [fromMaxDate, setFromMaxDate] = useState<Date | null>(null)
    const [toMinDate, setToMinDate] = useState<Date | null>(null)
    const [toMaxDate, setToMaxDate] = useState<Date | null>(null)

    const [auditable, setAuditable] = useState(true);

    useEffect(() => { 
        const fn: Date | null = props.minDate || null;
        const tx: Date | null = props.maxDate || null;

        const fx: Date | null = propDates.toDate || tx; 
        const tn: Date | null = propDates.fromDate || fn; 

        setFromMinDate(fn);
        setFromMaxDate(fx);
        setToMinDate(tn);
        setToMaxDate(tx);
    }, [props.minDate, props.maxDate, propDates.fromDate, propDates.toDate])

    useEffect(() => {
        setAuditable(true);
    }, [props.transactionId, props.activeTabId]);

    useEffect(() => {
        if (props.labels && props.labels !== labels) {
            setLabels(props.labels);
        }
    }, [props.labels])

    useEffect(() => {
        if (props.data) {
            const newData = props.data.map(d => ({
                id: d.id,
                freeText: d.freeText,
                checked: d.checked === undefined ? (d.active === 1) : d.checked
            }))

            const from = props.data.filter(d => d.dateOfService?.from).map(d => new Date(d.dateOfService.from)).sort((a: Date, b: Date) => a < b ? -1 : (b < a ? 1 : 0))[0]
            const to = props.data.filter(d => d.dateOfService?.to).map(d => new Date(d.dateOfService.to)).sort((a: Date, b: Date) => a < b ? 1 : (b < a ? -1 : 0))[0]
            const all = props.data.some(d => d.allDates) && !from && !to;

            if (propDates.fromDate != from || propDates.toDate != to || propDates.allDates != all) {
                const newDates = {
                    fromDate: from,
                    toDate: to,
                    allDates: all
                }
                setPropDates(newDates)
                setSelectDates(newDates)
            }

            let same = newData.reduce((acc, cur) => {
                const i = data.find(p => p.ItemId == cur.id);
                return acc && i?.checked === cur.checked;
            }, true);

            if (!same)
                setData(newData);
        }
    }, [props.data])

    useEffect(() => {
        if (labels.length > 0) {
            const itemList: Item[] = [];
            labels.forEach((l, i) => {
                const item = new Item();
                item.ItemId = l.id;
                item.Labels = [l.name];
                item.State = false;
                itemList.push(item);
            });

            if (data) {
                data.forEach(d => {
                    if (d) {
                        const item = itemList.find(i => i.ItemId === d.id);
                        if (item && d.checked) {
                            item.State = true;
                            item.FreeText = d.freeText
                        }
                    }
                })
            }

            let same = itemList.reduce((acc, cur) => {
                const i = propItems.find(p => p.ItemId == cur.ItemId);
                return acc && i?.State === cur.State;
            }, true);

            if (!same) {
                setPropItems(itemList);
                setSelectItems(itemList);
                props.onChange && props.onChange(createOutput([], selectDates, itemList));
            }
        }
    }, [labels, data])

    const onChangeItems = (newSelectItems) => {
        const diff = newSelectItems.reduce((acc: number[], cur) => {
            const i = propItems.find(p => p.ItemId == cur.ItemId);
            if (cur.State === i?.State && cur.FreeText === i?.FreeText )
                return acc;
            else
                return [...acc, cur.ItemId];
        }, []);

        if (diff.length) {
            const result: output = createOutput(diff, selectDates, newSelectItems);

            setSelectItems(newSelectItems);
            props.onChange && props.onChange(result);
        }
    }

    const onChangeDates = (newSelectDates) => {
        if (newSelectDates.fromDate !== selectDates.fromDate ||
                newSelectDates.toDate !== selectDates.toDate ||
                newSelectDates.allDates !== selectDates.allDates) {

            const result:output = createOutput(selectItems.map(i => i.ItemId), newSelectDates, selectItems);

            setSelectDates(newSelectDates);
            props.onChange && props.onChange(result);
        }
    }

    const createOutput = (diff:number[], dateSet:any, itemSet:Item[]):output =>{
       return {
            saveOnly: diff,
            model: "releaseInfoItem",
            transactionId: props.transactionId,
            fromDate: dateSet.fromDate || null,
            toDate: dateSet.toDate || null,
            allDates: dateSet.allDates,
            auditable: auditable,
            recordTypes: itemSet.map(i => {
                const type: recordType = {
                    state: i.State === true,
                    id: i.ItemId,
                    name: labels.find(l => l.id === i.ItemId)?.name || "",
                    code: labels.find(l => l.id === i.ItemId)?.code || "",
                    text: i.FreeText
                }
                return type;
            })
        };
    }

    const changeFrom = (date: Date) => {
        const newDates = {
            fromDate: date,
            toDate: selectDates.toDate,
            allDates: date || selectDates.toDate ? false : selectDates.allDates
        }
        onChangeDates(newDates)
    }

    const changeTo = (date: Date) => {
        const newDates = {
            fromDate: selectDates.fromDate,
            toDate: date,
            allDates: date || selectDates.toDate ? false : selectDates.allDates
        }
        onChangeDates(newDates)
    }

    const changeAll = (state: boolean) => {
        const newDates = {
            fromDate: state ? null : selectDates.fromDate,
            toDate: state ? null : selectDates.toDate,
            allDates: state
        }
        onChangeDates(newDates)
    }



    return (
        <div id="infotorelease" style={{ marginBottom: "30px" }}>
            <div className="new-panel-header section-headers">Information to Release</div>
            <div className="new-panel-body panel-body  sensitive-information-accordion">
                <div className="sensitive-info-header"><div className="sensitive-info-question">Select records and the date range that have been requested</div></div>
                {props.errorMsg && (
                    <div className="error-message Phi-text rtr-alert-icon-after">
                        {props.errorMsg}
                    </div>
                )}
                <div className={"sensitive-Info-inner" + (props.fromErrorMsg || props.toErrorMsg || props.errorMsg ? " inputError" : "")}>
                    <Row>
                        <Col>
                            <DateField 
                                label="From" 
                                value={propDates.fromDate} 
                                placeholder="mm/dd/yyyy" 
                                errMsg={props.fromErrorMsg || ""} 
                                onChange={changeFrom} 
                                minDate={fromMinDate}
                                maxDate={fromMaxDate}
                                />
                        </Col>
                        <Col>
                            <DateField 
                                label="To" 
                                value={propDates.toDate} 
                                placeholder="mm/dd/yyyy" 
                                errMsg={props.toErrorMsg || ""} 
                                onChange={changeTo}
                                minDate={toMinDate}
                                maxDate={toMaxDate}
                                />
                        </Col>
                        <Col>
                            <Checkbox checked={propDates.allDates} onChange={e => changeAll(e.currentTarget.checked)} /> All Dates
                        </Col>
                    </Row>
                    <CheckboxList
                        items={propItems}
                        checkboxHeader="RETRIEVE"
                        labelHeaders={["RECORD TYPE"]}
                        onChange={onChangeItems}
                        className="checkboxSection"
                        hasFreeText={(item) => item && item.ItemId === 31}
                        freeTextMaxLen={2000}
                    />
                </div>
            </div>
        </div>
    )
}

export default InfoToRelease