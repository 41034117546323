import { makeStyles, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { generatePickerOptions, numericAmount } from '../utils/appUtils';
import RemoveIcon from './../../assets/images/tabImages/RemoveIcon.png';
import './CustomTable.scss';
import { connect } from 'react-redux';
import { updateAdditionalChargesProps, updateAdditionalChargesActionCreator } from '../../components/dataEntryDetails/DataEntryDetailsDucks'
import { DEFAULT_DROPDOWN_VALUE } from '../constants/ApiConstants';

interface AdditionalChargeRowsProps {
    setGrid?: Function;
    onTableRowClickHandler?: Function;
    rows: object
    headCells: object
    addNewText?: String
    defaultSortCol?: String,
    updateNewChargeRowKeys?: Array<string>,
    updateNewChargeRows?: any,
    setEnable?: boolean,
    additionalChargeTypeOption?: Array<Object>
    transactionID?: String,
    updateAdditionalChargesData?: any,
    setAdditionalChargesErr:Function
}

const StyledTable = withStyles({
    root: {
        position: 'relative',
        minWidth: '96.5% !important',
        width: "96%"
    },
})(Table)

function AddChargeHead(props) {
    const { headCells } = props;
    const tableHeadStyles = makeStyles({
        headCell: {
            borderRight: 'none !important',
        }
    })
    const classes = tableHeadStyles()
    return (
        <TableHead>
            <TableRow >
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        className={classes.headCell}
                        width={headCell.label === 'ORDER CHARGE TYPE' ? '43%' : headCell.label === 'DESCRIPTION' ? '32%' : headCell.label === 'AMOUNT' ? '10%' : '15%'}
                    >
                        {headCell.label}
                        {headCell.label === 'ORDER CHARGE TYPE' || headCell.label === 'AMOUNT' || headCell.label === 'DESCRIPTION' ? <span className="required" style={{ color: "red" }}>*</span> : <span>&nbsp;</span>}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function AddChargeRows(props) {

    const { rows, keys, setEnable } = props

    const [updatedRows, setUpdatedRows] = useState(rows)
    const [updatedRowKeys, setUpdatedRowKeys] = useState(keys)

    useEffect(() => {
        setUpdatedRows(props.rows)
        setUpdatedRowKeys(props.keys)
    }, [props.rows, props.keys])

    const tableStyles = makeStyles({
        tableHead: {
            height: 34,
            border: "none !important",
        },
        tableCell: {
            border: 'none !important',
            padding: '2px 2px !important',
            margin: '0px !important',
        }
    })
    const classes = tableStyles()

    const onBlurAmount = (key, value, additionalChargesFieldOnBlurHandler) => {
        onChangeValue({ key, fieldKey: "chargeAmount", value: parseFloat(value).toFixed(2) })
        additionalChargesFieldOnBlurHandler();
    }

    const onChangeValue = ({ key, fieldKey, value }) => {
        let errorFieldKey = fieldKey === "glCodeID" ? "chargeTypeErrMsg" : "descriptionErrMsg"
        if (fieldKey === 'chargeAmount') {
            value = numericAmount(value)
            errorFieldKey = "chargeAmountErrMsg"
        }

        let tempUpdatedRows = { ...updatedRows }
        tempUpdatedRows = {
            ...tempUpdatedRows,
            [key]: {
                ...tempUpdatedRows[key],
                [fieldKey]: value,
                [errorFieldKey]: errorFieldKey==="chargeTypeErrMsg" ? value === "-9999" ? "Required" : null : value.length > 0 ? null : "Required"
            }
        }
        setUpdatedRows(tempUpdatedRows)
        props.input && props.input.onChange(tempUpdatedRows)

        const lastKey: any = updatedRowKeys[updatedRowKeys.length - 1]
        const data = tempUpdatedRows[lastKey]
        if ((data?.chargeAmount?.length > 0 && data?.description?.length > 0 && data?.glCodeID !== "-9999" && data?.glCodeID?.length>0) || updatedRowKeys.length === 0) {
            setEnable(false)
        }
        else {
            setEnable(true)
            props.setAdditionalChargesErr && props.setAdditionalChargesErr("")
        }
    }


    const callPatchAdditionalCharge = (object) => {
        
        const requestData: any = []
        for(let row in object) {
            let rowData: any = {}
            rowData.id= object[row].id ? object[row].id : 0;
            rowData.glCodeID = object[row].glCodeID;
            rowData.description = object[row].description;
            rowData.chargeAmount = parseFloat(object[row].chargeAmount).toFixed(2);
            requestData.push(rowData)
         }
         const data = {
             transactionId: props.transactionId,
             requestData
         }
         props.updateAdditionalChargesData(data)

    }
    const removeChargeRow = (key: any, index) => {
        let rowsKeys = [...updatedRowKeys]
        let tempUpdatedRows = { ...updatedRows }
        delete tempUpdatedRows[key]
        rowsKeys.splice(index, 1)
        callPatchAdditionalCharge(tempUpdatedRows)
        setUpdatedRows(tempUpdatedRows)
        setUpdatedRowKeys(rowsKeys)
        props.input && props.input.onChange(tempUpdatedRows)
        if (rowsKeys.length ===0) {
            setEnable(false)
        }
    }

    const additionalChargesFieldOnBlurHandler = () => { 
        let patchFlag = false
        for(let row in updatedRows) {
            if(!updatedRows[row].glCodeID || !updatedRows[row].description || !updatedRows[row].chargeAmount)
                patchFlag = true
        }
        if(patchFlag === false) {
            callPatchAdditionalCharge(updatedRows) 
        }
    }
    let error=props.additionalChargesErr || ""
    return (
        <>
            <StyledTable stickyHeader size="small" className="Additional-charge-row">
                <div className='Additional-table-wrapper'>
                    <AddChargeHead
                        headCells={props.headCells}
                    />

                    <TableBody>
                        {updatedRowKeys
                            .map((key: any, index: any) => {
                                const rowData = updatedRows[key]
                                const isChargeTypeErr = rowData["chargeTypeErrMsg"] || (error.length > 0 && rowData['glCodeID'] === DEFAULT_DROPDOWN_VALUE)
                                const isDescriptionError = rowData['descriptionErrMsg'] || (error.length > 0 && rowData['description'].length === 0)
                                const isAmountError = rowData['chargeAmountErrMsg'] || (error.length > 0 && rowData['chargeAmount'].length === 0)
                                return (
                                    <TableRow key={rowData}>
                                        <TableCell width='50%' className={classes.tableCell} >
                                            <Form className={classes.tableCell}>
                                            {isChargeTypeErr  ? <span style={{paddingRight:'2.5em'}} className="alert-icon"></span>  : ""}
                                                <Form.Control
                                                    style={{ fontSize: '14px' }}
                                                    className={isChargeTypeErr ? "inputError input-field" : "input-field"}
                                                    type='text'
                                                    as='select'
                                                    value={rowData["glCodeID"]}
                                                    disabled={rowData["disabled"]}
                                                    onChange={(e) => { onChangeValue(({ key, fieldKey: "glCodeID", value: e.target.value })) }}
                                                    onBlur={additionalChargesFieldOnBlurHandler}
                                                >

                                                    {generatePickerOptions(props.additionalChargeTypeOption, "name", "id").map((option, index) => (

                                                        <option value={option.value}>{option.label}</option>
                                                    ))}

                                                </Form.Control>
                                            </Form>
                                            <Row>
                                                <Col>
                                                    {isChargeTypeErr ?
                                                        <span className="error-message">{"Required"} </span>
                                                        :
                                                        <span>&nbsp;</span>
                                                    }
                                                </Col>
                                            </Row>
                                        </TableCell>
                                        <TableCell width='25%' className={classes.tableCell}>
                                            <Form>
                                            {isDescriptionError ? <span className="alert-icon"></span>  : ""}
                                                <Form.Control
                                                    placeholder='Enter Description'
                                                    type='text'
                                                    className={isDescriptionError? "inputError input-field" : "input-field"}
                                                    disabled={rowData["disabled"]}
                                                    value={rowData['description']}
                                                    maxLength={50}
                                                    onChange={(e) => { onChangeValue(({ key, fieldKey: "description", value: e.target.value })) }}
                                                    onBlur={additionalChargesFieldOnBlurHandler}
                                                />
                                            </Form>
                                            <Row>
                                                <Col>
                                                    {isDescriptionError ?
                                                        <span className="error-message">{"Required"} </span>
                                                        :
                                                        <span>&nbsp;</span>
                                                    }
                                                </Col>
                                            </Row>
                                        </TableCell>
                                        <TableCell width='10%' className={classes.tableCell}>
                                            <Form>
                                            {isAmountError  ? <span className="alert-icon"></span>  : ""}
                                                <Form.Control
                                                    placeholder='00.00'
                                                    type='text'
                                                    className={isAmountError ? "inputError input-field" : "input-field"}
                                                    disabled={rowData["disabled"]}
                                                    value={rowData["chargeAmount"]}
                                                    maxLength={20}
                                                    onBlur={(e) => { onBlurAmount(key, e.target.value, additionalChargesFieldOnBlurHandler) }}
                                                    onChange={(e) => { onChangeValue(({ key, fieldKey: "chargeAmount", value: e.target.value })) }}
                                                />
                                            </Form>
                                            <Row>
                                                <Col>
                                                    {isAmountError ?
                                                        <span className="error-message">{"Required"} </span>
                                                        :
                                                        <span>&nbsp;</span>
                                                    }

                                                </Col>
                                            </Row>
                                        </TableCell>
                                        <TableCell width='15%' className={classes.tableCell}>
                                            <a className="upload-remove" href="#!" onClick={() => { removeChargeRow(key, index) }}  >
                                                <span className="actions remove action-upload"><img className="" src={RemoveIcon} alt="X" /></span>
                                            </a>
                                            <Row>
                                                <Col><span>&nbsp;</span></Col>
                                            </Row>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </div>
            </StyledTable>
        </>
    );
}

function mapStateToProps(state: any) {
    return {
        transactionId: state.landingPageState.transactionId,
    }
} 

function mapDispatchToProps(dispatch: Function) {
    return {
      updateAdditionalChargesData: (requestData: updateAdditionalChargesProps) =>
        dispatch(updateAdditionalChargesActionCreator(requestData)),

    };
  }

export default connect<{}, {}, AdditionalChargeRowsProps>(mapStateToProps, mapDispatchToProps)(
    AddChargeRows
  )
