import React, { Fragment } from 'react';
import { Navbar } from 'react-bootstrap';
import VerismaLogo from '../../../assets/images/headerImages/VsmaLogo.png';
import './BasicHeader.scss';

function BasicHeader() {
  return (
    <Fragment>
      <Navbar className='basicHeader'>
        <Navbar.Brand href="#">
          <img className='basicLogo loginLogo' src={VerismaLogo} alt='Logo'/>
        </Navbar.Brand>
      </Navbar>
    </Fragment>
  );
}

export default BasicHeader;
