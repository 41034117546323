import React, { useState, useEffect } from "react";
import { CheckboxList, Item } from "../../../common/formFields/CheckboxList";
import { Modal, Row, Col, Button } from "react-bootstrap";
import '../../../common/formFields/FormField.scss';

interface SensitiveInfoProps {
    labels: string[],
    data?: any,
    onChange?: Function,
    errorMsg?: string
}

function SensitiveInfo(props: SensitiveInfoProps) {
    const [labels, setLabels] = useState<string[]>([])
    const [data, setData] = useState({})
    const [items, setItems] = useState<Item[]>([])

    const [allowAll, setAllowAll] = useState(false);
    const [allowNone, setAllowNone] = useState(false);

    const [firstInput, setFirstInput] = useState(false); //means component HAS RECEIVED its first input since the transaction was created

    useEffect(() => {
        if (props.labels && props.labels !== labels)
            setLabels(props.labels);
    }, [props.labels])

    useEffect(() => {
        setFirstInput(props.data !== null);
        if(props.data){
            const same = itemsAreSame(items, props.data);
            if (!same)
                setData(props.data);
        }
    }, [props.data])

    useEffect(() => {
        if (labels.length > 0) {
            const itemList: Item[] = [];
            labels.forEach((l, i) => {
                const item = new Item();
                item.ItemId = i;
                item.Labels = [l];
                item.State = false;
                itemList.push(item);
            });

            if(data){
                const dataKeys = Object.keys(data);
                dataKeys.forEach(k => {
                    if (data[k]) {
                        const item = itemList.find(i => i.Labels[0] === k);
                        if(item)item.State = true;
                    }
                })
            }

            if (itemList.some(i => i.State)) {
                setFirstInput(true);
            }
            setButtonColor(itemList);

            const same = itemsAreSame(itemList, items);
            if (!same){
                setItems(itemList);
            }
        }
    }, [labels, data])

    useEffect(() => {
        const test = 1;
    },[items])

    const listMod = (list:any) =>{
        if (Array.isArray(list)){
            return list.reduce((acc, cur) => {
                acc[cur.Labels[0]] = cur.State;
                return acc;
            }, {})
        }else{
            return Object.keys(list).reduce((acc, cur) => {
                acc[cur] = list[cur] !== null;
                return acc;
            },{})
        }
    }

    const itemsAreSame = (list1:any[], list2:any[]) => {
        const set1 = listMod(list1);
        const set2 = listMod(list2);
        
        if (Object.keys(set1).length !== Object.keys(set2).length) { return false; }

        let union = Object.keys(set1).concat(Object.keys(set2));
        union = union.filter((v, p) => { return union.indexOf(v) == p });

        const same = union.reduce((acc, cur) => {
            return acc && set1[cur] == set2[cur];
        }, true);

        return same;
    }

    const onChange = (data:Item[]) => {
        setFirstInput(true);
        setChangeData(data)
    }

    const [changeData, setChangeData] = useState<Item[]|null>(null)

    useEffect(() => {
        if (changeData !== null){
            if (firstInput) {
                setButtonColor(changeData)
            }
            if (labels.length > 0 && !itemsAreSame(changeData, items)) {
                outputList(changeData);
            }
            setChangeData(null);
        }
    }, [changeData])

    const setButtonColor = (data: Item[]) => {
        setAllowAll(data.every(i => i.State));
        setAllowNone(firstInput && data.every(i => !i.State));
    }

    const outputList = (data:Item[], force:Boolean = false) => {
        const output = {}
        data.forEach(d => {
                output[d.Labels[0]] = d.State ? { recordType: d.Labels[0] } : null
        })
        if (firstInput || force){
            props.onChange && props.onChange(output)
        }
    }

    const clickSetAll = (state:boolean) =>{
        const newItems: Item[] = [];
        items.forEach(i => {
            const item = new Item();
            item.ItemId = i.ItemId;
            item.Labels = i.Labels;
            item.State = state;
            newItems.push(item);
        });
        outputList(newItems, true);
    }

    return (
        <div id="sensitiveinfo">
            <div className="new-panel-header section-headers">Sensitive Information</div>
            <div className="new-panel-body panel-body  sensitive-information-accordion">
                <div className="sensitive-info-header"><div className="sensitive-info-question">Review the records and check for any information on the following disease/conditions. Does the patient record contain any reference or information below:</div></div>
                {props.errorMsg && (
                    <div className="error-message Phi-text rtr-alert-icon-after">
                        {props.errorMsg}
                    </div>
                )}
                <div className={"sensitive-Info-inner" + (props.errorMsg ? " inputError" : "")}>
                    <div className="sensitiveInfoButtonContainer">
                        <button className={"btn sensitiveInfoButton allowAll" + (allowAll ? " sensInfoActive" : "")} onClick={() => clickSetAll(true)}>Authorize the disclosure of ALL sensitive information</button>
                        <button className={"btn sensitiveInfoButton allowNone" + (allowNone ? " sensInfoActive" : "")} onClick={() => clickSetAll(false)}>DO NOT authorize the disclosure of ANY sensitive information</button>
                    </div>
                    <CheckboxList
                        items={items}
                        checkboxHeader="INCLUDE"
                        labelHeaders={["CATEGORY"]}
                        onChange={onChange}
                        className="checkboxSection"
                        uncheckedX={firstInput}
                    />
                </div>
            </div>
            <div className="important-msg">
                <b>Important:</b> There is an increased risk of an inappropriate release and/or a HIPAA violation if this information is released without additional authorization or documentation.
            </div>
        </div>
    )
}

export default SensitiveInfo