import React, { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap'
import '../CustomTable.scss';
import DropdownField from '../../formFields/DropdownField';
import { LightTooltip } from '../../../common/utils/appUtils';
import crossIcon from "../../../assets/images/x_cancel_icon.png";
import checkIcon from '../../../assets/images/check_green_icon.png';
import EditIcon from "../../../assets/images/editIcons/editSmallIcon.png";
import AddNewRequestor from "../../../assets/images/tabImages/AddNewRequestor.png";


export default function TransactionNotesTable(props) {
    const [type, setTypes] = useState("1300");
    const [note, setNotes] = useState("");
    const [showNote, setShowNote] = useState(false)

    const onClickEdit = () => {
        setShowNote(!showNote);
    }

    const onChangeTypes = (e) => {
        setTypes(e.target.value);
    }
    const onChangeNotes = (e) => {
        setNotes(e.target.value);
    }

    const updateTransactionNotesToServer = () => {
        if (note && type) {
            let requestObject = {
                note: note,
                type: type,
                transactionId: props.transactionId
            }
            props.updateTransactionNotes(requestObject)
            onClickEdit()
        }
    }


    return (
        <>
            <div className="new-main" onClick={() => props.setGrid(false)}></div>
            <div>
                <Row className="margin-bottom transaction-notes-header">
                    <Col sm={9} className="pt-6 text-left col-4 transaction-notes-title" >
                        <span className="section-headers"> Transaction Notes: </span>
                    </Col>
                    <Col className="text-right transaction-notes-add" sm={3}>
                        <a
                            href="#!"
                            onClick={onClickEdit}
                        >
                            <img
                                src={AddNewRequestor}
                                alt={"Add Note"}
                                className="add-new-icon"
                            />
                            <span className="">{"Add Note"}</span>
                        </a>
                    </Col>
                </Row>
                {showNote ? <div className="newTransaction">
                    <div className="type-drop-down">
                        {console.log(props.transactionNoteTypes, "types")}
                        <DropdownField style={{ margin: 0 }}
                            required={false}
                            selectStyle={{ height: 30 }}
                            onChange={(e) => { onChangeTypes(e) }}
                            options={props.transactionNoteTypes} 
                            input={{ value: type }} 
                            meta={{}} />
                    </div>
                    <div className="note-text">
                        <Form>
                            <Form.Control
                                type="text"
                                as="textarea"
                                style={{ height: 183 }}
                                placeholder="Enter notes"
                                maxLength={1000}
                                onChange={(e) => {
                                    onChangeNotes(e)
                                }}

                            />
                        </Form>
                    </div>
                    <Row className="text-right" style={{ marginRight: "-5px" }}>
                        <Col>
                            <LightTooltip title="Cancel Notes" placement="right">
                                <span onClick={onClickEdit} onKeyDown={k => { k.key === 'Enter' && onClickEdit() }}  tabIndex={0}>
                                    <img src={crossIcon} alt={"undo save"} className="add-new-icon pointer-cursor cancel-task-icon" />
                                </span>
                            </LightTooltip>

                            <LightTooltip title="Save Notes" placement="right">
                                <span className={type && note ? "" : "addtaskstyles"} onClick={updateTransactionNotesToServer} onKeyDown={k => { k.key === 'Enter' && updateTransactionNotesToServer() }}  tabIndex={0} >
                                    <img src={checkIcon} alt={"Save Comment"} className="add-new-icon pointer-cursor save-task-icon" />
                                </span>
                            </LightTooltip>

                        </Col>
                    </Row>
                </div> : ""}

            </div>

        </>
    );
}
