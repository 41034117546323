import React, { useState, useEffect } from "react";
import './alertPopup.scss';
import { connect } from "react-redux";
import { Modal, Row, Col } from "react-bootstrap";
import IconWithButton from "../IconWithButton/IconWithButton";
import { updateShowPopupErrorActionCreator } from '../../components/dataEntryDetails/DataEntryDetailsDucks';

interface ErrorMessagePopupProps {
    header?: string,
    body?: string,
    popupErrorMessages?: string[],
    updateShowPopupError?: any
}
function ErrorMessagePopup(props: ErrorMessagePopupProps) {
    const [displayPopup, setDisplayPopup] = useState(false)
    useEffect(() => {
        if (props.popupErrorMessages == null || props.popupErrorMessages.length == 0)
            setDisplayPopup(false)
        else
            setDisplayPopup(true);
    }, [props.popupErrorMessages])

    if (displayPopup) {
        return (
            <React.Fragment>
                <div className="error-backdrop" onClick={() => props.updateShowPopupError()}>
                    <div className="api-error-popup-main">
                        <div className="alert alert-danger alert-dismissible fade show api-error-popup" role="alert" style={{ backgroundColor: '#FAEEF0', paddingRight: '17px' }}>
                            <div className="alert-icon"></div>
                            <h5 className="alert-heading">{props.header || "Errors Occurred"}
                                <span className="close-icon alert-close-icon" onClick={() => props.updateShowPopupError()}></span>
                            </h5>

                            {props.body && (<p>{props.body}</p>)}

                            <ul style={{ margin: '20px' }}>
                                {props.popupErrorMessages?.map(e => (<li className="mb-0">{e}</li>))}
                            </ul>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
    else {
        return null
    }
}


function mapStateToProps(state: any) {
    return {
        header: state.dataEntryState?.popupErrorMessageHeader,
        body: state.dataEntryState?.popupErrorMessageBody,
        popupErrorMessages: state.dataEntryState?.popupErrorMessageArray
    };
}

function mapDispatchToProps(dispatch: Function) {
    return {
        updateShowPopupError: (id: number) => dispatch(updateShowPopupErrorActionCreator(id)),
    }
}

export default connect<{}, {}, ErrorMessagePopupProps>(mapStateToProps, mapDispatchToProps)(
    ErrorMessagePopup
);