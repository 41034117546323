import React from 'react';
import { Row, Col, Form } from "react-bootstrap";
import "../../sass/main.scss";
import './FormField.scss';

interface DropdownFieldProps {
    label?: string,
    required?: boolean,
    errMsg?: string,
    onChange: Function,
    onBlur?: any,
    options: any,
    value?: any,
    input: any,
    meta: any,
    style?: any,
    selectStyle?: any,
    disabled?: boolean,
    placeholder?:string
}

function DropdownField(props: DropdownFieldProps) {
    let errMsg = props.errMsg || ""
    if (props.meta.touched && !props.meta.active) {
        if (props.meta.valid) {
        } else {
            errMsg = props.meta.error
        }
    }

    const onChange = (event) => {
        props.onChange(event, props.input.value);
    }

    return (
        <Form className="dropdown-field-input" style={props.style} onSubmit={e => { e.preventDefault(); }}>
            {props.label ? <Row>
                <Col>
                    <Form.Label>{props.label}{props.required ?
                        <span className="required" style={{ color: "red" }}>*</span> : null}</Form.Label>
                </Col>
            </Row> : null}
            <Row>
                <Col className={errMsg?.length > 0 ? "alert-icon-after dropdown-Input" : "dropdown-Input"}>
                    <Form.Control placeholder={props.placeholder} style={props.selectStyle} value={props.input.value} type="text" as="select" onBlur={props.onBlur} disabled={props.disabled}
                        onChange={(event) => onChange(event)} className={errMsg?.length > 0 ? "inputError dropdown-Input dropdown-Input-style" : "dropdown-Input dropdown-Input-style"}>
                        {props.options ? props.options.map((opt: any) => {
                            return <option className="dropdown-options-style" disabled={opt.disabled} value={opt.value.toString()} id={opt?.rid}>{opt.label}</option>
                        }) : null}

                    </Form.Control>
                </Col>
            </Row>
            {errMsg.length ?
                <Row>
                    <Col>
                        <span className="error-message">{errMsg}</span>
                    </Col>
                </Row>
                : null}
        </Form>)
}
export default DropdownField;