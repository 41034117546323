import { Row, Col, Card, Form } from "react-bootstrap";
import React, { Fragment, useState, ChangeEvent } from "react";
import ResendIcon from '../../assets/images/tabImages/ResendIcon.png'

enum CharacterLength {
  MAX_LENGTH = 50,
}

interface LoginBodyProps {
  getOtpOrLoginRequest: Function;
  getOtpAPIStatus: number;
  getResendOtpForLoginRequest: Function;
  redirectToLandingPage: Function,
  errorDescription: string
}
export function LoginBody(props: LoginBodyProps) {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [isValidEmailAddress, validateEmailAddress] = useState(true);
  const [emailValidationMsg, setEmailValidationMsg] = useState("");
  const [isValidOtp, validateOtp] = useState(true);
  const [otpValidationMsg, setOTPValidationMsg] = useState("");
  const [otpState, setOtpSent] = useState({ otpSent: false, successMsg: "" });
  const [resend, setResend] = useState(false);
  const [resendCount, setresendCount] = useState(0);
  const [resendBtnDisableState, setResendBtnDisable] = useState({
    resendBtnDisable: false,
    resendOtpValidationMsg: "",
  });
  /*get email Address which is entered */
  const onChangeEmailAddress = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setEmailValidationMsg("");
    validateEmailAddress(true);
  };

  const onEmailKeyUp = e => {
    if (e.keyCode == 13)
      onClickProceed();
  }

  const onOTPKeyUp = e => {
    if (e.keyCode == 13)
      onClickLogin();
  }

  /*get OTP which is entered*/
  const onChangeOtp = (event: ChangeEvent<HTMLInputElement>) => {
    setOtp(event.target.value);
    setOTPValidationMsg("");
    resend
      ? resendCount <= 5
        ? validateOtp(true)
        : validateOtp(false)
      : validateOtp(true);
  };

  /* validate the email Address*/
  const emailValidation = () => {
    if (!email.length) {
      validateEmailAddress(false);
      setEmailValidationMsg("Email address is required");
      return false;
    }
    return true;
  };
  /* validate email and generate OTP*/
  const onSuccessAPI = (status: number, error?: string) => {
    if (status === 200) {
      setOtpSent({ otpSent: true, successMsg: "OTP has been Sent" });
      setResend(false);
    }
    else {
      setEmailValidationMsg(error || props.errorDescription)
      setOtpSent({ otpSent: false, successMsg: "" });
      setResend(false);
    }
  }
  const onClickProceed = () => {
    const pageValid = emailValidation();
    if (pageValid) {
      props.getOtpOrLoginRequest(email, otp, onSuccessAPI);
    }
  };
  /* validate the provided OTP*/
  const otpValidation = () => {
    if (!otp.length) {
      validateOtp(false);
      setOTPValidationMsg("OTP is required");
      return false;
    }
    props.getOtpOrLoginRequest(email, otp, redirectToLandingPage);
  };
  const redirectToLandingPage = (status: any, error?: string) => {
    if (status === 200) {
      props.redirectToLandingPage();
    } else {
      setOTPValidationMsg(error || props.errorDescription)
    }
  }
  /* validate the OTP and allow login*/
  const onClickLogin = () => {
    setResendBtnDisable({
      resendBtnDisable: resendBtnDisableState["resendBtnDisable"],
      resendOtpValidationMsg: "",
    });
    emailValidation();
    otpValidation();
  };
  /*Resend the OTP requested*/
  const onClickResend = () => {
    validateOtp(true);
    const resendCnt = resendCount + 1;
    setOtp("");
    setOTPValidationMsg("");
    if (resendCnt <= 5) {
      setOtpSent({ otpSent: false, successMsg: "" });
      props.getResendOtpForLoginRequest(email, otp);
      setOtpSent({ otpSent: true, successMsg: "A new OTP has been sent." });
      setResend(true);
      setresendCount(resendCnt);
    } else {
      setResendBtnDisable({
        resendBtnDisable: true,
        resendOtpValidationMsg:
          "Maximum number of OTP retries exceeded, please contact support@verisma.com",
      });
    }
  };
  return (
    <Fragment>
      {/* login page welcome text starts here */}
      {/* login page main content starts here */}
      <Row className="mx-0">
        <Col lg={5} sm={8} xs={12} className="m-auto" id="login-container">
          <Card className="mt-3 login-wrapper">
            <span className='login-title'>{otpState.otpSent ? 'Verify' : 'Login'}</span>
            <Card.Body>
              <div>
                {otpState["otpSent"] === false ?
                  <p className="text-center">
                    We'll send you a <span className='text-center-bold'>One Time Password</span>
                  </p>
                  :
                  <p className="text-center" style={{ marginBottom: '.75rem' }}>
                    If that login exists in our system we will send you a <span style={{whiteSpace: "nowrap" }} className='text-center-bold'>One Time Password</span>
                  </p>
                }
              </div>

              {/* login form starts here */}
              <Form onSubmit={e => { e.preventDefault(); }}>
                <Form.Group as={Row} controlId="formPlaintextEmail">
                  <Col sm={8}>
                    {!isValidEmailAddress || emailValidationMsg !== "" ? <span className="alert-icon"></span> : ""}
                    {!otpState.otpSent ?
                      <Form.Control
                        maxLength={CharacterLength.MAX_LENGTH}
                        type="email"
                        placeholder="e.g. jdoe@example.com"
                        value={email}
                        onChange={onChangeEmailAddress}
                        onKeyUp={onEmailKeyUp}
                        disabled={otpState["otpSent"]}
                        autoComplete="off"
                        className={!isValidEmailAddress || emailValidationMsg !== "" ? "isValidation" : ""}
                      />
                      :
                      <p className='user-email'>{email}</p>
                    }
                    <span
                      className={
                        otpState["otpSent"] === true && isValidEmailAddress
                          ? "successMsg"
                          : "errorMsg"
                      }
                    >
                      {emailValidationMsg}
                    </span>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formPlaintextPassword" style={{ display: (otpState.otpSent) ? 'flex' : 'none' }}>
                  <Col sm={10}>
                    {!isValidOtp ? <span className="alert-icon"></span> : ""}
                    <Form.Control
                      type="text"
                      placeholder="Enter OTP"
                      value={otp}
                      onChange={onChangeOtp}
                      onKeyUp={onOTPKeyUp}
                      disabled={!otpState["otpSent"]}
                      autoComplete="off"
                      className={!isValidOtp ? "isValidation" : ""}
                    />
                    {otpState["otpSent"] === true && resendCount > 0 ?
                      <p className="text-center">
                        {otpState["successMsg"]}
                      </p> : ""
                    }
                    {otpValidationMsg.length > 0 ? (
                      <span className="errorMsg">{otpValidationMsg}</span>
                    ) : (
                      ""
                    )}
                  </Col>
                  {resendBtnDisableState["resendBtnDisable"] === true ? (
                    <span className="resendOtpMsg">
                      {resendBtnDisableState["resendOtpValidationMsg"]}
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>
                {otpState["otpSent"] === false ? (
                  <p className="text-center mt-2">
                  </p>
                ) : resendBtnDisableState["resendBtnDisable"] === false ? (
                  <p className="text-center resend-text" style={{ fontSize: '.75rem' }}>
                    Didn't receive the OTP? <span onClick={onClickResend} className='resend-link'>Resend</span>
                  </p>
                ) : (
                  ""
                )}
                {otpState["otpSent"] === false ? (
                  <div className="mt-2 text-center">
                    <button
                      type="button"
                      onClick={onClickProceed}
                      className="btn login-button"
                    >
                      <span>Get OTP</span>
                    </button>
                  </div>
                ) : (
                  <div className="mt-2 text-center">
                    <button
                      type="button"
                      onClick={onClickLogin}
                      className="btn login-button"
                    >
                      <span>Proceed</span>
                    </button>
                  </div>
                )}
                {/* Begin : used for covering unit test coverage */}
                <span id="onSuccessAPI" onClick={!otpState["otpSent"] ? e => { onSuccessAPI(200) }
                  : e => { onSuccessAPI(0) }}></span>
                <span id="redirectToLandingPage" onClick={redirectToLandingPage} ></span>
                {/* End: used for covering unit test coverage */}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}