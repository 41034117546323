import React from 'react'
import Drag_icon from '../../../assets/images/drag-icon'

export default function DragTableWrapper({requestorTable, setGrid, children}) {
    
    const [tablePosition, setTablePosition] = React.useState<{top?: any, left: any}>({top: 'auto', left: 0});
    const [dragActive, setDragActive] = React.useState(false);
    
    const moveTable = React.useCallback((e) => {
        const targetWidth = e.target.offsetWidth
        const mousePositionX = e.clientX
        let requestorTableWidth = e.target.classList.contains('draggable-table-header') ? 
            targetWidth : document.getElementById('draggable-requestor-table')?.offsetWidth
        setTablePosition({left: mousePositionX - requestorTableWidth/2, top: e.clientY - 18})
    }, [])
    
    const activateDrag = (e) => {
        const targetWidth = e.target.offsetWidth;
        const mousePositionX = e.clientX;
        setDragActive(true);
        setTablePosition({left: mousePositionX - targetWidth/2, top: e.clientY - 16})
        e.preventDefault()
        document.addEventListener('mousemove', moveTable);
    }

    const deactivateDrag = () => {
        document.removeEventListener('mousemove', moveTable);
    }
    
    if (requestorTable){
        return (
            <div className='draggable-table-container' 
            id='draggable-requestor-table' 
            style={{top: tablePosition.top, left: tablePosition.left, position: dragActive ? 'fixed' : 'absolute'}} 
            onMouseUp={deactivateDrag} 
            >
                <div className='draggable-table-header' 
                onMouseDown={activateDrag} 
                >
                    <span className='grabbable-area' >
                        <Drag_icon />
                    </span>
                    <span className='close-icon alert-close-icon close-icon-container' 
                    onMouseDown={(e)=>{e.stopPropagation()}} 
                    onClick={(e) => setGrid(false)}
                    ></span> 
                </div>
                {children}
            </div>
        )
    }
    else return <>{children}</>;
}