import React, { useRef, useState, useEffect } from 'react';
import WebViewer, { WebViewerInstance} from '@pdftron/webviewer';
import { connect } from "react-redux";

interface IpropType {
    fileData: any,
    saveTrigger: boolean,
    mergeTrigger: boolean,
    getFileData: Function,
    canSave?: Function
    license: string,
    deleteFile?: Function
}

function PdfViewer(props: IpropType) {

    const viewerDiv = useRef<HTMLDivElement | null>(null)
    const [viewerInstance, setViewerInstance] = useState<WebViewerInstance | undefined>()
    const [originalPageCount, setOriginalPageCount] = useState(0);

    const mergeFileInput = useRef<any>(null)

    const handleFileInput = (e) => {

        viewerInstance?.Core.createDocument(e.target.files[0]).then(doc => {
            const sourcePages = viewerInstance.Core.documentViewer.getDocument().getPageCount();
            viewerInstance.Core.documentViewer.getDocument().insertPages(doc, undefined, sourcePages + 1);
        })
    }

    useEffect(() => {
        if(props.saveTrigger){
            const getFileData = async () => {
                if (viewerInstance) {
                    const { documentViewer, annotationManager } = viewerInstance.Core;
                    const doc = documentViewer.getDocument();
                    const xfdfString = await annotationManager.exportAnnotations();
                    const data = await doc.getFileData({
                        // saves the document with annotations in it
                        xfdfString
                    });
                    const arr = new Uint8Array(data);
                    const blob = new Blob([arr], { type: 'application/pdf' });
                    return blob
                }
            }

            const pagesRemoved = originalPageCount - (viewerInstance?.Core.documentViewer.getPageCount() || 0);

            getFileData().then(blob => {
                props.getFileData && props.getFileData({
                    ...props.fileData,
                    blobData: blob,
                    pagesDeleted: pagesRemoved
                })
            })
        }
    },[props.saveTrigger])

    useEffect(() => {
        if(props.mergeTrigger){
            mergeFileInput.current && mergeFileInput.current.click();
        }
    }, [props.mergeTrigger])

    useEffect(() => {
        if (props.fileData?.filePath) {
            if (viewerInstance){
                const fixedPath = props.fileData.filePath.replace(/^\./,'')
                viewerInstance.UI.loadDocument(fixedPath, {extension: 'pdf'})
            }else{
                WebViewer({
                    path: 'lib/pdftron',
                    initialDoc: props.fileData.filePath,
                    extension: 'pdf',
                    licenseKey: props.license,
                    css: 'assets/css/pdfviewer.css'
                }, viewerDiv.current as HTMLDivElement).then(instance => {
                    //instance.UI.disableElements(['header'])
                    instance.UI.disableElements(['selectToolButton'])
                    
                    instance.UI.disableElements(['pageAdditionalControlsHeader'])
                    instance.UI.disableElements(['moveToTop'])
                    instance.UI.disableElements(['moveToBottom'])

                    instance.UI.disableElements(['pageInsertionHeader'])
                    instance.UI.disableElements(['insertPageAbove'])
                    instance.UI.disableElements(['insertPageBelow'])

                    instance.UI.disableElements(['pageManipulationHeader'])
                    instance.UI.disableElements(['replacePage'])
                    instance.UI.disableElements(['extractPage'])
                    instance.UI.disableElements(['deletePage'])

                    //instance.UI.disableElements(['rotateHeader'])
                    //instance.UI.disableElements(['rotateClockwiseButton'])
                    //instance.UI.disableElements(['rotateCounterClockwiseButton'])


                    instance.UI.disableElements(['viewControlsDivider1'])

                    instance.UI.disableElements(['toggleNotesButton']) 
                    instance.UI.disableElements(['toolsHeader'])
                    instance.UI.disableElements(['ribbons'])
                    instance.UI.disableElements(['leftPanelTabs'])
                    //instance.UI.disableElements(['pageManipulationOverlayButton'])
                    //instance.UI.disableElements(['thumbRotateClockwise'])
                    //instance.UI.disableElements(['contextMenuPopup'])

                    if (!props.canSave) {
                        instance.UI.disableElements(['thumbDelete'])
                    }else{
                        instance.Core.documentViewer.addEventListener('documentLoaded', () => {
                            setOriginalPageCount(instance.Core.documentViewer.getPageCount());
                            props.canSave && props.canSave(true);
                        });

                        instance.Core.documentViewer.addEventListener('documentUnloaded', () => {
                            props.canSave && props.canSave(false);
                        });

                        if (props.deleteFile){
                            instance.UI.updateElement('thumbDelete', {
                                onClick: () => {
                                    const selectedPages = instance.UI.getSelectedThumbnailPageNumbers();
                                    if(selectedPages.length == 0){
                                        selectedPages.push(1);
                                    }
                                    const doc = instance.Core.documentViewer.getDocument();
                                    
                                    props.deleteFile && props.deleteFile(
                                        selectedPages.length == instance?.Core.documentViewer.getPageCount(),
                                        () => { doc.removePages(selectedPages); }
                                    );
                                }
                            });
                        }
                    }
                    
                    setViewerInstance(instance);
                });
            }
        } else if (viewerInstance) {
            viewerInstance?.UI.dispose();

            setViewerInstance(undefined);
        }

    }, [props.fileData?.filePath])

    return (
        <>
            <div className='webviewer' ref={viewerDiv} />
            <div className="file-uploader" style={{ "display": "none" }}>
                <input type="file" accept='application/pdf'  ref={mergeFileInput} onChange={handleFileInput} />
            </div>
        </>
    );
}

function mapStateToProps(state: any, props: any) {
    return {
        license: state.loginState.featureFlags.ptlk,
    }
}

export function mapDispatchToProps(dispatch: Function) {
}

export default connect(mapStateToProps, mapDispatchToProps)(PdfViewer);
