const DATA_ENTRY_FIELDS_FORM = "DATA_ENTRY_FIELDS_FORM",
    READ_ONLY_FIELDS_FORM = "READ_ONLY_FIELDS_FORM",
    REJECT_MODAL_FORM = "REJECT_MODAL_FORM",
    REJECT_MODAL_DESCRIPTION = "REJECT_MODAL_DESCRIPTION",
    REQUEST_TYPE = "Request type",
    TRANSACTION_CANCEL_REASONS = "Transaction cancellation reasons",
    TRANSACTION_CANCEL_OTHER_REASONS_TEXT = "Transaction cancellation other reason text",
    PURPOSE_TYPE = "Purpose type",
    PATIENT_NAME = "Patient name",
    PATIENT_MIDDLE_NAME = "Middle name",
    PATIENT_LAST_NAME = "Last name",
    DOB = "DOB",
    MRN_ID = "MRN id",
    REQUEST_ID = "Request id",
    EMPI_NUMBER = "Empi number",
    AUTHORIZATION_DATE = "Authorization date",
    REQUEST_DATE = "Request date",
    RECEIVED_DATE = "Recieved date",
    DUE_DATE = "Due date",
    CLINIC_RESPONISBLE = "Clininc responsible",
    CASE_NUMBER = "Case number",
    SITE_CODE = 'Site code',
    DISCLOSE_ALL_INFO = "disclose all sensitive information",
    DONT_DISCLOSE_ANY_INFO = "do not disclose any sensitive information",
    DISCLOSURE_CHANGED = "DISCLOSURE_CHANGED",
    SEARCH_LAST_NAME = 'Last Name/MRN',
    SEARCH_DOB = 'Date of Birth',
    REJECT_INCLUDE_AUTH_FORM = 'Include auth forms',
    REJECT_REASON_IDS = 'reject reason ids',
    AUDIT_TYPE = 'Audit Type',
    ON_BEHALF_OF = 'On Behalf Of',
    WAIT_HOLD_REASONS = 'wait and hold reasons',
    WAIT_HOLD_MODAL_FORM = 'WAIT_HOLD_MODAL_FORM',

    //requestor tab fields
    PHONE_FAX_NUMBER = 'Phone/Fax number',
    EMAIL_ADDRESS = 'email address',
    REQUESTOR_NAME = 'requestor name',
    ADDRESS = 'address',
    ADDRESS2 = "address2",
    CITY = 'city',
    STATE = 'state',
    ZIP = 'zip',
    FIRST_NAME = 'first name',
    LAST_NAME = 'last name',
    EMAIL = 'email',
    REJECT_MODAL_EMAIL = "reject modal email",
    PHONE_NUMBER = 'phone number',
    FAX_NUMBER = 'fax number',
    //for advance search
    SEARCH_REQUESTOR_NAME = 'search requestor name',
    SEARCH_ADDRESS = 'search address',
    SEARCH_ZIP = 'search zip',
    //DDS Fields
    RQID = 'RQID',
    TX_SITE_CODE = 'TX_SITE_CODE',
    RQ_SITE_CODE = 'RQ_SITE_CODE',
    SSN = 'SSN',
    DOC_TYPE = 'DOC_TYPE',
    ROUTING_FIELD = 'ROUTING_FIELD',
    DOC_RETURN_CODE = 'DOC_RETURN_CODE',
    NO_BARCODE = 'NO_BARCODE',
    INTENRNAL_REQUESTOR = 'INTENRNAL_REQUESTOR',
    REQUESTOR_CONTACT = 'REQUESTOR_CONTACT',

    /* accordion components fields */
    DISCLOSURE_REASON = 'disclosure reason',
    DISCLOSURE_DATE = 'disclosure date',
    SPECIAL_NOTES = 'special notes',
    PAGES_DISCLOSED = 'number of pages disclosed',
    PROTOCOL_NAME = 'protocol name',
    PROTOCOL_NUMBER = 'protocol number',
    PHI = "PHI",
    RTR_FROM = 'records to retrieve from',
    RTR_TO = 'records to retrieve to',
    RTR_ALL = 'records to retrieve all',
    RETRIEVE_RECORD_TYPE = 'retrieve record type',
    RELEASE_INFO_COMMENTS = 'release info comments',
    SENSITIVE_INFORMATION = 'sensitive information',
    GENETIC_INFORMATION = 'Genetic Information',
    HIV = 'HIV/Aids',
    MENTAL_HEALTH = 'Mental Health',
    SUBSTANCE_ABUSE = 'Substance Abuse',
    STD = 'Sexually Transmitted Disease',
    COMMENTS_AND_ACTIONS = 'Comments and actions',
    ENTER_COMMENTS_FIELD = 'Enter comments here',
    DUE_BY = 'Due by',
    SUBJECT = 'Subject',
    TASK_DESCRIPTION = "Task Description",
    AUTHORIZATION_VERIFICATION = "AUTHORIZATION_VERIFICATION",

    /* BILLING FORM CONSTANTS*/
    FEE_SCHEDULES = 'fee schedules',
    RELEASE_METHODS = 'release methods',
    SHIPPING_SERVICES = 'shipping services',
    DELIVERY_INSTRUCTIONS = 'delivery instrucations',
    BILLING_TYPES = 'billing types',
    RELEASE_METHOD_CHARGE = 'release method charge',
    CHARGE_FOR_POSTAGE = 'charge for postage',
    AUXILIARY_CHARGE = 'auxiliary charge',
    CERTIFICATION_REQUESTED = 'certification requested',
    REQUIRES_PREPAYMENT = 'requires prepayment',
    SAME_AS_SEND_TO = 'same as send to',
    ATTENTION_TO = 'attention to',
    SEND_TO_REQUESTOR_NAME = 'send to requestor name',
    SEND_TO_FIRST_NAME = 'send to first name',
    SEND_TO_LAST_NAME = 'send to last name',
    SEND_TO_ADDRESS_LINE1 = 'send to address line1',
    SEND_TO_ADDRESS_LINE2 = 'send to address line2',
    SEND_TO_CITY = 'send to city',
    SEND_TO_STATE = 'send to state',
    SEND_TO_ZIP = 'send to zipCode',
    SEND_TO_PHONE = 'send to phone',
    SEND_TO_FAX = 'send to FAX',
    SEND_TO_EMAIL_ADDRESS = 'send to email-address',

    BILL_TO_REQUESTOR_NAME = 'bill to requestor name',
    BILL_TO_FIRST_NAME = 'bill to first name',
    BILL_TO_LAST_NAME = 'bill to last name',
    BILL_TO_ADDRESS_LINE1 = 'bill to address line1',
    BILL_TO_ADDRESS_LINE2 = 'bill to address line2',
    BILL_TO_CITY = 'bill to city',
    BILL_TO_STATE = 'bill to state',
    BILL_TO_ZIP = 'bill to zipCode',
    BILL_TO_PHONE = 'bill to phone',
    BILL_TO_FAX = 'bill to FAX',
    BILL_TO_EMAIL_ADDRESS = 'bill to email-address',
    ADDITIONAL_CHARGES = 'additionalCharges',

    /*reject Modal*/
    NOTIFICATION_METHOD_OVERRIDES = 'Notification Method Overrides',
    PRINTER = 'Printer',
    SELECT_PHRASE = 'Select a Phrase',

    /**Records Section */
    MEDICAL_RECORDS_FROM = "From",
    MEDICAL_RECORDS_TO = "To",
    MEDICAL_ACCOUNT_NUMBER = "Account number",
    /**Header search */
    TRANSACTION = "TRANSACTION#",
    /*copy transaction Modal*/
    SEARCH_TRANSACTION = 'Transaction #',
    COPY_TRANSACTION_MODAL_FORM = 'Copy Transaction Form',
    SEARCH_CONTACT_NAME = 'search contact name',
    DIGITAL_FAX = 'digital fax'



export const RequestorFormConstants = {
    PHONE_FAX_NUMBER,
    EMAIL_ADDRESS,
    REQUESTOR_NAME,
    ADDRESS,
    ADDRESS2,
    CITY,
    STATE,
    ZIP,
    FIRST_NAME,
    LAST_NAME,
    EMAIL,
    PHONE_NUMBER,
    FAX_NUMBER,
    DOB,
    TRANSACTION,
    SEARCH_REQUESTOR_NAME,
    SEARCH_ADDRESS,
    SEARCH_ZIP,
    AUTHORIZATION_VERIFICATION,
    RQID,
    TX_SITE_CODE,
    RQ_SITE_CODE,
    SSN,
    DOC_TYPE,
    ROUTING_FIELD,
    DOC_RETURN_CODE,
    NO_BARCODE,
    INTENRNAL_REQUESTOR,
    SEARCH_CONTACT_NAME,
    REQUESTOR_CONTACT

}

export const BillingFormconstants = {
    FEE_SCHEDULES,
    BILLING_TYPES,
    SHIPPING_SERVICES,
    RELEASE_METHODS,
    DELIVERY_INSTRUCTIONS,
    AUXILIARY_CHARGE,
    CERTIFICATION_REQUESTED,
    REQUIRES_PREPAYMENT,
    CHARGE_FOR_POSTAGE,
    RELEASE_METHOD_CHARGE,
    SAME_AS_SEND_TO,
    ATTENTION_TO,
    SEND_TO_REQUESTOR_NAME,
    SEND_TO_FIRST_NAME,
    SEND_TO_LAST_NAME,
    SEND_TO_ADDRESS_LINE1,
    SEND_TO_ADDRESS_LINE2,
    SEND_TO_CITY,
    SEND_TO_STATE,
    SEND_TO_ZIP,
    SEND_TO_PHONE,
    SEND_TO_FAX,
    SEND_TO_EMAIL_ADDRESS,
    ADDITIONAL_CHARGES,

    BILL_TO_REQUESTOR_NAME,
    BILL_TO_FIRST_NAME,
    BILL_TO_LAST_NAME,
    BILL_TO_ADDRESS_LINE1,
    BILL_TO_ADDRESS_LINE2,
    BILL_TO_CITY,
    BILL_TO_STATE,
    BILL_TO_ZIP,
    BILL_TO_PHONE,
    BILL_TO_FAX,
    BILL_TO_EMAIL_ADDRESS,
    DIGITAL_FAX
},

    FormConstants = {
        DATA_ENTRY_FIELDS_FORM,
        READ_ONLY_FIELDS_FORM,
        REQUEST_TYPE,
        PURPOSE_TYPE,
        PATIENT_MIDDLE_NAME,
        PATIENT_LAST_NAME,
        PATIENT_NAME,
        DOB,
        MRN_ID,
        REQUEST_ID,
        EMPI_NUMBER,
        AUTHORIZATION_DATE,
        REQUEST_DATE,
        RECEIVED_DATE,
        DUE_DATE,
        CLINIC_RESPONISBLE,
        CASE_NUMBER,
        SITE_CODE,
        SEARCH_LAST_NAME,
        SEARCH_DOB,
        DISCLOSURE_REASON,
        DISCLOSURE_DATE,
        SPECIAL_NOTES,
        PAGES_DISCLOSED,
        PROTOCOL_NAME,
        PROTOCOL_NUMBER,
        PHI,
        NOTIFICATION_METHOD_OVERRIDES,
        PRINTER,
        RTR_FROM,
        RTR_TO,
        RTR_ALL,
        RETRIEVE_RECORD_TYPE,
        RELEASE_INFO_COMMENTS,
        SENSITIVE_INFORMATION,
        GENETIC_INFORMATION,
        HIV,
        MENTAL_HEALTH,
        SUBSTANCE_ABUSE,
        STD,
        DISCLOSE_ALL_INFO,
        DONT_DISCLOSE_ANY_INFO,
        DISCLOSURE_CHANGED,
        COMMENTS_AND_ACTIONS,
        ENTER_COMMENTS_FIELD,
        DUE_BY,
        SUBJECT,
        TASK_DESCRIPTION,
        MEDICAL_RECORDS_FROM,
        MEDICAL_RECORDS_TO,
        MEDICAL_ACCOUNT_NUMBER,
        AUDIT_TYPE,
        ON_BEHALF_OF
    },

    RejectModalConstants = {
        SELECT_PHRASE,
        REJECT_MODAL_FORM,
        REJECT_MODAL_DESCRIPTION,
        REJECT_MODAL_EMAIL,
        PHONE_FAX_NUMBER,
        TRANSACTION_CANCEL_REASONS,
        TRANSACTION_CANCEL_OTHER_REASONS_TEXT,
        REJECT_INCLUDE_AUTH_FORM,
        REJECT_REASON_IDS

    },
    CopyTransactionModalConstants = {
        SEARCH_TRANSACTION,
        COPY_TRANSACTION_MODAL_FORM
    },
    WaitAndHoldConstants = {
        WAIT_HOLD_REASONS,
        WAIT_HOLD_MODAL_FORM,
    }
export default FormConstants