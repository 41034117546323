//Constants

import { call, put } from 'redux-saga/effects'
import Api from '../../services/Api';
import { putRequest } from '../../services/Network';
import { API_FAILURE, API_FETCHING, API_INITIAL, API_SUCCESS, isSuccessStatusCode } from "../../common/constants/ApiConstants"
import { getUserAttributeAPI, requestOtpOrLoginAPI, logoutUserAPI, getFeatureFlagsAPI } from "../dataEntryDetails/test/DataEntry.APIs"
export const LoginConstants = {
    getOtpOrLoginRequest: "getOtpOrLoginRequest/LoginPageConstants",
    getOtpOrLoginSuccess: "getOtpOrLoginSuccess/LoginPageConstants",
    getOtpOrLoginFailure: "getOtpOrLoginFailure/LoginPageConstants",
    getUserAttributesRequest: "getUserAttributesRequest/LoginPageConstants",
    getUserAttributesSuccess: "getUserAttributesSuccess/LoginPageConstants",
    getUserAttributesFailure: "getUserAttributesFailure/LoginPageConstants",
    getResendOtpForLoginRequest: "getResendOtpForLoginRequest/LoginPageConstants",
    logoutUserRequest: "logoutUserRequest/LoginPageConstants",
    logoutUserSuccess: "logoutUseSuccess/LoginPageConstants",
    logoutUserFailure: "logoutUseFailure/LoginPageConstants",
    resetReduxState: "RESET_STATE",
    putHeartbeatRequest: "putHeartbeatRequest",
    putHeartbeatFailure: "putHeartbeatFailure",
    getFeatureFlagsRequest: "getFeatureFlagsRequest/LoginPageConstants",
    getFeatureFlagsSuccess: "getFeatureFlagsSuccess/LoginPageConstants",
    getFeatureFlagsFailure: "getFeatureFlagsFailure/LoginPageConstants"
}
// create reducers here
var userid = sessionStorage.getItem('userID');
var userAttributes = sessionStorage.getItem('userAttributes');
const defaultState = {
    getOtpAPIStatus: API_INITIAL,
    loginAPIStatus: API_INITIAL,
    errorMessage: null,
    getAttributesAPIStatus: API_INITIAL,
    userAttributes: userAttributes !== null ? JSON.parse(userAttributes) : null,
    userID: userid !== null ? JSON.parse(userid) : null,
    errorDescription: "",
    getFeatureFlagsAPIStatus: API_INITIAL,
    featureFlags: {}
}

export const loginState = (state = defaultState, action: any) => {
    const key = action.data && action.data.otp && action.data.otp.length ? "loginAPIStatus" : "getOtpAPIStatus"
    switch (action.type) {
        case LoginConstants.getOtpOrLoginRequest:
            return {
                ...state,
                [key]: API_FETCHING
            }
        case LoginConstants.getOtpOrLoginSuccess:
            global.userID = action.data.userID;
            sessionStorage.setItem("userID", JSON.stringify(action.data.userID))

            return {
                ...state,
                [key]: action.data.status,
                userID: action.data.userID,
                errorDescription: action.data.errorDescription
            }
        case LoginConstants.getResendOtpForLoginRequest:
            return {
                ...state,
                [key]: API_SUCCESS,
            }
        case LoginConstants.getOtpOrLoginFailure:
            return {
                ...state,
                [key]: API_FAILURE,
                errorMessage: action.data
            }
        case LoginConstants.getUserAttributesRequest:
            return {
                ...state,
                getAttributesAPIStatus: API_FETCHING
            }
        case LoginConstants.getUserAttributesSuccess:
            sessionStorage.setItem("userAttributes", JSON.stringify(action.data))
            return {
                ...state,
                userAttributes: action.data,
                getAttributesAPIStatus: API_SUCCESS
            }
        case LoginConstants.getUserAttributesFailure:
            return {
                ...state,
                getAttributesAPIStatus: API_FAILURE
            }
        case LoginConstants.logoutUserRequest:
            return {
                ...state,
                getAttributesAPIStatus: API_FETCHING
            }
        case LoginConstants.logoutUserSuccess:
            sessionStorage.clear();
            sessionStorage.removeItem("userID");
            sessionStorage.removeItem("userAttributes");
            return {
                ...defaultState,
                getAttributesAPIStatus: API_SUCCESS
            }
        case LoginConstants.logoutUserFailure:
            return {
                ...state,
                getAttributesAPIStatus: API_FAILURE
            }
        case LoginConstants.putHeartbeatFailure:
            return {
                ...state,
                getAttributesAPIStatus: API_FAILURE
            }
        case LoginConstants.getFeatureFlagsRequest:
            return {
                ...state,
                getFeatureFlagsAPIStatus: API_FETCHING
            }
        case LoginConstants.getFeatureFlagsSuccess:
            return {
                ...state,
                featureFlags: action.data,
                getFeatureFlagsAPIStatus: API_SUCCESS
            }
        case LoginConstants.getFeatureFlagsFailure:
            return {
                ...state,
                getFeatureFlagsAPIStatus: API_FAILURE
            }
        default:
            return state
    }
}

// create action creators here

export function resetState() {
    return {
        type: LoginConstants.resetReduxState
    }
}

export function getOtpOrLoginRequest(userName: string, otp?: string, onAPISuccess?: Function) {
    return {
        type: LoginConstants.getOtpOrLoginRequest,
        userName,
        otp,
        onAPISuccess
    }
}

export function logoutUserRequest(action) {
    return {
        type: LoginConstants.logoutUserRequest,
        userId: action.userId,
        redirect: action.redirect
    }
}

export function getUserAttributesRequest() {
    return {
        type: LoginConstants.getUserAttributesRequest
    }
}

export const putHeartbeatRequest = () => {
    return {
        type: LoginConstants.putHeartbeatRequest,
        data: {}
    }
}

export function getFeatureFlagsRequest() {
    return {
        type: LoginConstants.getFeatureFlagsRequest
    }
}


// create sagas here

// GET OTP OR LOGIN
export function* getOtpOrLogin(action: any) {
    let requestObject = {
        userName: action.userName,
        otp: action.otp,
        resendOtp: action.resendOtp
    }
    try {
        const response = yield call(requestOtpOrLoginAPI, requestObject)
        if (isSuccessStatusCode(response.status)) {
            yield put({
                type: LoginConstants.getOtpOrLoginSuccess,
                data: {
                    ...response.data, userName: action.userName,
                    getOtpAPIStatus: response.status, status: API_SUCCESS, userID: response.data
                }
            })
            action.onAPISuccess && action.onAPISuccess(response.status, "")
        }
    } catch (err) {
        if (err.response?.status === 400) {
            action.onAPISuccess && action.onAPISuccess(err.response?.status, err.response?.data[0]?.message)
        } else {
            action.onAPISuccess && action.onAPISuccess(err.response?.status, err.response?.data)
        }
        yield put({
            type: LoginConstants.getOtpOrLoginFailure,
            data: err
        })
    }
}

//takes in userID
export function* logoutUsers(action: any) {
    let requestObject = {
        userId: action.userId,
    }
    try {
        const response = yield call(logoutUserAPI, requestObject)
        const heartbeatID = parseInt(sessionStorage.getItem("heartbeatID") || "", 10);
        sessionStorage.clear();
        if (isSuccessStatusCode(response.status)) {
            action.redirect()
            yield put({
                type: LoginConstants.logoutUserSuccess,
                data: {
                    ...response.data,
                    getOtpAPIStatus: response.status, status: API_SUCCESS
                }
            })
        }
    } catch (err) {
        yield put({
            type: LoginConstants.logoutUserFailure,
            data: err
        })
    }
}


export async function callHeartbeatSaga() {
    return await putRequest(Api.putHeartbeat, {})
        .then(resp => {
            console.log(resp);
            return resp;
        })
        .catch(err => {
            put({ type: LoginConstants.putHeartbeatFailure, data: err.response });
            return { status: err.response.status }
        })
}


// GET USER ATTRIBUTES
export function* getUserAttributes() {
    try {
        const response = yield call(getUserAttributeAPI, {})
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: LoginConstants.getUserAttributesSuccess, data: response.data })
        }
    } catch (err) {
        yield put({ type: LoginConstants.getUserAttributesFailure, data: err })
    }
}
/* Get Resend OTP */
export function getResendOtpForLoginRequest(userName: string, otp?: string) {
    return {
        type: LoginConstants.getResendOtpForLoginRequest,
        userName,
        otp
    }
}

export function* getFeatureFlags() {
    try {
        const response = yield call(getFeatureFlagsAPI, {})
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: LoginConstants.getFeatureFlagsSuccess, data: response.data })
        }
    } catch (err) {
        yield put({ type: LoginConstants.getFeatureFlagsFailure, data: err })
    }
}