import React, { useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles, makeStyles } from '@material-ui/core';
import './CustomTable.scss';
import { _, getMomentDate } from "../utils/appUtils"
import { connect } from 'react-redux';
import { updateBillingPaneFields, saveInformationToReleaseActionCreator } from "../../components/dataEntryDetails/DataEntryDetailsDucks";
import FormConstants from '../../components/dataEntryDetails/dataEntryComponents/DataEntryFieldsConstants';

interface AccordionTableProps {
    setGrid?: Function;
    onTableRowClickHandler?: Function;
    rows: any
    headCells: any
    addNewText?: String
    defaultSortCol?: String,
    meta: any
    setRtrError?: Function,
    discloseAll: any,
    input?: any,
    changeSensitiveInfoFieldValue: Function,
    transactionDetails?: any,
    updateBillingPaneFields?: Function,
    transactionId?: any,
    releaseInfoFromDate?: any,
    releaseInfoToDate?: any,
    saveInformationToRelease?: Function,
    allDatesChecked?: any,
    dontDisclose?: any,
    updateValueInDb?: Function,
    auditable?: boolean,
    setAuditable?: Function
}

const StyledTable = withStyles({
    root: {
        position: 'relative',
        maxWidth: "90.5% ",
        minWidth: '90.5% !important'
    },
    icon: {
        color: 'red'
    }
})(Table)

export function AccordionTableHead(props) {

    const { headCells } = props;


    const tableHeadStyles = makeStyles({
        headCell: {
            borderRight: '0px !important',
        }
    })

    const classes = tableHeadStyles()
    return (
        <TableHead >
            <TableRow >
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        className={classes.headCell}
                        style={{
                            width: headCell.label === "RETRIEVE" || headCell.label === "INCLUDE" ? '11%' : headCell.label === "IS REFERENCED?" ? '25%' : '50%',
                            textAlign: 'left'
                        }}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function AccordionTableRTR(props: AccordionTableProps) {
    const { updateValueInDb } = props
    const onClickRow = (row: any) => {
        if (props.input) {
            let active = 1
            let currentValue = { ...props.input.value }
            if (currentValue[row.recordType]) {
                active = 0
                delete currentValue[row.recordType]
            } else {
                currentValue = {
                    ...props.input.value,
                    [row.recordType]: {
                        recordType: row.recordType,
                    }
                }
            }
            let requestObject = {
                transactionId: props.transactionId,
                active,
                id: row.id,
                fromDate: props.releaseInfoFromDate ? getMomentDate(props.releaseInfoFromDate) : "",
                toDate: props.releaseInfoToDate ? getMomentDate(props.releaseInfoToDate) : "",
                auditable: props.auditable === null ? null : props.auditable
            }

            if (props.allDatesChecked || (props.releaseInfoFromDate && props.releaseInfoToDate)) {
                props.saveInformationToRelease && props.saveInformationToRelease(requestObject)
                if (props.auditable !== null)
                    props.setAuditable && props.setAuditable(false);
            }
            props.input.onChange(currentValue)
        }
    }


    const onClickSensitiveRow = (row: any) => {
        let currentValue = { ...props.input.value }
        updateValueInDb && updateValueInDb(row)
        if (currentValue[row.category]) {
            delete currentValue[row.category]
            props.changeSensitiveInfoFieldValue(false)
        } else {
            currentValue = {
                ...props.input.value,
                [row.category]: {
                    recordType: row.category,
                }
            }
        }
        if (Object.keys(currentValue).length === props.rows.length) {
            props.changeSensitiveInfoFieldValue(true)
        }
        props.input.onChange(currentValue)
    }





    useEffect(() => {
        if (global.userID && props.input?.name === FormConstants.SENSITIVE_INFORMATION && props.transactionId) {
            if (props.dontDisclose) {
                props.rows?.map((row, key) => {
                    updateValueInDb && updateValueInDb(row, "0")
                })
                props.input && props.input.onChange({})
            }
        }
    }, [props.dontDisclose])


    const tableStyles = makeStyles({
        tableHead: {
            height: 34,
        },
        tableCell: {
            borderRight: '0px !important',
            lineHeight: '14px',
            paddingLeft: '15px !important'
        }
    })

    const classes = tableStyles()
    let fieldValue = props.input ? { ...props.input.value } : {}
    return (
        <>
            <StyledTable stickyHeader size="small" className="Accordion-table">
                <div >
                    <AccordionTableHead
                        headCells={props.headCells}
                    />
                    <TableBody>
                        {(props.rows)?.map((row, index) => {
                            return (

                                <TableRow key={row[index]}  >
                                    {props.headCells.map((headCell) => {
                                        var headCellType = row[headCell.id]
                                        if (typeof headCellType === "object") {
                                            if (headCell.id === "include") {
                                                headCellType = React.cloneElement(headCellType,
                                                    {
                                                        checked: !_.isNil(fieldValue[row.category]),
                                                        onClick: () => onClickSensitiveRow(row)
                                                    })
                                            }
                                            if (headCell.id === 'retrieve') {
                                                headCellType = React.cloneElement(headCellType, { checked: !_.isNil(fieldValue[row.recordType]), onClick: () => onClickRow(row) })
                                            }
                                        }
                                        return (
                                            <TableCell size='small' className={classes.tableCell}>{headCellType}</TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        }
                        )}
                    </TableBody>
                </div>
                <caption className="add-new-row">
                </caption>
            </StyledTable>
        </>
    );
}


function mapStateToProps(state: any) {
    const transactionDetails = state.dataEntryState?.transactionDetails
    const transactionId = state.landingPageState?.transactionId
    return {
        transactionDetails,
        transactionId
    };
}

export function mapDispatchToProps(dispatch: Function) {
    return {
        updateBillingPaneFields: (data: any) => {
            return dispatch(updateBillingPaneFields(data))
        },
        saveInformationToRelease: (data: any) => {
            return dispatch(saveInformationToReleaseActionCreator(data))
        }
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AccordionTableRTR);