import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { withStyles, makeStyles } from '@material-ui/core';
import './CustomTable.scss';
import { Row, Col } from 'react-bootstrap';
import {_} from "../utils/appUtils"
import { connect } from 'react-redux';
import { updatePHIDisclosed } from '../../components/dataEntryDetails/DataEntryDetailsDucks';
import { constants } from 'perf_hooks';

interface AccordionTableProps {
    setGrid?: Function;
    onTableRowClickHandler?: Function;
    rows?: object
    headCells: any
    addNewText?: String
    defaultSortCol?: String,
    meta: any,
    input:any,
    transactionId?:string,
    updatePHIDisclosed?:Function
    
}

export function descendingComparator(a, b, orderBy, searchTerms = null) {
    const localTerms = searchTerms // supress object is possibly null error

    if(orderBy === "default" && localTerms !== null){
        let aCounter = 0;
        let bCounter = 0;

        aCounter += a['national']? 1 : 0;
        aCounter += a['eTransfer']? 2 : 0;
        aCounter += a['email'] === localTerms['email']? 6 : 0; 
        aCounter += a['phone'] === localTerms['phone']? 6 : 0;
        aCounter += a['requestorAddress']['address1'] === localTerms['address']? 6 : 0; 
        aCounter += a['requestorAddress']['zip'] === localTerms['zip']? 6 : 0;  

        bCounter += b['national']? 1 : 0;
        bCounter += b['eTransfer']? 2 : 0;
        bCounter += b['email'] === localTerms['email']? 6 : 0; 
        bCounter += b['phone'] === localTerms['phone']? 6 : 0;
        bCounter += b['requestorAddress']['address1'] === localTerms['address']? 6 : 0; 
        bCounter += b['requestorAddress']['zip'] === localTerms['zip']? 6 : 0;  



        if (bCounter < aCounter) {
            return -1;
        }
        if (bCounter > aCounter) {
            return 1;
        }
        return 0;
    }
    if(orderBy === "en"){
        let aCounter = 0;
        let bCounter = 0;

        aCounter += a['national']? 1 : 0;
        aCounter += a['eTransfer']? 2 : 0;

        bCounter += b['national']? 1 : 0;
        bCounter += b['eTransfer']? 2 : 0;

        if (bCounter < aCounter) {
            return -1;
        }
        if (bCounter > aCounter) {
            return 1;
        }
        return 0;

    }else{
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
  
}

export function getComparator(order, orderBy, searchTerms = null) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy, searchTerms)
        : (a, b) => -descendingComparator(a, b, orderBy, searchTerms);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const StyledTable = withStyles({
    root: {
        position: 'relative',
        maxWidth: "90.5% ",
        minWidth: '90.5% !important'
    },
    icon: {
        color: 'red'
    }
})(Table)

export function AccordionTableHead(props ) {

    const { order, orderBy, onRequestSort, headCells } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    const tableHeadStyles = makeStyles({
        headCell: {
            borderRight: '0px !important',
            lineHeight: '14px ',
            padding:'0 !important'

        }
    })

    const classes = tableHeadStyles()
    return (
        <TableHead >
            <TableRow >
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.headCell}
                        style={{ width: headCell.label === "DESCRIPTION" ? '72%' :  headCell.label === "NOTES" ? '50%' :  headCell.label === "TYPE" ? '30%' : headCell.label === "DATE" ? '20%' : '11%'  , 
                                 textAlign: 'left' }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function AccordionTable(props : AccordionTableProps) {

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState(props.defaultSortCol);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const onClickRow = (row: any) => {
        if (props.input) {
            let currentValue = { ...props.input.value }
            if (currentValue[row.requestedItemID]) {
                delete currentValue[row.requestedItemID]
                props.updatePHIDisclosed && props.updatePHIDisclosed(props.transactionId,row.requestedItemID,true)

            } else {
                currentValue = {
                    ...props.input.value,
                    [row.requestedItemID]: {
                        code: row.code,
                        description: row.description
                    }
                }
            props.updatePHIDisclosed && props.updatePHIDisclosed(props.transactionId,row.requestedItemID,false)

            }
            props.input.onChange(currentValue)
        }
    }

    const tableStyles = makeStyles({
        tableHead: {
            height: 34,
        },
        tableCell: {
            borderRight: '0px !important',
            lineHeight: '14px',
            paddingLeft: '15px !important'
        }
    })

    const classes = tableStyles()
    let error = ""
    if (props.meta.touched && !props.meta.active) {
        if (props.meta.valid) {
        } else {
            error = props.meta.error
        }
    }

    let fieldValue = props.input ? {...props.input.value} : {}

    return (
        <>
            
            <span className='Phi-text'>Select all the PHI codes included in the disclosure</span>
            {error.length 
                ? <Row>
                    <Col >
                        <Col className="alert-icon-after acccordion-alert-icon"></Col>
                        <Col className="error-message Phi-text " style={{ marginLeft: '1em' }}>{error}</Col>
                    </Col>
                </Row> 
                : null
            }
            <StyledTable stickyHeader size="small" className={error?.length > 0 ? "inputError Accordion-table " : "Accordion-table"}>

                <div className='Accordion-table-wrapper' style={{ maxHeight: "40vh" }}>
                    <AccordionTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={props.headCells}
                    />
                    <TableBody>
                        {stableSort(props.rows, getComparator(order, orderBy))
                            .map((row, index) => {
                                return (
                                    <TableRow key={row[index]}  >
                                        {props.headCells.map((headCell) => {
                                            var headCellType = row[headCell.id]
                                            if (typeof headCellType === "object") {
                                                headCellType = React.cloneElement(headCellType,  {checked:  !_.isNil(fieldValue[row.requestedItemID]) , onClick: () => onClickRow(row) })
                                            }
                                            return (
                                                <TableCell size='small' className={classes.tableCell}>{headCellType}</TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            }
                            )}
                    </TableBody>
                </div>
                <caption className={error?.length > 0 ? "inputError add-new-row" : "add-new-row"} >
                </caption>

            </StyledTable>
        </>
    );
}

function mapStateToProps(state: any) {
    const transactionId= state.landingPageState?.transactionId
    return {
      transactionId
    };
  }

  function mapDispatchToProps(dispatch: Function) {
    return {
        updatePHIDisclosed: (transactionId: string , requestedItemID:string , isDelete:boolean) => {
        return dispatch(updatePHIDisclosed(transactionId , requestedItemID , isDelete))
      }
    };
  }
export default connect(mapStateToProps, mapDispatchToProps)(AccordionTable);

