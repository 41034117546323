import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import {store, persistor, history} from './redux/index';
import 'bootstrap/dist/css/bootstrap.css';
import * as serviceWorker from './serviceWorker';
import './global'


const RouteApp = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App history={history} />
    </PersistGate>
  </Provider>
);

ReactDOM.render(
  <React.StrictMode>
    <RouteApp />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();