import React from 'react';
import { Card, Figure } from 'react-bootstrap';
import RecordValidationLogo from '../../assets/images/tabImages/RecordValidationSVG.svg';

interface RecordAttachmentProps {
  recordValidation: number
}
function RecordValidation(props: RecordAttachmentProps) {
  return (
    <Card className="cardBorderStyle">
      <Card.Body className='border-none'>
        <Figure className='mb-0'>
          <Figure.Image
            src={RecordValidationLogo}
            alt='RecordValidationLogo'
            className='mr-2 float-left'
          />
          <Figure.Caption className='figure-caption-custom mt-1'>
            Record Validation
          </Figure.Caption>
        </Figure>
        <span className='card-data'>
          <span className="totalRecordCount">{props.recordValidation}</span>
        </span>
      </Card.Body>
    </Card>
  );
}
export default RecordValidation;
