import { Checkbox } from '@material-ui/core';
import React from 'react';
import { Col, Form, Row } from "react-bootstrap";
import "../../sass/main.scss";
import './FormField.scss';


interface CheckboxFieldProps {
    fieldlabel: string,
    placeholder?: string,
    required?: boolean,
    errMsg: string,
    onChange: Function
    value?: any
    showError?: boolean,
    disabled?: boolean,
    checkboxLabel?: string,
    onCheck?: any,
    defaultChecked?: boolean,
}

function CheckboxField(props: CheckboxFieldProps) {

    const { fieldlabel, checkboxLabel, required, errMsg = "", value, defaultChecked } = props

    return (
        <Form className="checkbox-field-input" onSubmit={e => { e.preventDefault(); }}>
            {fieldlabel && <Row>
                <Col>
                    <Form.Label >{fieldlabel}{required ?
                        <span className="required" style={{ color: "red" }}>*</span> : null}</Form.Label>
                </Col>
            </Row>}
            <Row>
                <Col>
                    <div className="checkbox-field">
                        {errMsg.length > 0 ? <span className="alert-icon  alert-icon-align"></span> : ''}
                        <Checkbox color='primary'
                            value={value}
                            typeof='checkbox'
                            onChange={value => props.onChange(value)}
                            className={checkboxLabel === "Authorize the disclosure of ALL sensitive information" ? "checkbox-className authorizeAllSensitive" : "checkbox-className"}
                            defaultChecked={defaultChecked}
                            checked={value}
                            data-testid={`checkbox-1234`}
                        />
                        <Form.Label >{checkboxLabel}</Form.Label>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    {props.errMsg.length > 0
                        ? <span className="error-message">{props.errMsg}</span>
                        : <span>&nbsp;</span>
                    }
                </Col>
            </Row>
        </Form>
    )
}

export default CheckboxField;