import React, {useEffect, useState} from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import NewRequestIcon from '../../assets/images/tabImages/NewRequestIcon.png';
import { FacilitiesCountsModal, OrganizationDetailsModal } from '../../components/dataEntryDetails/dataEntryComponents/DataEntryDetails';
import './TabComponents.scss';

interface Props {
  hltOrgTransactionsData: OrganizationDetailsModal,
  onClickFacility: Function,
  uploadDocument: Function,
  createTransaction: Function,
  createdTransactionDetails: any,
  updateFacilityDeatils?:Function,
  getFacilityDiscloseInstitutionsRequest?:Function,
  createTransactionAPIStatus: number,
  clearUserLocks?: Function,
  changeRequestContext: Function,
  searchTerm: string
}

function Facility(props: Props) {
  const [clickOnNew, setClickOnNew] = useState(false)
  const [facilitiesCounts, setFacilitiesCounts] = useState<FacilitiesCountsModal[]>([]);

  const showModel = (facilityDetails?:any, facilityId?:string) => {
    if(facilityDetails && facilityId){
      props.updateFacilityDeatils && props.updateFacilityDeatils(facilityDetails)
      props.createTransaction && props.createTransaction({ facilityId: facilityId.toString()})
      props.clearUserLocks && props.clearUserLocks()
    }
  };

  const nextTransaction = (context, facility) =>{
    props.changeRequestContext(context);
    if(!clickOnNew) props.onClickFacility(facility);
  }

  useEffect(() => {
    setFacilitiesCounts(props && props.hltOrgTransactionsData ? sortedFacilities(props.hltOrgTransactionsData).facilityCounts : [])
  }, [props.hltOrgTransactionsData])


  const sortedFacilities = (orgDetails: OrganizationDetailsModal): OrganizationDetailsModal => {

    if (props.searchTerm === '' || !orgDetails.facilityCounts.some(hs =>
      hs.facility.name.toLowerCase().includes(props.searchTerm) || hs.facility.id.toString().includes(props.searchTerm)
    )) {

      return props.hltOrgTransactionsData;
    } else {
      const org: OrganizationDetailsModal = {
        model: orgDetails.model,
        healthSystem: orgDetails.healthSystem,
        transactionCounts: orgDetails.transactionCounts,
        facilityCounts: [...orgDetails.facilityCounts]
      };
      org.facilityCounts.sort((a, b) => {
        let a_fac_starts = a.facility.name.toLowerCase().startsWith(props.searchTerm) || a.facility.id.toString().startsWith(props.searchTerm);
        let b_fac_starts = b.facility.name.toLowerCase().startsWith(props.searchTerm) || b.facility.id.toString().startsWith(props.searchTerm);

        if (a_fac_starts && !b_fac_starts)
          return -1;
        else if (!a_fac_starts && b_fac_starts)
          return 1;

        let a_fac_includes = a.facility.name.toLowerCase().includes(props.searchTerm) || a.facility.id.toString().includes(props.searchTerm);
        let b_fac_includes = b.facility.name.toLowerCase().includes(props.searchTerm) || b.facility.id.toString().includes(props.searchTerm);
        if (a_fac_includes && !b_fac_includes)
          return -1;
        else if (!a_fac_includes && b_fac_includes)
          return 1;

        return 0;
      });


      return org;
    }

  }

  const isContextDisabled = (count) =>{
    return count > 0? "": "-disabled";
  }
  return (
    <div className="facility-wrapper">
      {facilitiesCounts.map((facility: FacilitiesCountsModal, index: number) => (
        <Card key={index} className="facility-card mb-0 px-0" >
          <Card.Header className="facility-card-header-custom px-3" onClick={() => nextTransaction(1,facility)}>
            <Row style={{justifyContent:'space-between'}}>
              <div className="facility-card-name-and-number" onMouseEnter={() => setClickOnNew(false)}>
                <div className='facility-name'>
                  <span
                    className="buttonText"
                  >
                    {facility.facility?.name}
                  </span>
                </div>
                <div className='facility-id-container'>
                  <span
                    className="buttonText facility-id"
                    onClick={() => props.onClickFacility(facility)}
                  >
                    {facility.facility?.id}
                  </span>
                </div>
              </div>
              <div className='newRequestLink' onMouseEnter={() => setClickOnNew(true)}>
                <a href="#!" className="newRequest" onClick={()=>clickOnNew? showModel(facility, facility.facility?.id.toString()): null}>
                  <img
                    src={NewRequestIcon}
                    alt="New Request"
                    className="newRequestIcon"
                  />
                  New Request
                </a>
                
              </div>
            </Row>
          </Card.Header>
          <Card.Body onMouseEnter={() => setClickOnNew(false)}>
            <Card.Text>
              <Row>
                <Col className="text-right">

                  <span className={"totalCount" + isContextDisabled(facility.transactionCounts.dataEntry)} onClick={() => facility.transactionCounts.dataEntry > 0? nextTransaction(2,facility): null}>
                    {facility.transactionCounts.dataEntry}
                  </span>
                </Col>
                <Col className="text-right">

                  <span className={"totalCount" + isContextDisabled(facility.transactionCounts.recordAttachment)} onClick={() => facility.transactionCounts.recordAttachment > 0? nextTransaction(3,facility): null}>
                    {facility.transactionCounts.recordAttachment}
                  </span>
                </Col>
                <Col className="text-right">

                    <span className={"totalCount" + isContextDisabled(facility.transactionCounts.recordValidation)} onClick={() => facility.transactionCounts.recordValidation > 0? nextTransaction(4,facility): null}>
                      {facility.transactionCounts.recordValidation}
                    </span>
                </Col>
                <Col className="text-right">

                    <span className={"totalCount" + isContextDisabled(facility.transactionCounts.userTasks)} onClick={() => facility.transactionCounts.userTasks > 0?nextTransaction(5,facility): null} >
                      {facility.transactionCounts.userTasks}
                    </span>
                </Col>
              </Row>
            </Card.Text>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
}

export default Facility;
