import React, { useEffect } from 'react';
import toast from "react-hot-toast";
import './UploadProgressBars.scss';

interface IpropType {
    uploadProgress: any,
    uploadDocumentFailure: any,
    setUploadProgress: Function
}

function UploadProgressBars(props: IpropType) {

    const uploadToast = (fileKey, errorMsg?) => {

        return <div>
            <div className='upload-toast-file'>{props.uploadProgress[fileKey].name}</div>
            <div className='upload-toast-progress'>
                {errorMsg ?
                    <>
                        <div>{errorMsg}</div>
                        <div><a className='upload-toast-close' onClick={() => {
                            toast.dismiss(fileKey);
                        }}>Close</a></div>
                    </> :
                    <>
                        <div>{props.uploadProgress[fileKey].loaded + "%"}</div>
                        {props.uploadProgress[fileKey].loaded === 100 ?
                            <div className='upload-toast-bar-complete'>&nbsp;</div> :
                            <div className='upload-toast-bar-loading'>
                                <div className='upload-toast-bar-inner' style={{ width: props.uploadProgress[fileKey].loaded + "%" }}>&nbsp;</div>
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    }



    useEffect(() => {
        if (props.uploadProgress && Object.keys(props.uploadProgress).length) {
            Object.keys(props.uploadProgress).forEach(f => {
                if (props.uploadProgress[f].loaded === 100) {
                    toast.success(uploadToast(f), { id: f, className: "upload-toast", duration: 5000 })
                } else {
                    toast.loading(uploadToast(f), { id: f, className: "upload-toast", duration: Infinity })
                }
            })
        }
    }, [props.uploadProgress])

    useEffect(() => {
        if (props.uploadDocumentFailure) {
            toast.error(uploadToast(props.uploadDocumentFailure.fileID, props.uploadDocumentFailure?.error), { id: props.uploadDocumentFailure?.fileID, className: "upload-toast", duration: Infinity })

            props.setUploadProgress(prev => {
                const { [props.uploadDocumentFailure.fileID]: _, ...ret } = prev;
                return ret;
            })
        }
    }, [props.uploadDocumentFailure])

    return <></>
}

export default UploadProgressBars;