import React, { Fragment, FunctionComponent, useEffect, useState,ChangeEvent } from "react";
import { connect } from "react-redux";
import './SpotlightDetailsModal.scss';
import {
  Modal,
  Row,
  Col,
  Nav,
  Navbar
} from "react-bootstrap";
import NormalTable from '../../common/table/NormalTable/NormalTable';
import CloseModal from "../../assets/images/close_modal_icon.png";
import infoIcon from "../../assets/images/Info_icon.png";
import {resetRequestContext } from '../../components/landingPage/LandingPageDucks'
import {getTransactionSearchResultsReset, headerAssignTransactionActionHeader} from "../../components/landingPage/LandingPageDucks";
import { onChangeDataEntryTab, resetDataEntryState} from '../../components/dataEntryDetails/DataEntryDetailsDucks';

interface Props {
  show: Function,
  handleClose: any,
  tableData: any,
  spotlightText: any,
  setSearchGrid?:Boolean,
  onSearchTableRowClickHandler?: Function,
  resetRequestContext?: Function,
  getTransactionSearchResultsReset?: Function,
  assignSearchTransactionID?: any,
  unassignSearchTransactionID?: any,
  onChangeDataEntryTab?: Function,
  resetDataEntryState?: Function
}
const SpotlightDetails: FunctionComponent<Props> = (props) =>  {
  const [showSearchGrid, setSearchGrid] = useState(props.setSearchGrid);
  const headCellsSearch: { id: string, label: string }[] = [
    { id: 'transaction', label: 'TRANSACTION#' },
    { id: 'lastName', label: 'LAST NAME' },
    { id: 'firstName', label: 'FIRST NAME' },
    { id: 'dob', label: 'DOB' },
    { id: 'requestorName', label: 'REQUESTOR NAME' },
    { id: 'status', label: 'STATUS' },
    { id: 'receivedDate', label: 'RECEIVED DATE' },
    { id: 'dueDate', label: 'DUE DATE' },
    { id: 'reuestPurpose', label: 'REQUEST TYPE' },
    { id: 'transactionHoldReason', label: 'ONHOLD REASON' },
    { id: 'delayLetterDate', label: 'DELAY LETTER SENT' },
    { id: 'taskName', label: 'TASK TITLE' },
    { id: 'taskAssignedTo', label: 'TASK ASSIGNED TO' },
    { id: 'taskDueDate', label: 'TASK DUE DATE' }
  ];
  const onSearchTableRowClickHandler = (rowData: any) => {
    setTimeout(() => {
      props.assignSearchTransactionID && props.assignSearchTransactionID(rowData.transaction, rowData.facilityId)
    }, 1000)
    props.resetDataEntryState && props.resetDataEntryState();
    props.onChangeDataEntryTab && props.onChangeDataEntryTab(1);

    props.resetRequestContext && props.resetRequestContext();
    props.getTransactionSearchResultsReset && props.getTransactionSearchResultsReset();
    props.handleClose();
  }
  return (
    <Modal className="model-dimension-spotlight" show={true} hasBackdrop={false} backdrop="static"
    animation={false}>
      <Modal.Title className="new-request-header">
      <img
          src={infoIcon}
          alt={"info Icon"}
        />
        <span style={{ marginLeft: "5px" }}>{props.spotlightText}</span>
        <img
          src={CloseModal}
          alt={"Close Modal"}
          style={{ float: "right", marginRight: "16px", marginTop: "5px" }}
          onClick={e => props.handleClose(e)}
        />
        </Modal.Title>
      <div className="newRequest-content">
     <Row>
        <Col>
        <NormalTable
            onTableRowClickHandler={onSearchTableRowClickHandler}
            setGrid={setSearchGrid}
            rows={props.tableData}
            headCells={headCellsSearch}
            addNewText=""
            defaultSortCol="patientName"
            cssName="table-backdrop-spotlight"
            searchTablecss="search-table-spotlight"
            headerSearch={false}
            //transactionSearchResultsStatus={props.transactionSearchResultsStatus}
            />
        </Col>
     </Row>
      </div>
      <Row className="save-cancel-row">
        <Col className="mb-4 button-position">

        </Col>
      </Row>
    </Modal>
  );
}
export function mapDispatchToProps(dispatch: Function) {
  return {
    getTransactionSearchResultsReset: () => dispatch(getTransactionSearchResultsReset()),
    resetRequestContext: () => dispatch(resetRequestContext()),
    assignSearchTransactionID: (transactionId: string, facilityId: string) => dispatch(headerAssignTransactionActionHeader(transactionId, facilityId)),
    onChangeDataEntryTab: (data: number) => dispatch(onChangeDataEntryTab(data, false)),
    resetDataEntryState: () => dispatch(resetDataEntryState()),
  };
}

export default connect(null, mapDispatchToProps)(SpotlightDetails);
