import React from 'react';
import './TabComponents.scss';
import { Card, Figure } from 'react-bootstrap';
import tasks_icon from '../../assets/images/tabImages/tasks_icon.png';

interface MyTaskProps {
  dataEntry: number
}

function MyTasks(props: MyTaskProps) {
  return (
    <Card className="cardBorderStyle">
      <Card.Body className='border-none'>
        <Figure className='mb-0'>
          <Figure.Image
            src={tasks_icon}
            alt='DataEntryLogo'
            className='mr-2 float-left'
          />
          <Figure.Caption className='figure-caption-custom mt-1 data-entryCaption'>
            My Tasks
        </Figure.Caption>
        </Figure>
        <span className='card-data'>
          <span className="totalRecordCount">{props.dataEntry}</span>
        </span>
      </Card.Body>
    </Card>
  );
}
export default MyTasks;
