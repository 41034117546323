import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useState} from "react";
import { connect } from 'react-redux'
import { Accordion, Col, Modal, Row, Form } from "react-bootstrap";
import CloseModal from "../../../../src/assets/images/close_modal_icon.png";
import ReduxFormDropdownField from "../../reduxFormFields/ReduxFormDropDownField";
import { reduxForm, Field } from 'redux-form';
import { RejectModalConstants } from '../../../components/dataEntryDetails/dataEntryComponents/DataEntryFieldsConstants'
import infoIcon from "../../../../src/assets/images/Info_icon.png";
import IconWithButton from "../../IconWithButton/IconWithButton";
import "../rejectModal/RejectModal.scss";
import localValidations from '../../../components/dataEntryDetails/dataEntryComponents/validations/localValidations'
import ReduxFormTextField from "../../reduxFormFields/ReduxFormTextField";
import { API_SUCCESS, DEFAULT_DROPDOWN_VALUE } from "../../constants/ApiConstants";
import { Radio } from "@material-ui/core";
import {
  pickerHelperFunction
} from "../../../common/utils/appUtils";

interface IProps {
    fields: any,
    patientName: string,
    templateId: any,
    handleSubmit: Function,
    handleChangeReason: Function,
    logoUrlStatusCode?: any,
    logoUrl?: any,
    phone?: string
}



export function DelayModal(props: IProps){
    let curDate = new Date();
    const[rName, setRName] = useState(props.fields.length > 0 ? props.fields.find(x => x.pdfName == 'RName'): {});
    const[rCity, setRCity] = useState(props.fields.length > 0 ? props.fields.find(x => x.pdfName == 'RCity'): {});
    const[rState, setRState] = useState(props.fields.length > 0 ? props.fields.find(x => x.pdfName == 'RState'): {});
    const[rZipCode, setRZipCode] = useState(props.fields.length > 0 ? props.fields.find(x => x.pdfName == 'RZipCode'): {});
    const[rAddress1, setRAddress1] = useState(props.fields.length > 0 ? props.fields.find(x => x.pdfName == 'RAddress1'): {});
    const[rAddress2, setRAddress2] = useState(props.fields.length > 0 ? props.fields.find(x => x.pdfName == 'RAddress2'): {});
    const[rFullName, setRFullName] = useState(props.fields.length > 0 ? props.fields.find(x => x.pdfName == 'RFullName'): {});
    const[orgName, setOrgName] = useState(props.fields.length > 0 ? props.fields.find(x => x.pdfName == 'OrgName'): {});
    const[accessCode, setAccessCode] = useState(props.fields.length > 0 ? props.fields.find(x => x.pdfName == 'AccessCode'): {});
    const[caseNumber, setCaseNumber] = useState(props.fields.length > 0 ? props.fields.find(x => x.pdfName == 'CaseNumber'): {});
    const[delayReason, setDelayReason] = useState(props.fields.length > 0 ? props.fields.find(x => x.pdfName == 'delayReason'): []);
    const[invoiceNumber, setInvoiceNumber] = useState(props.fields.length > 0 ? props.fields.find(x => x.pdfName == 'txId'): []);

    const [selectedReasonPhrase, setSelectedReasonPhrase] = useState("")

    function setUpDelayResasonDropDrop(){
        let newarr = [{index: DEFAULT_DROPDOWN_VALUE, dropDownName: "Select Reason"}]
        delayReason?.phraseDropDowns.forEach((x, i) => {
            newarr.push({
                ...x,
                index: i
            })
        });

        return pickerHelperFunction(newarr, "dropDownName", "index")
    }

    function onChangeDropDown(value: any){
        if(value === DEFAULT_DROPDOWN_VALUE){
            setSelectedReasonPhrase("")
            return
        }
        let phrase = delayReason?.phraseDropDowns[value]?.dropDownValue
        setSelectedReasonPhrase(phrase)
        props.handleChangeReason(phrase)
        return
    }

    return(
        <>
            <div className="reject-logo-section">
                <div className="reject-main">
                    <Row>
                        <Col>
                            <div className='modal-text-style-requestor'>
                                <div>Requestor Name:<br/></div>
                                <div>&emsp;{rName?.userValue}<br /></div>
                                &emsp;{rFullName?.userValue? rFullName?.userValue: ""}
                                <div>Requestor Address:<br/></div>
                                <div>&emsp;{rAddress1?.userValue}<br /></div>
                                {rAddress2?.userValue? <div>&emsp;{rAddress2?.userValue}<br /></div>: <div></div>}
                                &emsp;{rCity?.userValue + " " + rState?.userValue + " " + rZipCode?.userValue}
                            </div>
                            <div className='modal-text-style-date'>
                              {props.logoUrlStatusCode == API_SUCCESS? <div className="reject-logo-left-section">
                                  <img src={props.logoUrl} alt="logo"></img>
                                </div>: null}    
                                Date:{` ${curDate.getMonth() + 1}/${curDate.getDate()}/${curDate.getFullYear()}`}<br />
                                <br />
                                Invoice #: <span>{invoiceNumber?.userValue.substring(3)}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <Row className="reject-descriptive-section">
              <Col>
                
                <span >
                  Patient name: {props.patientName} <br />
                  Case #: <span>{caseNumber?.userValue}<br /></span> 
                  Medical Provider: <span>{orgName?.userValue}<br /></span>
                  <br />
                  <br />
                </span>
                <p>
                    Dear Medical Records Requestor:

                    Verisma Systems, Inc. has contracted with the {props.fields.medicalProvider} noted above to provide HIPAA compliance 
                    review and distribute medical records on its behalf. This letter is to inform you about the progress regarding your 
                    request for medical records. We understand that under 45 CFR 164.524(b)(2), that we are required to notify you of a 
                    delay that could exceed 30 days.

                </p>
              </Col>
            </Row> 
            <Row className="phrase-section">
              <Col>
                <label className="phrase-section-one">Select a Phrase</label>
                <div className="phrase-dropdown">
                  <Field
                    name={"Delay Reason"}
                    component={ReduxFormDropdownField}
                    props={{
                      required: false,
                      options: setUpDelayResasonDropDrop(),
                      onChange: onChangeDropDown
                    }}
                  />
                </div>

               
                <Row>
                  <Col>
                    <p style={{marginTop: "10px"}}>{selectedReasonPhrase}</p>
                  </Col>
                </Row>
                <div style={{ fontSize: "9px" }}>
                  STATEMENT OF CONFIDENTIALITY
                  <br />
                  THIS INFORMATION IS INTENDED FOR THE EXCLUSIVE USE OF THE
                  ADDRESSEE AND MAY CONTAIN CONFEDENTIAL OR PRIVILEGED
                  INFORMATION.IF YOU ARE NOT THE
                  INTENDED RECIPIENT, YOU ARE HEREBY NOTIFIED THAT ANY RETENTION,
                  COPYING, DISSEMINATION, OR USE OF THIS COMMUNICATION IS STRICTLY
                  PROHIBITED. IF THIS WAS SENT IN ERROR, PLEASE NOTIFY ROIS App
                  Health Service Org, AT {props.phone} AND DESTROY THIS COMMUNICATION
                  AND ANY ATTACHMENTS.
                </div>
              </Col>
            </Row>
        </>
    );
}