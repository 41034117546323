import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import IconWithButton from "../IconWithButton/IconWithButton";
import './alertPopup.scss';


interface Props {
    setDeleteTaskPopUp?: any,
    getConfirmation?: any
}

function DeleteTaskPopUp(props: Props) {

    const {setDeleteTaskPopUp, getConfirmation} = props


    const onclickCancelhandler = () => {
        setDeleteTaskPopUp(false)
        getConfirmation('Cancel')
    }

    const onClickDeleteHandler = () => {
        setDeleteTaskPopUp(false)
        getConfirmation('OK')
    }

    return (
        <div>
            <Modal className="cancel-dimension delete-task-modal" show={true}>
                <div className="alert-container">
                    <Modal.Title className="cancel-header">
                        <span className="info-icon"></span>
                        <div className="cancel-header-text">Delete task</div>
                    </Modal.Title>
                    <div className="newRequest-content">
                        <div className="delete-confirmation-text"><p>Are you sure you want to delete this task?</p></div>
                        <Row className="button-align">
                            <Col xs={6} md={6} className="button-position-cancel">
                                <a href="#!" onClick={onclickCancelhandler} className="modal-cancel">
                                    Cancel
                                </a>
                            </Col>
                            <Col xs={6} md={6} className="button-position-ok">
                                <IconWithButton
                                    buttonText={"Yes"}
                                    onButtonClick={onClickDeleteHandler}
                                    isSave={true}
                                    hideArrowIcon={true}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default DeleteTaskPopUp;