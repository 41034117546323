import React, { useEffect } from 'react';
import { Row, Col, Form } from "react-bootstrap";
import "../../sass/main.scss";
import './FormField.scss';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core';
import { INVALID_DATE } from '../constants/ApiConstants';
import { _, mirrorData } from '../../common/utils/appUtils'

interface DateFieldProps {
    label: any,
    placeholder: string,
    required?: boolean,
    maxDate?: any,
    minDate?: any,
    errMsg: string,
    onChange: any
    value?: any
    showError?: boolean,
    disabled?: boolean,
    className?: any,
    name?: any,
    onBlur?: any,
    onChangeVal?: any,
    onChangeRTRDateRange?: Function,
    onKeyDown?: Function,
    labelSameLine?: boolean
}

function DateField(props: DateFieldProps) {

    const {
        label,
        name,
        placeholder,
        required,
        maxDate,
        minDate,
        errMsg = "",
        onChange,
        value,
        onKeyDown
    } = props


    const useStyle = makeStyles(theme => ({
        root: {
            '& .MuiInput-root': {
                border: "1px solid #CC5967 !important"
            }
        }

    }))


    const classes = useStyle()

    const onChangeDate = (date) => {
        if (props.onChangeVal) {
            props.onChangeVal(date, props.name);
        }
        if (date && date.getTime && !isNaN(date.getTime())) {
            onChange(date)
            props.onChangeRTRDateRange && props.onChangeRTRDateRange(props.name, date)
            mirrorData(name, date)
        } else if (_.isNil(date)) {
            onChange(null)
        }
    }

    useEffect(() => {
        const val = props.value;
    }, [props.value])

    const onBlur = (e) => {
        if (e.target.value === ""){
            onChange(null);
            return;
        }
        let date = e.target.value
        date = new Date(date)

        if(isNaN(date)){
            onChange(INVALID_DATE)
        }else if(props.onBlur){
            props.onBlur(e);
        }else{
            let patientMinDOB = new Date();
            patientMinDOB.setFullYear(patientMinDOB.getFullYear() - 120);
            let futureDate = new Date();
            futureDate.setDate(futureDate.getDate());
            if (date.getTime() > futureDate.getTime()) {
            } else if (date.getTime() < patientMinDOB.getTime()) {
            } else if (_.isNil(date)) {
                mirrorData(name, null)
            } else if (date && !isNaN(date.getTime())) {
                mirrorData(name, date)
            } else {
                mirrorData(name, null)
            }
        }
    }

    const onKeyup = (e) => {
        if (e.key === "T" || e.key === "t") {
            var d = new Date();
            d.setHours(0);
            d.setMinutes(0);
            d.setSeconds(0);
            d.setMilliseconds(0);
            onChangeDate(d);
        }
    }


    const labelBlock = () => {
        return <Form.Label >{label}{required ?
            <span className="required" style={{ color: "red" }}>*</span> : null}</Form.Label>
    }

    const pickerBlock = () => {
        return (<>
                {errMsg.length > 0 ? <span className="alert-icon  alert-icon-align"></span> : ''}
                <MuiPickersUtilsProvider utils={DateFnsUtils}  >
                    <KeyboardDatePicker
                        className={errMsg.length > 0 ? classes.root : props.disabled ? 'date-field-disable date-field-style' : 'date-field-style'}
                        onBlur={onBlur}
                        fullWidth
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        value={value && value !== INVALID_DATE ? value : null}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        allowKeyboardControl={true}
                        autoOk={true}
                        maxDate={maxDate && maxDate.setHours ? maxDate.setHours(0, 0, 0, 0) : undefined}
                        minDate={minDate && minDate.setHours ? minDate.setHours(0, 0, 0, 0) : undefined}
                        placeholder={placeholder}
                        {...(errMsg && { error: true, helperText: errMsg })}
                        type="text"
                        onChange={date => onChangeDate(date)}
                        onKeyUp={key => onKeyup(key)}
                        onKeyDown={e => onKeyDown && onKeyDown(e)}
                        disabled={props.disabled}
                    />
                </MuiPickersUtilsProvider>
            </>)
    }

    const errorBlock = () => {
        return (<span className="error-message">{props.errMsg}</span>)
    }

    const labelSameLine = () => {
        return (<Form className={`date-field-input ${props.className}`} onSubmit={e => { e.preventDefault(); }}>
            <Row>
                <Col>{label ? 
                 labelBlock() 
             : null}
                { pickerBlock() }
                </Col>
            </Row>
            {props.errMsg.length > 0 ?
                <Row>
                    <Col>{ errorBlock() }</Col>
                </Row> : null}
        </Form>)
    }

    const labelSeparateLine = () => {
        return (<Form className={`date-field-input ${props.className}`} onSubmit={e => { e.preventDefault(); }}>
            {label ? <Row>
                <Col>{ labelBlock() }</Col>
            </Row> : null}
            <Row>
                <Col>{ pickerBlock() }</Col>
            </Row>
            {props.errMsg.length > 0 ?
                <Row>
                    <Col >{ errorBlock() }</Col>
                </Row> : null}
        </Form>)
    }

    return (
        props.labelSameLine === true ? labelSameLine() : labelSeparateLine()
    )
}

export default DateField;