import React, { useState } from 'react';
import './ButtonWithDropdown.scss';
import {
  DropdownButton, Dropdown
} from "react-bootstrap";
interface ButtonWithDropdownProps {
  onSelect: any,
  title: any,
  isAttachment?: boolean,
  isAddFile?: boolean,
  items: any,
  style?: any,
  drop?: any
}
function ButtonWithDropdown(props: ButtonWithDropdownProps) {
  const handleSelect = (e) => {
    props.onSelect && props.onSelect(e)
  }

  return (
    <div className="mt-2">
      <DropdownButton className="" style={{ ...props.style, whiteSpace: 'nowrap' }}
        title={props.title}
        id="dropdown-basic"
        drop={props.drop}
      >
        {props.items.map((item, i) =>
        (typeof item === 'string' ?
          (<Dropdown.Item key={i} eventKey={item} onClick={() => handleSelect(item)}>
            {item}
          </Dropdown.Item>) :
          item.divider ?
          (<Dropdown.Divider />) :
          item.optgroup ?
          (<Dropdown.Header>{item.optgroup}</Dropdown.Header>) :
          (<Dropdown.Item key={i} eventKey={item} id={item.id} onClick={() => handleSelect(item)}>
            {item.title}
          </Dropdown.Item>))

        )}
      </DropdownButton>
    </div>
  )
}

export default ButtonWithDropdown