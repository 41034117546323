/* eslint-disable no-use-before-define */
import React from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import NoSsr from '@material-ui/core/NoSsr';
import CheckIcon from '@material-ui/icons/Check';
import styled from 'styled-components';
import CloseIcon from '../../assets/images/tabImages/cancelButton.png'
const Label = styled('label')`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
`;

const InputWrapper = styled('div')`
  width: 100%;
  border: 1px solid #BFD8E8;
  background-color: #fff;
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;
 
  &.focused {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
 
  & input {
    font-size: 14px;
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;

const Tag = styled(({ label, onDelete, ...props }) => (
  <div {...props}>
    <span style={{ paddingRight: "14px" }}>{label}</span>
    <img src={CloseIcon} alt="Close Icon" onClick={onDelete} />
  </div>
))`
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: #E2E3E8;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;
 
  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }
 
  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
 
  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`;

const Listbox = styled('ul')`
  width: 88%;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
 
  & li {
    padding: 5px 12px;
    display: flex;
 
    & span {
      flex-grow: 1;
    }
 
    & svg {
      color: transparent;
    }
  }
 
  & li[aria-selected='true'] {
    background-color: #bfdeff;
    font-weight: 600;
 
    & svg {
      color: #1890ff;
    }
  }
 
  & li[data-focus='true'] {
    background-color: #1e8fff;
    cursor: pointer;
 
    & svg {
      color: #000;
    }
  }
`;

export default function AutoCompleteDropdown(props) {
  let defaultValue = props.defaultValue || []
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
    setAnchorEl,
    inputValue,
    value
  } = useAutocomplete({
    id: 'Assigned To',
    defaultValue: defaultValue,
    multiple: true,
    options: props.data,
    clearOnBlur: true,
    blurOnSelect: true,
    onChange: props.onChange,
    getOptionLabel: (option) => option.label,
  });

  let updatedValue = value && value.length > 1 ? [value[value.length - 1]] : value
  return (
    <NoSsr>
      <div>
        <div {...getRootProps()}>
          <Label {...getInputLabelProps()} >Assigned To:</Label>
          <InputWrapper ref={setAnchorEl} className={focused ? 'focused input-wrapper' : 'input-wrapper'}>
            {updatedValue.map((option, index) => {
              return (<Tag className="input-tag" label={option.label} {...getTagProps({ index })} />)
            })}

            <input className="input-Deselect-tag" {...getInputProps()} />
          </InputWrapper>
        </div>
        {groupedOptions.length > 0 ? (
          <Listbox className="auto-dropdown-list" {...getListboxProps()}>
            {groupedOptions.map((option, index) => {
              const optionProps = {
                ...getOptionProps({ option, index }),
                "aria-selected": updatedValue && updatedValue.length && (updatedValue[0].label) === option.label ? true : false
              }
              return (<li className="auto-dropdown-list" {...optionProps}>
                <span>{option.label}</span>
                <CheckIcon fontSize="small" />
              </li>)
            })}
          </Listbox>
        ) : focused && inputValue && inputValue.length ? <Listbox className="auto-dropdown-list" {...getListboxProps()}>
          <li className="auto-dropdown-list">
            <span>No matching results found</span>
          </li>
        </Listbox> : null}
      </div>
    </NoSsr>
  );
}


