import React from 'react';
import { Row, Col, Form } from "react-bootstrap";
import "../../sass/main.scss";
import './FormField.scss';

interface TextFieldProps {
    label: string,
    name?: string,
    placeholder: string,
    required?: boolean,
    errMsg: string,
    maxLength?: number
    onChange: Function
    value?: string
    showError?: boolean,
    disabled?: boolean
    onBlur?: any,
    className?: string,
    multiline?: string,
    suppressEnterSubmit?: boolean
}


function TextField(props: TextFieldProps) {
    const submitHandler = (e) => {
        if (props.suppressEnterSubmit) {
            e.preventDefault();
        }
    }


    return (
        <>
            <Form className={props.className ? "" : "text-field-input"} onSubmit={submitHandler}>
                {(props.label || props.required) &&
                <Row>
                    <Col>
                        <Form.Label>{props.label}{props.required ?
                            <span className="required" style={{ color: "red" }}>*</span>
                            : <span>&nbsp;</span>}</Form.Label>
                    </Col>
                </Row>
                }
                <Row>
                    <Col>
                        {props.errMsg.length > 0 ? <span className="alert-icon"></span> : ""}
                        {props.multiline ?
                            <textarea
                                className={props.className ? props.className : (props.errMsg.length > 0 ? `inputError input-field` : `input-field`)}
                                placeholder={props.placeholder}
                                maxLength={props.maxLength}
                                onChange={e => props.onChange(e.target.value)}
                                value={props.value}
                                onBlur={props.onBlur}
                            />
                            : <Form.Control
                                className={props.className ? props.className : (props.errMsg.length > 0 ? `inputError input-field` : `input-field`)}
                                placeholder={props.placeholder}
                                maxLength={props.maxLength}
                                onChange={e => props.onChange(e.target.value)}
                                value={props.value}
                                type={"text"}
                                onBlur={props.onBlur}
                                disabled={props.disabled}
                            />}
                    </Col>
                </Row>
                {props.errMsg.length > 0 && <Row>
                    <Col>
                        {props.errMsg === "Atleast one value is required to search."
                                ? <div className="error-message phone-fax-email-error-message">{props.errMsg}</div>
                                : <span className="error-message">{props.errMsg}</span>
                        }
                    </Col>
                </Row>}
            </Form>
        </>
    )
}

export default TextField;