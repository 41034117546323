import { combineReducers } from 'redux';
import {navigationState} from './navigation/navigationDucks';
import {dataEntryState} from '../components/dataEntryDetails/DataEntryDetailsDucks';
import {headerState} from '../common/header/HeaderDucks';
import {landingPageState} from '../components/landingPage/LandingPageDucks';
import {loginState} from '../components/login/LoginPageDucks'
import { reducer as form } from 'redux-form';

const appReducer = combineReducers({
  navigationState,
  dataEntryState,
  headerState,
  landingPageState,
  loginState,
   form: form.plugin({
"DATA_ENTRY_FIELDS_FORM": (state, action) => { 
  switch(action.type) {
    case "@@redux-form/RESET":
      const values = undefined;
      const fields = undefined
      const newState = { ...state, values, fields };
      return newState; 
    case "@@redux-form/DESTROY":
      const formValues = sessionStorage.getItem("formValues") || ""
      const normalizedValues = formValues ? JSON.parse(formValues) : {}
      return  {values:normalizedValues, initial: normalizedValues}
    default:
      return state;
    }
  }
})
})

const rootReducer = (state, action) => {
  if (action.type === "logoutUseSuccess/LoginPageConstants") {
    state = undefined
  }else if(action.type === "RESET_STATE"){
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer