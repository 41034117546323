import React from "react";
import { Row, Col } from "react-bootstrap";
import "./transactionInfo.scss";
import moment from "moment";
import ReactHover, { Hover, Trigger } from "../reactHover";

interface TransactionData {
    transactionID: string,
    paymentStatus: string,
    resDataEntryDate: Date,
    receivedDate: Date,
    stdDate: Date,
    releaseDate: Date,
    patientFirstName: string,
    patientLastName: string,
    patientDOB: Date,
    mrn: string,
    empi: string,
    caseNumber: string,
    requestorName: string,
    requestorAddress: any,
    phone: string,
    requestorEmail: string,
    requestDocument: string,
    status: string,
    note?: any
}

interface TransactionInfoProps {
    transactionData: TransactionData,
    selected?: boolean,
    className?: string,
    onClick?: Function
}

const DisplayTransaction = (props: TransactionInfoProps) => {
    const click = (e) => {
        if (props.onClick)
            props.onClick(props.transactionData);
    }

    const isValidDate = (date) => {
        return (Object.prototype.toString.call(date) === "[object String]" && !isNaN(Date.parse(date))) || (Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date.getTime()));
    }

    const address = (() => {
        const line1 = [props.transactionData?.requestorAddress?.Address1, props.transactionData?.requestorAddress?.Address2].filter(l => !!l).join(" ").trim();
        const line2 = [props.transactionData?.requestorAddress?.City, props.transactionData?.requestorAddress?.State, props.transactionData?.requestorAddress?.ZipCode].filter(l => !!l).join(" ").trim();

        const result = [line1 ? <>{line1}</> : null, line2 ? <>{line2}</> : null].filter(l => !!l)

        if (result.length === 2)
            result.splice(1, 0, <>,<br /></>)

        return result.length === 0 ? null : result;
    })();

    const optionsCursorTrueWithMargin = {
        followCursor: false,
        shiftX: 0,
        shiftY: 15,
        style: {
            right: "0px",
            zIndex: "9999"
        }
    };

    return (
        <div className={"transactionBlock" + (props.selected ? " selected" : "") + (props.onClick ? "" : " nohover") + (props.className ? " " + props.className : "")} onClick={(e) => click(e)}>
            <Row>
                <Col>
                    <Row className="txBold">{props.transactionData.transactionID}</Row>
                    <Row className="txBold">{props.transactionData.paymentStatus}</Row>
                    {isValidDate(props.transactionData.receivedDate) ? (<Row>Rcv: {moment(props.transactionData.receivedDate).format("MM/DD/YYYY")}</Row>) : null}
                    {isValidDate(props.transactionData.resDataEntryDate) ? (<Row>Ent: {moment(props.transactionData.resDataEntryDate).format("MM/DD/YYYY")}</Row>) : null}
                    {isValidDate(props.transactionData.stdDate) ? (<Row>Std: {moment(props.transactionData.stdDate).format("MM/DD/YYYY")}</Row>) : null}
                    {isValidDate(props.transactionData.releaseDate) ? (<Row>Rel: {moment(props.transactionData.releaseDate).format("MM/DD/YYYY")}</Row>) : null}
                </Col>
                <Col className="txColor">
                    <Row className="txBold">{props.transactionData.patientFirstName + " " + props.transactionData.patientLastName}</Row>
                    <Row className="txBold">{moment(props.transactionData.patientDOB).format("MM/DD/YYYY")}</Row>
                    {props.transactionData.mrn ? (<Row>MRN: {props.transactionData.mrn}</Row>) : ""}
                    {props.transactionData.empi ? (<Row>EMPI: {props.transactionData.empi}</Row>) : ""}
                    {props.transactionData.caseNumber ? (<Row>Case: {props.transactionData.caseNumber}</Row>) : ""}
                    {props.transactionData.status ? (<Row>Status: {props.transactionData.status}</Row>) : ""}
                </Col>
                <Col>
                    <Row className="txBold">{props.transactionData.requestorName}</Row>
                    {address ? (<Row>{address}</Row>) : ""}
                    {props.transactionData?.requestorAddress?.PhoneNumber === undefined ? "" : (<Row>P: {props.transactionData?.requestorAddress?.phoneNumber}</Row>)}
                    {props.transactionData?.requestorAddress?.FaxNumber === undefined ? "" : (<Row>F: {props.transactionData?.requestorAddress?.faxNumber}</Row>)}
                    {props.transactionData.requestorEmail === undefined ? "" : (<Row>{props.transactionData.requestorEmail}</Row>)}
                </Col>
                <Col>
                    {props.transactionData.note ?
                        <ReactHover options={optionsCursorTrueWithMargin}>
                            <Trigger type="trigger">
                                <i className="fa fa-comment note-space" aria-hidden="true"></i>
                            </Trigger>
                            <Hover type="hover">
                                <div className="react-hover">
                                    <blockquote className="patient-comment">
                                        <div>{props.transactionData.note.createdByName + " - " + moment(props.transactionData.note.created).format("MM/DD/YYYY") + " - " + props.transactionData.note.type.name }</div>
                                        <div>{props.transactionData.note.text
                                                .split('\n')
                                            .map((s, i) => <>{i > 0 && <br />}{s}</>)}</div>
                                    </blockquote>
                                </div>
                            </Hover>
                        </ReactHover>
                        : <></>}
                </Col>
            </Row>

        </div>
    )
}

export default DisplayTransaction;

