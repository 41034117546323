import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import CheckboxField from "./CheckboxField";
import "../../sass/main.scss";
import './FormField.scss';
import { withStyles, makeStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell, { SortDirection } from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import internal from 'events';
import { Label, ArrowDownward } from '@material-ui/icons';


class Item {
    ItemId: number = 0;
    Labels: string[] = [];
    State?: boolean;
    FreeText: string = '';
}

interface CheckboxListProps {
    items?: Item[],
    onChange?: Function,
    checkboxHeader: string,
    labelHeaders: string[],
    allowSort?: boolean,
    className?: string,
    style?: any,
    uncheckedX?: boolean,
    hasFreeText?: (item: Item) => boolean,
    freeTextMaxLen?: number
}

function CheckboxList(props: CheckboxListProps) {
    const [items, setItems] = useState<Item[]>([]);
    const [sortColumn, setSortColumn] = useState(1);
    const [sortAsc, setSortAsc] = useState(true);
    const [freeText, setFreeText] = useState('');

    useEffect(() => {
        if (props.items) {
            let same = setsAreSame(props.items, items);
            if (!same){
                setItems(props.items);
                ((o) => {
                    if(o){
                        setFreeText(o.FreeText);
                    }
                })(props.items.find(i => props.hasFreeText && props.hasFreeText(i)))
            }
        }
    }, [props.items])

    const onChangeData = (e:Item) => {
        const newItems:Item[] = [];
        items.forEach((i) => {
            if(i.ItemId === e.ItemId){
                newItems.push(e);
            }else{
                newItems.push(i);
            }

        });
        props.onChange && props.onChange(newItems);
    }

    const onBlurText = () => {
        const otherItem = items.find(i => i.ItemId === 31);
        if(otherItem && otherItem.FreeText != freeText){
            // const newItems = items.map(i => 
            //     props.hasFreeText && props.hasFreeText(i) ? 
            //     {...i,
            //     FreeText: freeText}:
            //     i
            // )
            // setItems(newItems);
            const newOther = {
                ...otherItem,
                FreeText: freeText
            }
            onChangeData(newOther);
        }
    }

    const clickSortArrow = (index: number) => {
        if (sortColumn === index) {
            setSortAsc(!sortAsc);
        } else {
            setSortColumn(index);
            setSortAsc(true);
        }
    }

    const sortedRows = () => {
        if (props.allowSort) {
            return [...(props.items || [])].sort((e1, e2) => {
                if (sortAsc)
                    return e1.Labels[sortColumn].localeCompare(e2.Labels[sortColumn]);
                else
                    return e2.Labels[sortColumn].localeCompare(e1.Labels[sortColumn]);
            })
        } else {
            return props.items || [];
        }
    }

    const setsAreSame = (set1: Item[], set2: Item[]) => {
        if (set1.length !== set2.length) return false;
        return set1.every((v) => set2.find(s => s.ItemId === v.ItemId && s.State === v.State && s.FreeText === v.FreeText ));
    }

    return (
        <table className={props.className} style={props.style}>
            <thead >
                <tr >
                    <td>{props.checkboxHeader}</td>
                    {props.labelHeaders.map((h, i) => (
                        <td className={(sortColumn === i ? " selected" : "")}>{h}{
                            props.allowSort ? (
                                <ArrowDownward className="sortArrow" onClick={() => clickSortArrow(i)}></ArrowDownward>
                            ) : <></>
                        }</td>
                    ))}
                    {props.hasFreeText ? <td></td> : null}
                </tr>
            </thead>
            <tbody>
                {sortedRows().map((row: Item, index) => {
                    return (
                        <tr key={row[index]}  >
                            <td>
                                <span>
                                    <Checkbox 
                                        onChange={(e) => onChangeData({ ItemId: row.ItemId, Labels: row.Labels, State: e.currentTarget.checked, FreeText: e.currentTarget.checked ? row.FreeText : ''})} 
                                        checked={row.State} 
                                        icon={props.uncheckedX ?
                                            <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#CC5967" font-size="12px"><rect fill="none" height="24" width="24" /><path d="M3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2H5C3.9,3,3,3.9,3,5z M16.3,16.29L16.3,16.29 c-0.39,0.39-1.02,0.39-1.41,0L12,13.41l-2.89,2.89c-0.39,0.39-1.02,0.39-1.41,0l0,0c-0.39-0.39-0.39-1.02,0-1.41L10.59,12L7.7,9.11 c-0.39-0.39-0.39-1.02,0-1.41l0,0c0.39-0.39,1.02-0.39,1.41,0L12,10.59l2.89-2.88c0.39-0.39,1.02-0.39,1.41,0l0,0 c0.39,0.39,0.39,1.02,0,1.41L13.41,12l2.89,2.88C16.68,15.27,16.68,15.91,16.3,16.29z" /></svg> :
                                            <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#BFD8E8" font-size="12px"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path></svg>}
                                    />
                                </span>
                            </td>
                            {row.Labels.map(l => (
                                <td>{l}</td>
                            ))}
                            {props.hasFreeText ?
                                <td>{props.hasFreeText(row) && row.State ? <textarea maxLength={props.freeTextMaxLen}
                                    className='checkboxlist-text' 
                                    value={freeText} 
                                    onChange={e => setFreeText(e.currentTarget.value)}
                                    onBlur={onBlurText} 
                                /> : null}</td>:
                                null
                            }
                        </tr>
                    )
                })}
            </tbody>
            <tfoot>
                <tr>
                    {[...Array(props.labelHeaders.length + 1 + (props.hasFreeText ? 1 : 0))].map(() => { return(<td></td>)})}
                </tr>
            </tfoot>
        </table>
    )

}

export { CheckboxList, Item };

