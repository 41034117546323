import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {getComparator} from '../AccordionTable'
import './NormalTable.scss';
import { isAPIFetching, isAPIInitial } from '../../utils/appUtils';
import ReactHover, { Trigger, Hover } from "../../reactHover";
import { makeStyles } from "@material-ui/core";

interface NormalTableProps {
    setGrid?: Function;
    onTableRowClickHandler?: Function;
    rows?: object
    headCells?: object
    addNewText?: String
    defaultSortCol?: String
    cssName?: String
    searchTablecss?: String
    headerSearch?: boolean
}
const useStyles = makeStyles(theme => ({
    root: {
      "& .MuiTable-root": {
        position: 'static'
      }
    }
  }));
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0){ return order;}
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
function NormalTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
export default function NormalTable(props) {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState(props.defaultSortCol);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const rowClickHandler = (rowData) => {
    props.onTableRowClickHandler(rowData);
    props.setGrid(false);
  }
  
  const optionsCursorTrueWithMargin = {
    followCursor: true,
    shiftX: 40,
    shiftY: -40
  };
  const classes = useStyles();
    return (
        <>
            <div className={props.cssName} onClick={() => props.setGrid(false)}></div>
            <div className={props.headerSearch ? "" : classes.root}>
            <Table size="small"  stickyHeader className={props.searchTablecss}>
                <div className="search-table-inner">                
                    <NormalTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={props.headCells}
                    />
                    <TableBody>
                        {isAPIInitial(props.transactionSearchResultsStatus) ? <></> :
                        isAPIFetching(props.transactionSearchResultsStatus) ?  
                        <TableRow className="row-wrapper no-records" key={'010101'}>
                            <TableCell colSpan={props.headCells.length}>{"Searching..."}</TableCell>
                        </TableRow> : !props.rows.length ? <TableRow className="row-wrapper no-records" key={'010101'}>
                            <TableCell colSpan={props.headCells.length}>{"No matching records found"}</TableCell>
                        </TableRow> :  
                        stableSort(props.rows, getComparator(order, orderBy))
                            .map((row, index) => { 
                                return (
                                    <TableRow key={row[index]} onClick={() => rowClickHandler(row)}>
                                        {props.headCells.map((headCell) =>  {
                                            return (
                                                <TableCell align={headCell.id === 'state' ? "center" : "left"}>
                                                    {headCell.id === 'lastName' && row.patientComments ?
                                                    <ReactHover options={optionsCursorTrueWithMargin}>
                                                        <Trigger type="trigger">
                                                            {row[headCell.id]} <i className="fa fa-comment note-space" aria-hidden="true"></i> 
                                                        </Trigger>
                                                        <Hover type="hover">
                                                            <div className="react-hover">
                                                                <blockquote className="patient-comment">{row.patientComments}</blockquote>
                                                            </div>
                                                        </Hover>
                                                    </ReactHover> : row[headCell.id]}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            }
                        )}                  
                    </TableBody>
                </div>
                <caption className='add-new-note search-table-caption'>
                </caption>
            </Table>  </div>        
        </>
    );
}