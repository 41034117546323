import React from "react";
import { connect } from "react-redux";
import * as Redux from 'redux'
import {Container} from "react-bootstrap";
import BasicHeader from "../../common/header/basicHeader/BasicHeader";
import Footer from "../../common/footer/Footer";
import "./LoginPage.scss";
import "../../sass/main.scss";
import {
  getOtpOrLoginRequest,
  getResendOtpForLoginRequest,
  resetState,
} from "./LoginPageDucks";
import {LoginBody} from '../login/LoginBody';
import { push } from "../../redux/navigation/navigationDucks";

interface LoginBodyProps {
  getOtpOrLoginRequest: Function;
  getOtpAPIStatus: number;
  getResendOtpForLoginRequest: Function;
  otp: string;
  resendOtp: string;
  redirectToLandingPage: Function,
  errorDescription: string,
  resetState: Function
}
class LoginPage extends React.Component<LoginBodyProps> {
  componentDidMount(){
    this.props.resetState()
    sessionStorage.clear()
  }
  render() {
    return (
      <Container fluid className="app-container-custom-style">
        <BasicHeader />
        <LoginBody
          redirectToLandingPage={this.props.redirectToLandingPage}
          getOtpOrLoginRequest={this.props.getOtpOrLoginRequest}
          getOtpAPIStatus={this.props.getOtpAPIStatus}
          getResendOtpForLoginRequest={this.props.getResendOtpForLoginRequest}
          errorDescription= {this.props.errorDescription}
        />
        <Footer />
      </Container>
    );
  }
}
export function mapDispatchToProps(dispatch: Redux.Dispatch<Redux.Action>) {
  return {
    getOtpOrLoginRequest: (
      userName: string,
      otp?: string,
      onAPISuccess?: Function
    ) => dispatch(getOtpOrLoginRequest(userName, otp, onAPISuccess)),
    getResendOtpForLoginRequest: (userName: string, otp?: string) =>
      dispatch(getResendOtpForLoginRequest(userName, otp)),
      redirectToLandingPage: () => dispatch(push("/home")),
      resetState: () => dispatch(resetState())
  };
}
export function mapStateToProps(state: any) {
  return {
    getOtpAPIStatus: state.loginState.getOtpAPIStatus,
    errorDescription: state.loginState.errorDescription
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);



