import React, { useState, useEffect } from "react";
import './alertPopup.scss';
import { connect } from "react-redux";
import { Modal, Row, Col, Button } from "react-bootstrap";
import IconWithButton from "../IconWithButton/IconWithButton";
import { hideDuplicateTransactionsPopup, postCancellationReasonsActionCreators, resetDataEntryState, unassignTransactionIDActionCreator} from '../../components/dataEntryDetails/DataEntryDetailsDucks';
import {clearTransactionId, resetRequestContext} from '../../components/landingPage/LandingPageDucks';
import TransactionInfo from "../transactionInfo/TransactionInfo";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { documentURL } from '../../services/Network';
import { useHistory } from "react-router-dom";


interface DuplicateTXPopupProps {
    compareTransaction?: any,
    duplicateTransactions?: any[],
    updateShowPopupError?: any,
    userId: Number,
    postCancellationReasonsActionCreators?: Function,
    cancelReasons?: any
    resetDataEntryState?: Function,
    clearTransactionId?: Function,
    unassignTransactionIDActionCreator?: Function,
    resetRequestContext?: Function,
    statusIndicatorID?:any
}
function DuplicateTXPopup(props: DuplicateTXPopupProps) {
    const [displayPopup, setDisplayPopup] = useState(false);
    const [selectedTx, setSelectedTx] = useState<any>({});
    const [alreadyShown, setAlreadyShown] = useState(false);
    const [duplicateId, setDuplicateId] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (props.duplicateTransactions == null || props.duplicateTransactions.length == 0) {
            setDisplayPopup(false)
        } else if (!alreadyShown) {
            setAlreadyShown(true);
            setDisplayPopup(true);
        }

        props.cancelReasons && setDuplicateId(props.cancelReasons.filter( i =>  i.label === 'Duplicate'))
    }, [props.duplicateTransactions])

    useEffect(() => {
        setAlreadyShown(false);
    }, [props.compareTransaction.transactionID])

    const onTxClick = (e) => {
        setSelectedTx(e)
    }

    const pdfPath = () => {
        return selectedTx.requestDocument ? documentURL + "REQ-" + selectedTx.transactionID + "-1/file?userid=" + props.userId + "&addTrailEntry=false" : "";
        //return selectedTx.RequestDocument ? documentURL + selectedTx.RequestDocument + "/file?userid=" + props.userId : "";
    }

    
  const resetTransaction = (unassign = true) => {
    if (unassign) props.unassignTransactionIDActionCreator && props.unassignTransactionIDActionCreator(props.compareTransaction.transactionID);

    if (props.resetDataEntryState) {
      props.resetDataEntryState();

    }
    if (props.clearTransactionId) {
      props.clearTransactionId();

    }
    props.resetRequestContext && props.resetRequestContext()
    history?.push("/home")

}
   

    if (displayPopup) {
        return (
            <React.Fragment>
                <div className="tx-duplicate">
                    <div className="tx-duplicate-popup-main">
                        <h5 className="tx-duplicate-popup-header" role="alert">
                            <div className="info-icon"></div>Duplicate Transaction Check
                            <span className="close-icon alert-close-icon" onClick={() => props.updateShowPopupError()}></span>
                        </h5>
                        <Row className="tx-duplicate-popup-body" >
                            <Col className="tx-duplicate-popup-leftcol">
                                <Row>
                                    <span className="header">Current Transaction Details</span>
                                </Row>
                                <Row className="mainHolder">
                                    <TransactionInfo transactionData={props.compareTransaction} ></TransactionInfo>
                                </Row>
                                <Row>
                                    <span className="header">Other Matching Transactions</span>
                                </Row>
                                <Row className="duplicatesHolder">
                                    <div className="scrollbox">
                                        {props.duplicateTransactions &&
                                            props.duplicateTransactions?.sort((a, b) => {
                                                const thisOrg = parseInt(props.compareTransaction.transactionID.split('-')[0]);
                                                const aOrg = parseInt(a.transactionID.split('-')[0]);
                                                const bOrg = parseInt(b.transactionID.split('-')[0]);
                                                if (aOrg === thisOrg && bOrg !== thisOrg)
                                                    return -1;
                                                else if (aOrg !== thisOrg && bOrg === thisOrg)
                                                    return 1;
                                                else if (aOrg < bOrg)
                                                    return -1;
                                                else if (aOrg > bOrg)
                                                    return 1;
                                                else {
                                                    const aRB = parseInt(a.transactionID.split('-')[1]);
                                                    const bRB = parseInt(b.transactionID.split('-')[1]);
                                                    if (aRB < bRB)
                                                        return -1;
                                                    else
                                                        return 1;
                                                }
                                            })
                                                .map((dup, i) => {
                                                    return [i > 0 ? (<hr></hr>) : null, (
                                                        <TransactionInfo transactionData={dup} selected={dup.transactionID === selectedTx.transactionID} onClick={(e) => onTxClick(e)}></TransactionInfo>
                                                    )]
                                                })}
                                    </div>
                                </Row>
                            </Col>
                            <Col className="tx-duplicate-popup-rightcol">
                                <iframe title="title" src={pdfPath()} className="pdf-viewer" />
                            </Col>
                        </Row>
                        <Row className="tx-duplicate-popup-footer">
                            <Col>
                                <Button onClick={() => {
                                    props.postCancellationReasonsActionCreators && props.postCancellationReasonsActionCreators(props.compareTransaction.transactionID, duplicateId[0].value);  
                                    resetTransaction(); 
                                    props.updateShowPopupError();
                                    }}>
                                        <HighlightOffIcon className="iconStyle"></HighlightOffIcon>Mark Duplicate
                                </Button>
                            </Col>
                            <Col>
                                <Button onClick={() => props.updateShowPopupError()}><PlayCircleOutlineIcon className="iconStyle"></PlayCircleOutlineIcon>Continue</Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment >
        );
    }
    else {
        return null
    }
}


function mapStateToProps(state: any) {
    return {
        duplicateTransactions: state.dataEntryState?.duplicateTransactions,
        cancelReasons: state.dataEntryState?.transactionCancellationReasons,
        statusIndicatorID: state.dataEntryState?.transactionDetails?.transactionStatus?.id
    };
}

function mapDispatchToProps(dispatch: Function) {
    return {
        updateShowPopupError: () => dispatch(hideDuplicateTransactionsPopup()),
        postCancellationReasonsActionCreators: (txid, cancelid) => dispatch(postCancellationReasonsActionCreators(txid, cancelid, '', true)),
        resetDataEntryState: () => dispatch(resetDataEntryState()),
        clearTransactionId: () => dispatch(clearTransactionId()),
        resetRequestContext: () =>dispatch(resetRequestContext())
    };
}

export default connect<{}, {}, DuplicateTXPopupProps>(mapStateToProps, mapDispatchToProps)(
    DuplicateTXPopup
);