import React, {useEffect} from 'react'; 
import RadioField from '../formFields/RadioField'
/**
 * to be wrapped with redux-form Field component
 */
export default function ReduxFormRadioField(props) {



  const { input, meta, fieldlabel, placeholder,  required ,radioLabel, defaultSelected, onChange, disabled} = props

  const [selectedValue, setSelectedValue] = React.useState(props.defaultSelected);

  useEffect(() => {
    if(selectedValue !== props.defaultSelected){
      setSelectedValue(props.defaultSelected)
    }
    if(input.value !== selectedValue){
      setSelectedValue(input.value)
    }
  },[props.defaultSelected] 
  )
 
  const onChangeValue = (event:any) =>  {
    setSelectedValue(event.target.value)
    input && input.onChange(event.target.value)
    onChange && onChange(event.target.value, input.name)
  }

  // do not display warning if the field has not been touched or if it's currently being edited
  let error = ""
  if (meta.touched && !meta.active) {
    if (meta.valid) {
    } else {
      error = meta.error
    }
  }
  return (
    <RadioField
    fieldlabel={fieldlabel}
    placeholder={placeholder}
    required = {required}
    errMsg={error}
    onChange={onChangeValue}
    value={input.value}
    radioLabel={radioLabel}
    selectedValue = {selectedValue}
    defaultSelected={defaultSelected}
    disabled={disabled}
  />
  );
}