import { DEFAULT_DROPDOWN_NAME, DEFAULT_DROPDOWN_VALUE } from "../../common/constants/ApiConstants";

enum tabNamesEmum{
    details = "Details",
    requestor = "Requestor",
    billing = "Billing",
    records = "Records"
}
export const tabItems = [
    {
       id: 1,
       label: tabNamesEmum.details
   },
    {
       id: 2,
       label: tabNamesEmum.requestor
   },
   {
       id: 3,
       label: tabNamesEmum.billing
   },
   {
       id: 4,
       label: tabNamesEmum.records
   }
];

export const assignedToOptions = [
    {
        label: DEFAULT_DROPDOWN_NAME,
      
        value: DEFAULT_DROPDOWN_VALUE
    },
    {
        label: "Option 1",
        value: "1"
    },
    {
        label: "Option 2",
        value: "2"
    }
]

export const transactionNotesTypes = [
    {
        label: DEFAULT_DROPDOWN_NAME,
        value: DEFAULT_DROPDOWN_VALUE
    },
    {
        label: "General Use",
        value: "0"
    },
    {
        label: "Internal Use Only",
        value: "1"
    },
    {
        label: "Issue Notification",
        value: "2"
    },
    {
        label: "Authorization Check",
        value: "3"
    },
    {
        label: "Cancellation Reason",
        value: "4"
    },
    {
        label: "Customer Service",
        value: "5"
    },
    {
        label: "Customer Service - Patient EDR",
        value: "6"
    },
    {
        label: "Error Found",
        value: "7"
    },
    {
        label: "Reject Letter",
        value: "8"
    },
    {
        label: "Requestor Concern",
        value: "9"
    },
    {
        label: "Review Reason",
        value: "10"
    },
    {
        label: "Task Assignments",
        value: "11"
    }
]